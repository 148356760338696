import { KeyNames } from "enums";
import React, { ReactElement, useCallback, useEffect, useRef } from "react";
import { useEvent } from "react-use";

import { cn } from "utils";

type Props = {
  children: ReactElement;
  isShow: boolean;

  isCloseWhenClickOutside?: boolean;
  preventEsc?: boolean;
  className?: React.HTMLAttributes<HTMLDivElement>["className"];
};

export const AlertModal = ({
  isShow = false,
  isCloseWhenClickOutside = false,
  preventEsc = false,
  className,
  ...props
}: Props) => {
  const dialogRef = useRef<HTMLDialogElement>(null);

  const handleKeyDown = useCallback(
    (e: KeyboardEvent) => {
      if (e.key !== KeyNames.ESCAPE) return;

      if (preventEsc) e.preventDefault();
    },
    [preventEsc]
  );

  useEvent("keydown", handleKeyDown);

  useEffect(() => {
    isShow ? show() : hide();
  }, [isShow]);

  const show = () => {
    const dialogElement = dialogRef.current;

    if (!dialogElement) return;

    dialogElement?.showModal();
  };

  const hide = () => {
    const dialogElement = dialogRef.current;

    if (!dialogElement) return;

    dialogElement?.close();
  };

  return (
    <dialog ref={dialogRef} className="daisy-modal">
      <div className={cn("daisy-modal-box", className)}>{props.children}</div>

      {isCloseWhenClickOutside && (
        <form method="dialog" className="daisy-modal-backdrop">
          <button>close</button>
        </form>
      )}
    </dialog>
  );
};
