import { useAuth0 } from "@auth0/auth0-react";
import { isNil } from "lodash";
import { useEffect } from "react";
import { useSearchParam } from "react-use";
import { isHomePage, redirectTo } from "utils";

export const useHomePageRedirection = () => {
  const { isAuthenticated } = useAuth0();

  const paramRedirectToUrl = useSearchParam("redirectTo");

  // Process with `redirectTo` URL param in Home page after logged out
  useEffect(() => {
    const hasRedirectToParam = !isNil(paramRedirectToUrl);
    const isHomePageAfterLogout = isHomePage() && hasRedirectToParam;

    const shouldRedirectAfterLogout = isHomePageAfterLogout && !isAuthenticated;

    if (!shouldRedirectAfterLogout) return;

    redirectTo(paramRedirectToUrl);
  }, [isAuthenticated, paramRedirectToUrl]);

  return null;
};
