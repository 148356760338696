import {
  DateInputMask,
  MonthInputMask,
  YearInputMask,
} from "components/shared/DateInputMask";
import { isNil } from "lodash";
import React, { Suspense } from "react";
import { ReactElement } from "react";
import { match } from "ts-pattern";
import { v4 } from "uuid";

const DatePicker = React.lazy(() => import("react-datepicker"));

type DateInputProps = {
  name?: string;
  type?: "date" | "month" | "year";
  title?: string;
  dateFormat?: string;
  placeholderText?: string;
  className?: string;

  isShowHint?: boolean;
  selectedDate?: Date;
  maxDate?: Date;

  hintComponent?: ReactElement;

  onChangeValue: (newValue: string) => void;

  [type: string]: any;
};

export const DateInput = ({
  name,
  title,
  type = "date",
  dateFormat = "dd/MM/yyyy",
  placeholderText = "DD/MM/YYYY",
  className,
  isShowHint = false,
  hintComponent,
  selectedDate,
  maxDate,
  onChangeValue,
  ...props
}: DateInputProps) => {
  const elemId = v4();

  const getPlaceholderText = (): string | undefined => {
    return match(type)
      .with("date", () => "DD/MM/YYYY")
      .with("month", () => "MM/YYYY")
      .with("year", () => "YYYY")
      .otherwise(() => undefined);
  };

  const getDateFormat = (): string | undefined => {
    return match(type)
      .with("date", () => "dd/MM/yyyy")
      .with("month", () => "MM/yyyy")
      .with("year", () => "yyyy")
      .otherwise(() => undefined);
  };

  const handleChangeValue = (newValue: Date | null) => {
    if (isNil(newValue)) return;

    onChangeValue(newValue.toISOString());
  };

  return (
    <div className="flex flex-col w-full gap-y-1">
      <div className="flex flex-row items-center justify-between">
        <label htmlFor={elemId} className="text-dark-grey">
          {title}
        </label>
        {isShowHint && <div>{hintComponent}</div>}
      </div>

      <Suspense fallback={<div>Loading...</div>}>
        <DatePicker
          portalId="root"
          name={name}
          selected={selectedDate}
          placeholderText={getPlaceholderText()}
          dateFormat={getDateFormat()}
          maxDate={maxDate}
          onChange={handleChangeValue}
          className="w-full py-3 pl-3 border rounded-md border-light-grey-3 focus:border-secondary-darker focus:ring-secondary-darker outline-secondary-darker"
          customInput={match(type)
            .with("date", () => <DateInputMask />)
            .with("month", () => <MonthInputMask />)
            .with("year", () => <YearInputMask />)
            .otherwise(() => null)}
          {...props}
        />
      </Suspense>
    </div>
  );
};
