export enum EndTime {
  The1400 = "14:00",
  The1800 = "18:00",
  The2000 = "20:00",
  The2030 = "20:30",
}

export enum DeliveryModes {
  VIDEO = "video",
  PHONE = "phone",
}

export enum ClientAgeRange {
  CHILDREN = "children", // 0-12
  TEENAGERS = "teenagers", // 13-17
  YOUTH = "youth", // 18-24
  ADULTS = "adults", // 25-64
  OLDER_ADULTS = "olderAdults", // 65+
}

export enum ClientGender {
  WOMAN = "woman",
  MAN = "man",
  TRANSGENDER_WOMAN = "transgenderWoman",
  TRANSGENDER_MAN = "transgenderMan",
  NON_BINARY = "nonBinary",
  AGENDER = "agender",
  GENDER_NOT_LISTED = "genderNotListed",
  PREFER_NOT_TO_STATE = "preferNotToState",
}

export enum AvailabilityTimeFilterType {
  ALL = "all",
  MORNING = "morning",
  AFTERNOON = "afternoon",
  EVENING = "evening",
}

export enum InputType {
  PASSWORD = "password",
  TEXT = "text",
  NUMBER = "number",
  CHECKBOX = "checkbox",
  RADIO = "radio",
  DATE = "date",
}

export enum NextAvailableSelectionType {
  TODAY = "availableToday",
  TOMORROW = "availableTomorrow",
  IN_NEXT_7_DAYS = "availableInNext7Days",
  IN_NEXT_2_WEEKS = "availableInNext2Weeks",
}

export enum PreferredDaySelectionType {
  MONDAY = "monday",
  TUESDAY = "tuesday",
  WEDNESDAY = "wednesday",
  THURSDAY = "thursday",
  FRIDAY = "friday",
  SATURDAY = "saturday",
  SUNDAY = "sunday",
}

export enum AvailableTimeSelectionType {
  MORNING = "morning",
  AFTERNOON = "afternoon",
  EVENING = "evening",
}

export enum PsychologistGenderSelectionType {
  WOMAN = "woman",
  MAN = "man",
  TRANSGENDER_WOMAN = "transgenderWoman",
  TRANSGENDER_MAN = "transgenderMan",
  NON_BINARY = "nonBinary",
  AGENDER = "agender",
}

export enum TreatmentStyleSelectionType {
  EMOTION_FOCUSED_THERAPY = "Emotion Focused Therapy",
  INTERPERSONAL_PSYCHOTHERAPY = "Interpersonal Psychotherapy",
  MINDFULNESS = "Mindfulness",
  COGNITIVE_BEHAVIOURAL_THERAPY_CBT = "Cognitive Behavioral Therapy (CBT)",
  NARRATIVE_THERAPY = "Narrative Therapy",
  POSITIVE_PSYCHOLOGY = "Positive Psychology",
  PSYCHODYNAMIC_THERAPY = "Psychodynamic Therapy",
  SOLUTION_FOCUSED_BRIEF_THERAPY = "Solution-Focused Brief Therapy",
  ACCEPTANCE_AND_COMMITMENT_THERAPY_ACT = "Acceptance and Commitment Therapy (ACT)",
  DIALECTICAL_BEHAVIOUR_THERAPY = "Dialectical Behavior Therapy",
  ATTACHMENT_BASED_THERAPY = "Attachment-Based Therapy",
  COACHING = "Coaching",
  MOTIVATIONAL_INTERVIEWING = "Motivational Interviewing",
  PERSON_CENTRED_THERAPY = "Person-Centered Therapy",
  SCHEMA_THERAPY = "Schema Therapy",
}

export enum ClinicianTitle {
  DR = "Dr",
  EMPTY = "",
  MS = "Ms",
  MISS = "Miss",
  MR = "Mr",
  MRS = "Mrs",
  NONE = "None",
}

export enum InclusionType {
  INCLUDE = 1,
  EXCLUDE = -1,
}

export enum BackendBinary {
  TRUE = 1,
  FALSE = 0,
}

export enum FileType {
  PDF = ".pdf",
  DOCX = ".docx",
  PNG = ".png",
  JPG = ".jpg",
  DOC = ".doc",
}

export enum StateType {
  NSW = "nsw",
  VIC = "vic",
  QLD = "qld",
  WA = "wa",
  SA = "sa",
  TAS = "tas",
  ACT = "act",
  NT = "nt",
}

export enum BookBehalfOfType {
  MY_SELF = "mySelf",
  SOMEONE_ELSE_ADULT = "someoneElseAdult",
  MY_CHILD = "myChild",
}

export enum ProvideReferralType {
  UPLOAD = "upload",
  SENT_TO = "sentTo",
}

export enum ConfirmRadioType {
  YES = "Yes",
  NO = "No",
}

export enum PreferredContactMethodType {
  BY_PHONE = "byPhone",
  BY_EMAIL = "byEmail",
}

export enum CustomToastType {
  INFO = "info",
  SUCCESS = "success",
  WARNING = "warning",
  ERROR = "error",
}

export enum ManageRadioType {
  PLAN = "plan",
  SELF = "self",
}

export const StatusCode = {
  OK: 200,
  CREATED: 201,
  BAD_REQUEST: 400,
  UNAUTHORIZED: 401,
  FORBIDDEN: 403,
  NOT_FOUND: 404,
  CONFLICT: 409,
  INTERNAL_SERVER_ERROR: 500,
};

export enum DVACardType {
  GOLD = "gold",
  WHITE = "white",
}

export enum TimeZoneType {
  AUSTRALIA_SYDNEY = "Australia/Sydney",
  AUSTRALIA_MELBOURNE = "Australia/Melbourne",
  AUSTRALIA_BRISBANE = "Australia/Brisbane",
  AUSTRALIA_ADELAIDE = "Australia/Adelaide",
  AUSTRALIA_DARWIN = "Australia/Darwin",
  AUSTRALIA_PERTH = "Australia/Perth",
  PACIFIC_NORFOLK = "Pacific/Norfolk",
  INDIAN_CHRISTMAS = "Indian/Christmas",
  INDIAN_COCOS = "Indian/Cocos",
}

export enum DateFormatType {
  DATE_STRING = "dd MMM yyyy",
  TWELVE_HOURS = "h:mm aa",
  HOUR_MINUTE = "HH:mm",
  APPOINTMENT_DATE = "EE, dd MMMM yyyy",
}

export enum KeyClientContactType {
  EMERGENCY = "Emergency Contact",
  ADULT = "Person Booking",
}

export enum ConsultPreferenceType {
  VIDEO_CALL = "videoCall",
  PHONE_CALL = "phoneCall",
}

export enum BookingRuleType {
  NEW = "new",
  EXISTING = "existing",
}

export enum MedicareRole {
  ClinicalPsychologists = "clinicalPsychologists",
  RegisteredPsychologists = "registeredPsychologists",
  OccupationalTherapists = "occupationalTherapists",
  SocialWorkers = "socialWorkers",
  GeneralPractitioner = "generalPractitioner",
}

export enum BulkBillEligibilityType {
  CONCESSION_CARD_HOLDER = "Concession Card Holders",
  FINANCIAL_HARDSHIP = "Financial Hardship",
}

export enum UserRole {
  PATIENT = "patient",
  CLINICIAN = "clinician",
}

export enum PageAlias {
  SIGNUP = "PAGE_SIGNUP",
  FUNDING_BOOKING = "PAGE_FUNDING_BOOKING",
  GP_BOOKING = "PAGE_GP_BOOKING",
  CONFIRM = "PAGE_CONFIRM",
  OTHER = "PAGE_OTHER",
}

export enum GTM_DataLayer_Events {
  REGISTRATION_COMPLETE = "registrationComplete", // completed account creation
  FIRST_APPOINTMENT_BOOKED = "first_appointment_booked", // first appointment booked
  SUBSEQUENT_APPOINTMENT_BOOKED = "OnSuccessReservedAppointment", // subsequent appointments booked
  GP_APPOINTMENT_BOOKED = "GPappointmentbooked", // GP appointment booked
}

export enum GTM_DataLayer_Types {
  BULK_BILL = "MedicareBulkBill",
  REBATE = "MedicareRebate",
  SELF_FUND = "SelfFund",
  NDIS = "NDIS",
  WORK_COVER = "WorkCover",
  DVA = "DVA",
  GP_APPOINTMENT = "GPAppointment",
}

export enum UrlParamType {
  SAVE_PAYMENT_DETAIL_STATUS = "save-payment-detail-status",
}

export enum UrlReturn {
  OUR_PSYCHOLOGISTS = "our-psychologists",
}

export enum SavedPaymentStatus {
  FAILED = "failed",
  SUCCESS = "success",
}

export enum SignUpFormType {
  FUNDING = "signupFunding",
  GP = "signupGp",
  INVITE = "signupInvite",
}

export enum CommunicationPreferenceType {
  NO_PREFERENCE = "noPreference",
  EMAIL = "email",
  SMS = "sms",
  NONE = "none",
}

export enum AlertType {
  INFO = "info",
  SUCCESS = "success",
  ERROR = "error",
}

export enum ScrollAnchorIds {
  PSYCHOLOGIST_DETAIL_INFO = "psychologist-detail-info",
  PSYCHOLOGIST_DETAIL_FUNCTIONS = "psychologist-detail-functions",
  PRICING_TABLE = "tbl-pricing-detail",
}

export enum OTPMessageResponse {
  INVALID = "Invalid smsOtp",
  EXPIRED = "smsOtp is expired",
}

export enum KeyNames {
  ESCAPE = "Escape",
}
