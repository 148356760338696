/* eslint-disable react-hooks/exhaustive-deps */
import {
  getMedicareMySelfFieldsValidation,
  isValidChildMedicareFields,
  validateAdultMedicareCardByTacklitAsync,
  validateChildMedicareCardByTacklitAsync,
} from "components/forms/shared/shared.resolver";
import {
  DEFAULT_AUTOSAVE_DEBOUNCED_MS,
  MEDICARE_VALIDATION_FAILED_MSG,
} from "core/booking.constant";
import { BookBehalfOfType, ConfirmRadioType } from "enums";
import { useAccessToken } from "hooks/useAccessToken";
import { debounce, isEqual, pick } from "lodash";
import {
  AdultFormData,
  ChildFormData,
  ChildFormNonSeparateData,
  ChildFormSeparateData,
  MedicareFieldName,
  MedicareUnionFormData,
} from "models";
import { MinifiedSignupUserData } from "models/client.model";
import { useCallback, useEffect, useMemo } from "react";
import { Path, useFormContext, useWatch } from "react-hook-form";
import { useAsyncFn } from "react-use";
import { useProfileStore } from "stores/profile.store";
import { match } from "ts-pattern";
import { getAdultRegisteredBasicInfo } from "utils/booking.util";

const keysChildSeparate: (keyof ChildFormSeparateData)[] = [
  "hasChildSeparateCard",
  "childSeparateNameMatchedCard",

  "childSeparateFirstName",
  "childSeparateLastName",
  "childSeparateDateOfBirth",

  "childSeparateMedicareNumber",
  "childSeparateIrn",
  "childSeparateExpiryDate",
];

const keysChildNonSeparate: (keyof ChildFormNonSeparateData)[] = [
  "hasChildSeparateCard",
  "childSeparateNameMatchedCard",

  "parentFirstName",
  "parentLastName",
  "parentDateOfBirth",

  "parentMedicareNumber",
  "parentIrn",
  "parentExpiryDate",
  "childIrn",
];

type Props = {
  behalfOfType: BookBehalfOfType;
  medicareFieldName: MedicareFieldName;
};

export const useMedicareAutosave = <T extends MedicareUnionFormData>(
  props: Props
) => {
  const profile = useProfileStore((state) => state.profile);

  const { accessToken } = useAccessToken();

  const { control, setError, clearErrors } = useFormContext<T>();

  const controlData = useWatch({ control });

  const {
    isRegisteredWithMedicareName = false,

    firstName = "",
    lastName = "",
    dateOfBirth = "",
    medicareNumber = "",
    irnNumber = "",
    expiryDate = "",
  } = controlData;

  const cardBasicInfo = {
    isRegisteredWithMedicareName,
    firstName,
    lastName,
    dateOfBirth,
  };

  const formData = useMemo(() => {
    return match(props?.behalfOfType)
      .with(BookBehalfOfType.MY_CHILD, () => {
        const hasChildSeparate = isEqual(
          controlData?.hasChildSeparateCard,
          ConfirmRadioType.YES
        );

        const childData = pick(
          controlData,
          hasChildSeparate ? [...keysChildSeparate] : [...keysChildNonSeparate]
        );

        return childData as ChildFormData;
      })
      .otherwise(() => {
        const storedProfileData = getAdultRegisteredBasicInfo(
          cardBasicInfo,
          profile
        );

        const storedBasicData = pick<MinifiedSignupUserData>(
          storedProfileData,
          ["firstName", "lastName", "dateOfBirth"]
        );

        return {
          isRegisteredWithMedicareName,
          medicareNumber,
          irnNumber,
          expiryDate,
          ...storedBasicData,
        } as AdultFormData;
      });
  }, [profile, cardBasicInfo]);

  const [validationState, upsertMedicareInfoHookAsync] = useAsyncFn(
    async (medicareInfo): Promise<boolean> => {
      if (!accessToken) return false;

      const validateMedicareAsync = match(props?.behalfOfType)
        .with(BookBehalfOfType.MY_CHILD, () => {
          return validateChildMedicareCardByTacklitAsync;
        })
        .otherwise(() => {
          return validateAdultMedicareCardByTacklitAsync;
        });

      const isValid = await validateMedicareAsync(
        medicareInfo,
        profile,
        accessToken
      );

      if (!isValid) {
        setError(props.medicareFieldName as Path<T>, {
          type: "manual",
          message: MEDICARE_VALIDATION_FAILED_MSG,
        });
      } else if (validationState.value === false) {
        clearErrors(props.medicareFieldName as Path<T>);
      }

      return true;
    },
    [accessToken, props?.behalfOfType, props.medicareFieldName]
  );

  // CHANGES DETECTOR
  useEffect(() => {
    validationState.value = false;

    const isValid = match(props?.behalfOfType)
      .with(BookBehalfOfType.MY_CHILD, () => {
        return isValidChildMedicareFields(formData as ChildFormData);
      })
      .otherwise(() => {
        const schema = getMedicareMySelfFieldsValidation();
        return schema.isValidSync(formData);
      });

    if (!isValid) return;

    debouncedAutosaveMedicareInfoAsync(formData);
  }, [...Object.values(formData)]);

  const debouncedAutosaveMedicareInfoAsync = useCallback(
    debounce(upsertMedicareInfoHookAsync, DEFAULT_AUTOSAVE_DEBOUNCED_MS),
    [accessToken, props.medicareFieldName]
  );

  return {
    isLoading: validationState.loading,
    isValid: validationState.value,
    validationState,
  };
};
