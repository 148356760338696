import {
  DEFAULT_LG_SCREEN_CARD_PER_ROW,
  DEFAULT_PSYCHOLOGIST_CARD_PER_PAGE,
} from "core/booking.constant";
import { useBreakpoint } from "hooks/useBreakpoint";
import { useEffect, useState } from "react";

type Props = {};

export const useListingCardPerRow = (props?: Props) => {
  const breakpoint = useBreakpoint();

  const [listingCardPerRow, setListingCardPerRow] = useState<number>(
    DEFAULT_PSYCHOLOGIST_CARD_PER_PAGE
  );

  useEffect(() => {
    if (breakpoint.isLg) {
      setListingCardPerRow(DEFAULT_LG_SCREEN_CARD_PER_ROW);
    } else {
      setListingCardPerRow(DEFAULT_PSYCHOLOGIST_CARD_PER_PAGE);
    }
  }, [breakpoint]);

  return {
    LISTING_CARD_PER_ROW: listingCardPerRow,
  };
};
