import { AxiosResponse } from "axios";
import { differenceInYears, format } from "date-fns";
import { parsePhoneNumber } from "libphonenumber-js";

import { SignupFormData } from "components/forms/signup/SignupForm";
import {
  AGE_ADULT,
  DEFAULT_PHONE_COUNTRY_CODE,
  DEFAULT_TIMEZONE,
} from "core/booking.constant";
import { globalConfig } from "core/config";
import { ProfileUpdatePayload, TimeSlot } from "models";
import {
  ClientRecord,
  CreateClientMinifiedClientRecord,
  RecordType,
  SignupUserData,
} from "models/client.model";
import {
  toClientDateOfBirthFormat,
  toHourMinuteFormat,
  toISODateFormat,
} from "utils";
import {
  ClinicianApiClient,
  NotifyApiClient,
  PatientProfileApiClient,
  ScheduleApiClient,
  getSessionAuthTokenOrDefault,
} from "./axios.service";
import { ConsultPreferenceType } from "enums";

type PhoneNumberValidateResponse = {
  valid: boolean;
};

type EmailValidateResponse = {
  used: boolean;
};

export const toTacklitPhoneNumberFormat = (phoneNumber: string): string => {
  try {
    const parsedPhone = parsePhoneNumber(
      phoneNumber,
      DEFAULT_PHONE_COUNTRY_CODE
    );

    return parsedPhone.number;
  } catch (error) {
    return phoneNumber;
  }
};

export const validatePhoneNumberByTacklitAsync = async (
  phoneNumber: string
): Promise<boolean> => {
  const response = await NotifyApiClient.put<PhoneNumberValidateResponse>(
    "validator/phoneNumber:validateFormat",
    {
      number: toTacklitPhoneNumberFormat(phoneNumber),
    }
  );

  return response.data.valid;
};

export const validateEmailByTacklitAsync = async (
  email: string
): Promise<boolean> => {
  const response = await ClinicianApiClient.patch<EmailValidateResponse>(
    `/accounts/${globalConfig.ACCOUNT_ID}/client-records:checkEmailAlreadyExisted`,
    {
      email: email,
    }
  );

  return response.data.used;
};

type NewClientReserveAppointmentRequestPayload = {
  appointmentTypeId: string;
  clinicianId: string;
  deliveryType: string;
  isNewClient: boolean;
  slots: NewClientReserveAppointmentRequestTimeSlot[];
};

type NewClientReserveAppointmentRequestTimeSlot = TimeSlot & {
  date: string;
  endDate: string;
};

type NewClientReserveResponse = {
  reserveId: string;
  clinicianId: string;
};

type ReservePayload = {
  timeSlotSelected: TimeSlot;
  appointmentTypeSelected: string;
  isNewClient: boolean;

  consultMethod: ConsultPreferenceType;
};

export const getReserveAppointmentForNewClientPayload = ({
  timeSlotSelected,
  appointmentTypeSelected,
  consultMethod,
  isNewClient = true,
}: ReservePayload): NewClientReserveAppointmentRequestPayload => {
  return {
    appointmentTypeId: appointmentTypeSelected,
    clinicianId: timeSlotSelected.clinicianId,
    deliveryType: consultMethod,
    isNewClient: isNewClient,
    slots: [
      {
        isAvailable: timeSlotSelected.isAvailable,
        startTime: toHourMinuteFormat(timeSlotSelected.startDateTime),
        endTime: toHourMinuteFormat(timeSlotSelected.endDateTime),
        startDateTime: timeSlotSelected.startDateTime,
        endDateTime: timeSlotSelected.endDateTime,
        clinicianId: timeSlotSelected.clinicianId,
        date: format(timeSlotSelected.startDateTime, "yyyy-MM-dd"),
        endDate: format(timeSlotSelected.endDateTime, "yyyy-MM-dd"),
      },
    ],
  };
};

export const reserveAppointmentForNewClientAsync = async ({
  timeSlotSelected,
  appointmentTypeSelected,
  consultMethod,
  isNewClient,
}: ReservePayload): Promise<AxiosResponse<NewClientReserveResponse>> => {
  return await ScheduleApiClient.post<NewClientReserveResponse>(
    `/accounts/${globalConfig.CLIENT_ID}/reserved-appointments`,
    getReserveAppointmentForNewClientPayload({
      timeSlotSelected,
      appointmentTypeSelected,
      consultMethod,
      isNewClient,
    })
  );
};

export type CreateClientProfileRequestPayload = {
  clinicianId: string;
  clientRecord: CreateClientMinifiedClientRecord;
  withoutParentAllowed?: boolean;
};

export type CreateClientProfileResponse = {
  clientRecord: ClientRecord;
  authToken: string;
};

export const getCreateClientProfilePayload = (
  clientRecord: SignupFormData,
  psychologistId?: string,
  appointmentTypeId?: string
): CreateClientProfileRequestPayload => {
  const agePatient = differenceInYears(new Date(), new Date(clientRecord.dob));
  const isYoungPerson = agePatient < AGE_ADULT;

  return {
    clinicianId: psychologistId ?? "",
    clientRecord: {
      avatar: "",
      mobile: toTacklitPhoneNumberFormat(clientRecord.phone),
      email: clientRecord.email,
      name: `${clientRecord.firstName} ${clientRecord.lastName}`,
      password: clientRecord.password,
      dateOfBirth: toClientDateOfBirthFormat(clientRecord.dob),
      firstName: clientRecord.firstName,
      lastName: clientRecord.lastName,
      postCode: "",
      timeZone: DEFAULT_TIMEZONE,
      communicationPreference: "noPreference",
      appointmentTypeId: appointmentTypeId ?? undefined,
      atsi: clientRecord?.isAtsi ? "Yes" : "Decline",
      recordType: isYoungPerson ? RecordType.YOUNG_PERSON : RecordType.ADULT,
    },
    withoutParentAllowed: isYoungPerson ? true : undefined,
  };
};

export const createClientProfileAsync = async (
  clientRecord: SignupFormData,
  psychologistId?: string,
  appointmentTypeId?: string
): Promise<AxiosResponse<CreateClientProfileResponse>> => {
  const payload = getCreateClientProfilePayload(
    clientRecord,
    psychologistId,
    appointmentTypeId
  );

  return PatientProfileApiClient.post<CreateClientProfileResponse>(
    `/accounts/${globalConfig.CLIENT_ID}/patients`,
    payload
  );
};

export const getSignupUserData = (
  data: SignupFormData,
  authToken: string
): SignupUserData => {
  return {
    mobile: toTacklitPhoneNumberFormat(data.phone),
    email: data.email,
    name: `${data.firstName} ${data.lastName}`,
    password: data.password,
    dateOfBirth: toISODateFormat(data.dob),
    firstName: data.firstName,
    lastName: data.lastName,
    timeZone: DEFAULT_TIMEZONE,
    authToken: authToken,
  };
};

export const updatePatientProfileAsync = (
  payload: ProfileUpdatePayload,
  accessToken?: string
) => {
  return PatientProfileApiClient.put(
    "/patients/me",
    payload,
    getSessionAuthTokenOrDefault(accessToken)
  );
};
