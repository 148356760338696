export enum Alignment {
  LEFT = "left",
  CENTER = "center",
  RIGHT = "right",
  BETWEEN = "between",
}

export enum BadgeTypes {
  SOLID = "solid",
  OUTLINED = "outlined",
  PLAIN = "plain",
  SECONDARY = "secondary",
  SECONDARY_LIGHT = "secondary-light",
}

export enum BadgeSizes {
  MEDIUM = "md",
  SMALL = "sm",
}

export enum BannerTitleSize {
  LARGE = "large",
  MEDIUM = "medium",
}

export enum BannerButtonAlignment {
  LEFT = "left",
  CENTER = "center",
  RIGHT = "right",
}

export enum ButtonTypes {
  SOLID = "solid",
  OUTLINED = "outlined",
  PLAIN = "plain",
  SOFT = "soft",
}

export enum ButtonSizes {
  LARGE = "lg",
  MEDIUM = "md",
  SMALL = "sm",
  EXTRA_SMALL = "xs",
}

export enum ButtonHoverStyles {
  NONE = "none",
  SOLID = "solid",
  OUTLINED = "outlined",
  PLAIN = "plain",
  TRANSITION = "transition",
  SOFT = "soft",
}

export enum Displays {
  BLOCK = "block",
  FLEX = "flex",
}

export enum Underline {
  HOVER = "hover",
  ALWAYS = "always",
  NONE = "none",
}

export enum Layout {
  COLUMN = "column",
  ROW = "row",
}

export enum Colors {
  PRIMARY = "primary",
  SECONDARY = "secondary",
  DARK = "dark",
  LIGHT = "light",
  NEUTRAL = "neutral",
  DISABLED = "disabled",
}

export enum AtomComponents {
  BUTTON = "button",
  HEADING = "heading",
  BADGE = "badge",
  HINT = "hint",
  TEXT = "text",
  RICH_TEXT = "richText",
  LINK = "link",
}
