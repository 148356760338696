import { useAuth0 } from "@auth0/auth0-react";
import { StoryblokComponent, storyblokEditable } from "@storyblok/react";
import { first, isArray, isEmpty, isEqual } from "lodash";
import { ReactElement, useCallback, useMemo } from "react";
import {
  useBeforeUnload,
  useMount,
  useSearchParam,
  useToggle,
} from "react-use";
import {
  ButtonStoryblok,
  ThankYouPageStoryblok,
} from "types/component-types-sb";
import { Props } from "types/core";
import { v4 } from "uuid";

import { SpinnerLoading } from "assets/icons/SpinnerLoading";
import { SUPPORT_THANK_YOU_URL } from "core/booking.constant";
import {
  AtomComponents,
  BookingRuleType,
  ButtonHoverStyles,
  ButtonSizes,
  ButtonTypes,
  UrlReturn,
} from "enums";
import { useGtmDataLayer } from "hooks/useGtmDataLayer";
import { useSwrBookingRuleType } from "hooks/useSwrBookingRuleType";
import { useSwrLastAppointment } from "hooks/useSwrLastAppointment";
import { useSwrMyProfile } from "hooks/useSwrMyProfile";
import { Funding } from "models";
import { Profile } from "models/client.model";
import { ExternalRoutes, Routes } from "routes/main.routes";
import { TacklitService } from "services/tacklit.service";
import {
  getAttachedPsychologistAuth0,
  isFilledRequiredFieldsGP,
  openInNewTab,
  redirectTo,
  redirectToPsychologistDetails,
} from "utils";
import { isClientFirstPsychologistAppointment } from "utils/booking.util";
import {
  getStoredFundingMethod,
  getStoredReserveData,
  getStoredSignupSessionUser,
  removeStoredReserveData,
} from "utils/storage.util";
import {
  renderBackToPsychologistsButton,
  renderSessionBookingWarning,
  validateStoredBookingData,
} from "utils/warning.util";
import { AppointmentSummarySection } from "../book-finalise/AppointmentSummarySection";
import { useLoginNavigate } from "hooks/useLoginNavigate";
import { getSessionStoreData } from "stores/session.store";

export const ThankYouPage = ({ blok }: Props<ThankYouPageStoryblok>) => {
  const GtmDataLayer = useGtmDataLayer();

  const { isAuthenticated, getAccessTokenSilently } = useAuth0();
  const { loginRedirectTacklit } = useLoginNavigate();

  const { data: bookingRuleType } = useSwrBookingRuleType();
  const { data: profile } = useSwrMyProfile();

  const { data: appointmentHistory } = useSwrLastAppointment();
  const { lastGpAppointment } = appointmentHistory || {};

  const hasBookedGpAppointment = useMemo(() => {
    if (!profile || !lastGpAppointment) return false;

    return lastGpAppointment && isFilledRequiredFieldsGP(profile);
  }, [profile, lastGpAppointment]);

  const [isNewClient, toggleNewClient] = useToggle(true);
  const [isLoading, toggleLoading] = useToggle(true);

  const attachedPsychologistId = getAttachedPsychologistAuth0();
  const storedReserveData = getStoredReserveData();

  const paramReturnUrl = useSearchParam("return");

  const renderBtnIfPsyUnavailable = useCallback((): ReactElement => {
    if (paramReturnUrl !== UrlReturn.OUR_PSYCHOLOGISTS) return <></>;

    return renderBackToPsychologistsButton({ visible: true });
  }, [paramReturnUrl]);

  useMount(async () => {
    if (
      !blok.isBooking ||
      (!isAuthenticated && !Boolean(getStoredSignupSessionUser()))
    ) {
      toggleLoading(false);

      return;
    }

    const isNewBooking: boolean = isEqual(
      bookingRuleType?.bookingRuleType,
      BookingRuleType.NEW
    );

    const accessToken = isAuthenticated
      ? await getAccessTokenSilently()
      : undefined;

    const isFirstAppointment: boolean =
      await isClientFirstPsychologistAppointment(accessToken);

    const newClient: boolean = [
      isNewBooking,
      isFirstAppointment,
      !isAuthenticated,
    ].some(Boolean);

    toggleNewClient(newClient);

    if (isFirstAppointment && !isEmpty(storedReserveData)) {
      try {
        await TacklitService.addClientToGroupAsync(accessToken);
      } catch (error) {
        console.log("[error ]: >> ", error);
      }
    }

    if (isFirstAppointment) {
      GtmDataLayer.pushEventFirstBooked();
    } else {
      GtmDataLayer.pushEventSubsequentBooked();
    }

    toggleLoading(false);
  });

  useBeforeUnload(() => {
    removeStoredReserveData();

    return false;
  });

  const storedBookingDataResult = validateStoredBookingData({
    shouldCheckAppointmentData: false,
    shouldCheckFundingMethod: false,
    shouldCheckSignedUpData:
      window.location.pathname === Routes.CONTACT_US_THANK_YOU
        ? false
        : !isAuthenticated,
  });

  const filterButtons = (blok: ThankYouPageStoryblok, isButton: boolean) => {
    if (!isArray(blok?.content)) return [];

    return blok.content.filter((item) =>
      isButton
        ? item?.component === AtomComponents.BUTTON
        : item?.component !== AtomComponents.BUTTON
    );
  };

  if (!storedBookingDataResult.isEligible) {
    return renderSessionBookingWarning(storedBookingDataResult);
  }

  return (
    <div
      {...storyblokEditable(blok)}
      className="flex flex-col my-14 lg:my-28 gap-14 lg:gap-28 lg:flex-row"
    >
      <div className="flex flex-col lg:flex-1 gap-y-9 lg:order-last">
        {blok.isBooking && (
          <AppointmentSummarySection shouldCountDown={false} />
        )}

        {blok.summary?.map((item) => (
          <StoryblokComponent key={blok._uid} blok={item} />
        ))}
      </div>

      <div className="flex flex-col lg:flex-1 xl:flex-[2] gap-y-9 md:pl-10">
        {isLoading ? (
          <div className="flex justify-center items-center h-full">
            <SpinnerLoading />
          </div>
        ) : (
          <>
            {!blok.isBooking || (blok.isBooking && isNewClient) ? (
              <>
                {/* HEADING - CONTENT */}
                {filterButtons(blok, false).map((item) => (
                  <StoryblokComponent key={item._uid} blok={item} />
                ))}

                {/* DOWNLOAD - BACK BUTTONS */}
                <div className="flex flex-wrap gap-3">
                  {filterButtons(blok, true).map((item) => (
                    <StoryblokComponent key={item._uid} blok={item} />
                  ))}

                  {renderBtnIfPsyUnavailable()}
                </div>

                {/* Contact - Thank you: isBooking = false */}
                {blok.isBooking &&
                  renderFirstAppointmentButtons({
                    profile,
                    hasBookedGpAppointment,
                    loginRedirectTacklit,
                  })}
              </>
            ) : (
              <>
                {blok.signedInContent?.map((item) => (
                  <StoryblokComponent key={item._uid} blok={item} />
                ))}
                {renderNthAppointmentButtons(attachedPsychologistId)}
              </>
            )}
          </>
        )}
      </div>
    </div>
  );
};

const renderNthAppointmentButtons = (psychologistSlugUrl?: string) => {
  const bookAnotherAppointmentBtn: ButtonStoryblok = {
    _uid: v4(),
    component: "button",
    name: "Book Another Appointment",
    type: ButtonTypes.SOLID,
    hoverStyle: ButtonHoverStyles.TRANSITION,
    size: ButtonSizes.MEDIUM,
  };

  const handleBookAnotherAppointmentClick = () => {
    redirectToPsychologistDetails(psychologistSlugUrl);
  };

  const browseButton: ButtonStoryblok = {
    _uid: v4(),
    component: "button",
    name: "Browse our blog",
    type: ButtonTypes.OUTLINED,
    hoverStyle: ButtonHoverStyles.TRANSITION,
    size: ButtonSizes.MEDIUM,
  };

  return (
    <div className="flex flex-col md:flex-row gap-x-0 gap-y-5 md:gap-x-5 md:gap-y-0">
      <StoryblokComponent
        key={bookAnotherAppointmentBtn._uid}
        blok={bookAnotherAppointmentBtn}
        onClick={handleBookAnotherAppointmentClick}
      />

      <StoryblokComponent
        key={browseButton._uid}
        blok={browseButton}
        onClick={() => openInNewTab(Routes.BLOG)}
      />
    </div>
  );
};

const renderFirstAppointmentButtons = (params: {
  profile: Profile | null | undefined;
  hasBookedGpAppointment: boolean;
  loginRedirectTacklit: Function;
}) => {
  const { profile, hasBookedGpAppointment, loginRedirectTacklit } =
    params || {};

  const isMedicareReferralBooking = !isMedicareNoReferral();
  const isReferredByGP = Boolean(profile?.referral?.isReferredByGP);

  if (isMedicareReferralBooking || isReferredByGP || hasBookedGpAppointment) {
    const bookAnotherAppointmentBtn: ButtonStoryblok = {
      _uid: v4(),
      component: "button",
      name: "What happens next?",
      type: ButtonTypes.SOLID,
      hoverStyle: ButtonHoverStyles.TRANSITION,
      size: ButtonSizes.MEDIUM,
    };

    const browseButton: ButtonStoryblok = {
      _uid: v4(),
      component: "button",
      name: "Browse our blog",
      type: ButtonTypes.OUTLINED,
      hoverStyle: ButtonHoverStyles.TRANSITION,
      size: ButtonSizes.MEDIUM,
    };

    return (
      <div className="flex flex-col md:flex-row gap-x-0 gap-y-5 md:gap-x-5 md:gap-y-0">
        <StoryblokComponent
          key={bookAnotherAppointmentBtn._uid}
          blok={bookAnotherAppointmentBtn}
          onClick={() => openInNewTab(SUPPORT_THANK_YOU_URL)}
        />

        <StoryblokComponent
          key={browseButton._uid}
          blok={browseButton}
          onClick={() => openInNewTab(Routes.BLOG)}
        />
      </div>
    );
  }

  const gpNavigateButton: ButtonStoryblok = {
    _uid: v4(),
    component: "button",
    name: "Get a GP Appointment",
    type: ButtonTypes.SOLID,
    hoverStyle: ButtonHoverStyles.TRANSITION,
    size: ButtonSizes.MEDIUM,
  };

  const redirectGpReferralOnClick = () => {
    const { isAuthenticated } = getSessionStoreData() || {};

    if (isAuthenticated) {
      redirectTo(ExternalRoutes.GP_BOOKING);

      return;
    }

    loginRedirectTacklit(ExternalRoutes.GP_BOOKING);
  };

  return (
    <div className="w-full flex justify-center items-center">
      <StoryblokComponent
        key={gpNavigateButton._uid}
        blok={gpNavigateButton}
        onClick={redirectGpReferralOnClick}
      />
    </div>
  );
};

const isMedicareNoReferral = (): boolean => {
  const storedFunding = getStoredFundingMethod();
  if (!storedFunding) return false;

  const isMedicareBooking = [Funding.BULK_BILL, Funding.REBATE].includes(
    storedFunding
  );

  if (!isMedicareBooking) return false;

  const storedReserveData = getStoredReserveData();

  const lastAppointment = first(
    storedReserveData?.reserveAppointment.appointments
  );

  return !lastAppointment?.isHaveGPReferralAndTreatmentPlan;
};
