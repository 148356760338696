import { v4 } from "uuid";

import { cn } from "utils";

type TextAreaProps = {
  name: string;
  title: string;

  placeholder?: string;
  numberRows?: number;
  noResize?: boolean;

  onChangeValue: (event: React.ChangeEvent<HTMLTextAreaElement>) => void;
};

export const TextAreaInput = ({
  name,
  title,
  placeholder,
  numberRows = 2,
  noResize = false,
  ...props
}: TextAreaProps) => {
  const elemId = v4();

  const textAreaClassnames = cn(
    "w-full py-3 pl-5 border rounded-md pr-9 border-light-grey-3 focus:border-secondary-darker focus:ring-secondary-darker",
    { "resize-none": noResize }
  );

  return (
    <div className="flex flex-col gap-y-1 xl:h-full">
      <label htmlFor={elemId} className="text-dark-grey">
        {title}
      </label>

      <textarea
        id={elemId}
        name={name}
        className={cn(textAreaClassnames, "xl:h-full")}
        placeholder={placeholder}
        rows={numberRows}
        onChange={props.onChangeValue}
      />
    </div>
  );
};
