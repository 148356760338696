import { yupResolver } from "@hookform/resolvers/yup";
import { StoryblokComponent, storyblokEditable } from "@storyblok/react";
import { isAxiosError } from "axios";
import { isArray, isEqual } from "lodash";
import { ReactElement } from "react";
import {
  Controller,
  FormProvider,
  SubmitHandler,
  useForm,
  useWatch,
} from "react-hook-form";
import { useToggle } from "react-use";
import { match } from "ts-pattern";
import { BookNdisFormStoryblok, HintStoryblok } from "types/component-types-sb";
import { Props } from "types/core";

import { SpinnerLoading } from "assets/icons/SpinnerLoading";
import { SelectionDropdown } from "components/selection-dropdown/SelectionDropdown";
import { BookBehalfOfType, ManageRadioType } from "enums";
import { useClientType } from "hooks/useClientType";
import { AddressFieldsType, FileItem, SelectionOption } from "models";
import { Routes } from "routes/main.routes";
import {
  UpdateNDISParams,
  validateNDISAsync,
} from "services/psychologist.service";
import { TacklitService } from "services/tacklit.service";
import { useProfileStore } from "stores/profile.store";
import {
  getDefaultBehalfOfOptions,
  getDefaultButtonStyles,
  getFormHeadingBlok,
  redirectTo,
} from "utils";
import {
  getNDISDefaultFormData,
  handleNdisOrWorkCoverUpdateReferralAsync,
} from "utils/booking.util";
import { setStoredFirstNdisAppointmentIsPlanManaged } from "utils/storage.util";
import { customToast } from "utils/toast.util";
import { BookingInformation } from "../BookingInformation";
import { CountDownClock } from "../CountDownClock";
import { FileInput } from "../FileInput";
import { RadioInput } from "../RadioInput";
import { TextError } from "../TextError";
import { TextInput } from "../TextInput";
import {
  getHintFromStoryBlok,
  MAX_UPLOAD_ANY_DOCUMENT_FILE,
  validFileTypes,
} from "../book-medicare/BookMedicareForm";
import { AddressFields } from "../shared/AddressFields";
import { PhoneValidatorInput } from "../shared/PhoneValidatorInput";
import { getBookNDISFormResolver } from "./book-ndis.resolver";

export type BookNDISFormData = {
  behalfOf: string;

  bookerName?: string;
  bookerPhone?: string;

  parentFirstName?: string;
  parentLastName?: string;
  parentEmail?: string;

  ndisNumber: string;

  plan: string;
  uploadedFiles?: FileItem[];
} & AddressFieldsType;

type BookNDISFormCustomProps = Props<BookNdisFormStoryblok> & {
  accessToken: string;
};

export const BookNDISForm = ({
  blok,
  accessToken,
}: BookNDISFormCustomProps) => {
  const profile = useProfileStore((state) => state.profile);

  const { isExistingClient } = useClientType();

  const [isValidating, toggleValidating] = useToggle(false);

  const formContext = useForm<BookNDISFormData>({
    mode: "onChange",
    criteriaMode: "all",
    defaultValues: getNDISDefaultFormData(profile),
    resolver: yupResolver(getBookNDISFormResolver(accessToken)),
  });

  const {
    control,
    formState: { errors, isValid },
    handleSubmit,
  } = formContext;

  const { behalfOf, plan } = useWatch({ control });

  const isPlanManaged = isEqual(plan, ManageRadioType.PLAN);
  const isSelfManaged = isEqual(plan, ManageRadioType.SELF);

  const isBehalfOfAdult = isEqual(
    behalfOf,
    BookBehalfOfType.SOMEONE_ELSE_ADULT
  );
  const isBehalfOfChild = isEqual(behalfOf, BookBehalfOfType.MY_CHILD);

  const getStreetAddressHint = (): HintStoryblok[] | undefined => {
    return match(behalfOf)
      .with(BookBehalfOfType.MY_SELF, () => undefined)
      .with(BookBehalfOfType.SOMEONE_ELSE_ADULT, () => blok.theirAddressHints)
      .with(BookBehalfOfType.MY_CHILD, () => blok.childAddressHints)
      .otherwise(() => undefined);
  };

  const getBehalfOfHint = (): ReactElement | undefined => {
    return match(behalfOf)
      .with(BookBehalfOfType.MY_SELF, () =>
        getHintFromStoryBlok(blok.helpingIncludeHints)
      )
      .with(BookBehalfOfType.SOMEONE_ELSE_ADULT, () =>
        getHintFromStoryBlok(blok.helpingSomeOneHints)
      )
      .with(BookBehalfOfType.MY_CHILD, () =>
        getHintFromStoryBlok(blok.under18BookingHints)
      )
      .otherwise(() => undefined);
  };

  const validateNDISNumberAsync = async (
    params: UpdateNDISParams
  ): Promise<boolean> => {
    try {
      await validateNDISAsync(params, accessToken);

      customToast.success("Valid NDIS number");

      return true;
    } catch (err) {
      if (isAxiosError(err)) {
        customToast.error("Invalid NDIS number. Please check again.");
      }

      return false;
    }
  };

  const onSubmit: SubmitHandler<BookNDISFormData> = async (formData) => {
    toggleValidating(true);

    try {
      const isValidNDISCheck = await validateNDISNumberAsync({
        plan: formData.plan as ManageRadioType,
        number: formData.ndisNumber,
      });

      if (!isValidNDISCheck) return;

      const isUploaded = await handleNdisOrWorkCoverUpdateReferralAsync({
        formData: formData,
        isExistingClient: isExistingClient,
        accessToken: accessToken,
      });

      if (!isUploaded) return;

      await match(formData.behalfOf)
        .with(BookBehalfOfType.MY_SELF, () => {
          return TacklitService.putContactAddressAsync(formData, accessToken);
        })
        .with(BookBehalfOfType.SOMEONE_ELSE_ADULT, () => {
          return Promise.all([
            TacklitService.putContactAddressAsync(formData, accessToken),
            TacklitService.putAdultContactAsync(formData, accessToken),
          ]);
        })
        .with(BookBehalfOfType.MY_CHILD, () => {
          return Promise.all([
            TacklitService.putContactAddressAsync(formData, accessToken),
            TacklitService.putChildGuardianProfileAsync(formData, accessToken),
          ]);
        })
        .otherwise(() => {});

      setStoredFirstNdisAppointmentIsPlanManaged(isPlanManaged);

      redirectTo(Routes.BOOKING_CONFIRM);
    } catch (err) {
      console.log("[error]: >>", err);

      customToast.error("Error while validating your NDIS number");
    } finally {
      toggleValidating(false);
    }
  };

  const behalfOfOptions = getDefaultBehalfOfOptions(profile);

  return (
    <div
      className="flex flex-col w-full gap-12 px-0 py-10 md:flex-row lg:px-20"
      {...storyblokEditable(blok)}
    >
      <FormProvider {...formContext}>
        <form
          className="flex flex-[2] gap-y-5 flex-col order-2 md:order-1"
          onSubmit={handleSubmit(onSubmit)}
        >
          <StoryblokComponent blok={getFormHeadingBlok(blok.title)} />

          <BookingInformation />

          <Controller
            name="behalfOf"
            control={control}
            render={({ field }) => (
              <div className="flex flex-col">
                <SelectionDropdown
                  isLoading={false}
                  title="I am booking on behalf of:"
                  placeHolder={"Select..."}
                  hintComponent={getBehalfOfHint()}
                  selectedValues={behalfOfOptions.filter(
                    ({ value }) => value === field.value
                  )}
                  options={behalfOfOptions}
                  onChangeSingleSelection={(selectedOption: SelectionOption) =>
                    field.onChange(selectedOption.value)
                  }
                  isShowTitle
                  isShowHint
                  isRequired
                />
                <TextError fieldError={errors.behalfOf} />
              </div>
            )}
          />

          <div className="flex flex-col gap-y-3">
            {/* BEHALF OF SOMEONE */}
            {isBehalfOfAdult && (
              <div className="flex flex-col items-start sm:flex-row mb-1 gap-x-5 gap-y-3">
                <div className="flex flex-col flex-1 w-full lg:min-w-[308px] gap-y-1">
                  <Controller
                    name="bookerName"
                    control={control}
                    render={({ field }) => (
                      <TextInput
                        title="Your name"
                        onChangeValue={field.onChange}
                      />
                    )}
                  />

                  <TextError fieldError={errors.bookerName} />
                </div>

                <div className="flex flex-col flex-1 w-full lg:min-w-[308px] gap-y-1">
                  <Controller
                    name="bookerPhone"
                    control={control}
                    render={({ field }) => (
                      <PhoneValidatorInput field={field} label="Your phone" />
                    )}
                  />
                </div>
              </div>
            )}

            {/* BEHALF OF CHILD */}
            {isBehalfOfChild && (
              <div className="flex flex-col gap-y-3">
                <div className="flex flex-col lg:flex-row items-stretch lg:items-start gap-x-5 gap-y-3">
                  <div className="flex flex-col flex-1">
                    <Controller
                      name="parentFirstName"
                      control={control}
                      render={({ field }) => (
                        <TextInput
                          title="Parent first name"
                          onChangeValue={field.onChange}
                        />
                      )}
                    />
                    <TextError fieldError={errors.parentFirstName} />
                  </div>

                  <div className="flex flex-col flex-1">
                    <Controller
                      name="parentLastName"
                      control={control}
                      render={({ field }) => (
                        <TextInput
                          title="Parent last name"
                          onChangeValue={field.onChange}
                        />
                      )}
                    />
                    <TextError fieldError={errors.parentLastName} />
                  </div>
                </div>

                <Controller
                  name="parentEmail"
                  control={control}
                  render={({ field }) => (
                    <div className="flex flex-col flex-1">
                      <TextInput
                        title="Parent email address"
                        onChangeValue={field.onChange}
                      />
                      <TextError fieldError={errors.parentEmail} />
                    </div>
                  )}
                />
              </div>
            )}

            {/* ADDRESS */}
            <AddressFields<BookNDISFormData>
              streetAddressHints={getStreetAddressHint()}
              control={control}
              errors={errors}
              behalfOfType={behalfOf as BookBehalfOfType}
            />

            <div className="flex flex-col flex-1 gap-y-1">
              <Controller
                name="ndisNumber"
                control={control}
                render={({ field }) => (
                  <TextInput
                    title="NDIS Number"
                    onChangeValue={field.onChange}
                    hintElement={undefined}
                    numberOnly
                  />
                )}
              />
              <TextError fieldError={errors.ndisNumber} />
            </div>
          </div>

          <div className="flex flex-col p-5 gap-y-3 bg-neutral-100 rounded-xl">
            <div className="flex flex-row items-center justify-between">
              <span>Are they plan or self-managed?</span>
            </div>

            <Controller
              name="plan"
              control={control}
              render={({ field }) => (
                <div className="flex flex-row gap-x-5">
                  <RadioInput
                    {...field}
                    label="Plan"
                    value={ManageRadioType.PLAN}
                    checked={isPlanManaged}
                  />

                  <RadioInput
                    {...field}
                    label="Self-managed"
                    value={ManageRadioType.SELF}
                    checked={isSelfManaged}
                  />
                </div>
              )}
            />
          </div>

          <div className="flex flex-col gap-y-3">
            <Controller
              name="uploadedFiles"
              control={control}
              render={({ field }) => (
                <div className="flex flex-col flex-1 gap-y-1.5">
                  <FileInput
                    title="Upload any documents"
                    validFileTypes={validFileTypes}
                    maxUploaded={MAX_UPLOAD_ANY_DOCUMENT_FILE}
                    selectedFiles={field.value || []}
                    blokHints={blok.documentUploadHints}
                    onChangeFiles={(selectedFiles: FileItem[]) => {
                      if (!isArray(field.value)) return;

                      field.onChange([...field.value, ...selectedFiles]);
                    }}
                    onRemoveFile={(fileId: string) => {
                      if (!isArray(field.value)) return;

                      field.onChange(
                        field.value.filter(({ id }) => id !== fileId)
                      );
                    }}
                  />
                  <TextError fieldError={errors.uploadedFiles} />
                </div>
              )}
            />
          </div>

          <div className="flex items-center justify-start mt-2">
            <button
              type="submit"
              disabled={isValidating || !isValid}
              className={getDefaultButtonStyles(isValidating)}
            >
              Next
            </button>
            {isValidating && <SpinnerLoading className="w-12 h-12 ml-3" />}
          </div>
        </form>
      </FormProvider>

      <div className="flex flex-col flex-1 gap-y-3 order-1 h-fit md:order-2">
        {blok.actions?.map((actionBlok) => (
          <StoryblokComponent key={actionBlok._uid} blok={actionBlok} />
        ))}

        <div className="flex bg-light-grey p-7 min-w-[280px] w-full rounded-xl">
          {/* COUNTDOWN CLOCK */}
          <CountDownClock />
        </div>
      </div>
    </div>
  );
};
