import { StoryblokComponent, storyblokEditable } from "@storyblok/react";
import { CheckpointStoryblok } from "types/component-types-sb";
import { Props } from "types/core";
import { cn } from "utils";
import { isEngagePages } from "utils/blog.util";

export const CheckPoint = ({ blok }: Props<CheckpointStoryblok>) => {
  return (
    <div
      className={cn("flex flex-row items-center justify-start gap-2", {
        "w-full": blok?.isFullWidth,
      })}
      {...storyblokEditable(blok)}
    >
      <img src={blok.icon?.filename} alt={blok.icon_alt} className="w-6 h-6" />
      <div
        className={cn({
          "flex-1": blok?.isFullWidth,
          "line-clamp-2": isEngagePages(),
        })}
        title={blok.text}
      >
        {blok.text}
      </div>

      {blok?.hint?.map((hint) => (
        <span key={hint._uid} className="ms-0">
          <StoryblokComponent blok={hint} />
        </span>
      ))}
    </div>
  );
};
