import { DEFAULT_PHONE_COUNTRY_CODE } from "core/booking.constant";
import { PreferredContactMethodType } from "enums";
import { isValidPhoneNumber } from "libphonenumber-js";
import * as Yup from "yup";

export const getContactUsFormResolver = () => {
  return Yup.object().shape({
    subject: Yup.string(),
    firstName: Yup.string().trim().required("First name is required"),
    lastName: Yup.string().trim().required("Last name is required"),
    email: Yup.string()
      .email("Email must be a valid email")
      .required("Email is required"),
    phoneNumber: Yup.string()
      .required("Phone number is required")
      .test({
        name: "phone",
        message: "Phone number is not valid",
        test: (phoneNumber = "", context) => {
          if (!isValidPhoneNumber(phoneNumber, DEFAULT_PHONE_COUNTRY_CODE)) {
            return context.createError({
              path: "phoneNumber",
              message: "Phone number is not valid",
            });
          }

          return true;
        },
      }),
    comments: Yup.string().trim().required("Comments is required"),
    preferredContactMethod: Yup.string()
      .required()
      .oneOf([
        PreferredContactMethodType.BY_PHONE,
        PreferredContactMethodType.BY_EMAIL,
      ]),
    isReceiveUpdates: Yup.boolean().required(),
  });
};
