import {
  HeadingStoryblok,
  PsychologistDetailExperienceStoryblok,
  RichTextStoryblok,
} from "@/types/component-types-sb";
import { Props } from "@/types/core";
import { StoryblokComponent, storyblokEditable } from "@storyblok/react";
import React from "react";
import { v4 } from "uuid";

import { PsychologistResponse } from "@/services/psychologist.service";
import { ExpandableText } from "components/shared/ExpandableText";
import { useBreakpoint } from "hooks/useBreakpoint";
import { Separator } from "./Separator";

type CustomProps = Props<PsychologistDetailExperienceStoryblok> & {
  psychologist: PsychologistResponse;
};

export const PsychologistDetailExperience = ({
  blok,
  psychologist,
}: CustomProps) => {
  const breakpoint = useBreakpoint();

  const { helmControl } = psychologist || {};

  const headingBlok = {
    content: blok.title,
    level: "h3",
    fontWeight: 400,
    align: "left",
    component: "heading",
  } as HeadingStoryblok;

  const getRichTextBlok = (text: string): RichTextStoryblok =>
    ({
      _uid: v4(),
      component: "richText",
      width: "100",
      fontSize: "text-base",
      textAlign: "left",
      content: {
        type: "doc",
        content: [
          {
            type: "paragraph",
            content: [
              {
                type: "text",
                text: text,
              },
            ],
          },
        ],
      },
    } as RichTextStoryblok);

  return (
    <div
      {...storyblokEditable(blok)}
      className="flex flex-col items-start justify-center gap-5 w-full"
    >
      <StoryblokComponent blok={headingBlok} />

      {breakpoint.isMobile ? (
        <React.Fragment>
          <ExpandableText text={helmControl?.credentials} />
          <Separator />
        </React.Fragment>
      ) : (
        <div className="leading-7">
          <StoryblokComponent
            blok={getRichTextBlok(helmControl?.credentials)}
          />
        </div>
      )}
    </div>
  );
};
