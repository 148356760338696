/* eslint-disable react-hooks/exhaustive-deps */
import { yupResolver } from "@hookform/resolvers/yup";
import { StoryblokComponent, storyblokEditable } from "@storyblok/react";
import { debounce, defaultTo, isEmpty, isEqual, isNil, toNumber } from "lodash";
import { ReactElement, useCallback, useEffect } from "react";
import {
  Controller,
  FormProvider,
  SubmitHandler,
  useForm,
  useWatch,
} from "react-hook-form";
import { useFirstMountState, useToggle } from "react-use";
import { match } from "ts-pattern";
import { BookDvaFormStoryblok, HintStoryblok } from "types/component-types-sb";
import { Props } from "types/core";
import * as Yup from "yup";

import { SpinnerLoading } from "assets/icons/SpinnerLoading";
import { AxiosError } from "axios";
import { DvaWhiteCardMedicareFields } from "components/forms/book-dva/DvaWhiteCardMedicareFields";
import { DEFAULT_AUTOSAVE_DEBOUNCED_MS } from "core/booking.constant";
import { MEDICARE_NUMBER_REGEX, ONLY_DIGITS_REGEX } from "core/regex.constant";
import { DVACardType } from "enums";
import { useAccessToken } from "hooks/useAccessToken";
import { useClientType } from "hooks/useClientType";
import { AddressFieldsType } from "models";
import { Profile } from "models/client.model";
import { Routes } from "routes/main.routes";
import {
  UpdateMedicareParams,
  validateMedicareAsync,
} from "services/psychologist.service";
import { TacklitService } from "services/tacklit.service";
import { useProfileStore } from "stores/profile.store";
import {
  getDefaultButtonStyles,
  getFormHeadingBlok,
  redirectTo,
  toExpiryDate,
} from "utils";
import {
  getDVADefaultFormData,
  getUserMedicareBasicInformation,
  handleUploadNoReferralAsync,
} from "utils/booking.util";
import { customToast } from "utils/toast.util";
import { BookingInformation } from "../BookingInformation";
import { CountDownClock } from "../CountDownClock";
import { RadioInput } from "../RadioInput";
import { TextError } from "../TextError";
import { TextInput } from "../TextInput";
import { getHintFromStoryBlok } from "../book-medicare/BookMedicareForm";
import { AddressFields } from "../shared/AddressFields";
import { getBookDVAFormResolver } from "./book-dva.resolver";
import { InputValidationSpinner } from "components/forms/shared/InputValidationSpinner";

export type WhiteCardFormData = {
  dvaCardNumber: string | undefined;
  medicareNumber: string | undefined;
  irnNumber: string | undefined;
  expiryDate: string | undefined;
};

export type BookDVAFormData = {
  dvaCardType: string;

  dvaCardNumber: string;
  medicareNumber?: string;
  irnNumber?: string;
  expiryDate?: string;
} & AddressFieldsType;

export const BookDVAForm = ({ blok }: Props<BookDvaFormStoryblok>) => {
  const { accessToken, isLoadingToken } = useAccessToken();

  const profile = useProfileStore((state) => state.profile);

  const isFirstMounted = useFirstMountState();

  const { isExistingClient } = useClientType();

  const [isValidating, toggleValidating] = useToggle(false);

  const schema = getBookDVAFormResolver();

  const formContext = useForm<BookDVAFormData>({
    mode: "onChange",
    criteriaMode: "all",
    defaultValues: getDVADefaultFormData(profile),
    resolver: yupResolver(schema),
  });

  const {
    control,
    formState: { errors, isValid, isValidating: isFormValidating },
    handleSubmit,
    setError,
    clearErrors,
  } = formContext;

  const { dvaCardType, dvaCardNumber, medicareNumber, irnNumber, expiryDate } =
    useWatch({ control });

  const isChooseWhiteDVACard = isEqual(dvaCardType, DVACardType.WHITE);
  const isChooseGoldDVACard = isEqual(dvaCardType, DVACardType.GOLD);

  const isDisabledForm =
    isValidating || !isValid || isFormValidating || !isEmpty(errors);

  const userMedicareInformation = getUserMedicareBasicInformation(profile);

  useEffect(() => {
    if (!isChooseWhiteDVACard) return;

    const formData = {
      dvaCardNumber,
      medicareNumber,
      irnNumber,
      expiryDate,
    };

    const whiteCardSchema = Yup.object().shape({
      dvaCardNumber: Yup.string().required().length(8),
      medicareNumber: Yup.string().required().matches(MEDICARE_NUMBER_REGEX),
      irnNumber: Yup.string().required().matches(ONLY_DIGITS_REGEX),
      expiryDate: Yup.string().required(),
    });

    const isValidWhiteCard = whiteCardSchema.isValidSync(formData);

    const shouldCancelValidateMedicareCard =
      !isValidWhiteCard ||
      isFirstMounted ||
      isLoadingToken ||
      isNil(accessToken);

    if (shouldCancelValidateMedicareCard) return;

    debounceValidateMedicareCardAsync(formData, profile);
  }, [dvaCardNumber, medicareNumber, irnNumber, expiryDate, dvaCardType]);

  const validateMedicareCardByTacklit = async (
    formData: WhiteCardFormData,
    profile: Profile
  ): Promise<void> => {
    const userMedicareInformation = getUserMedicareBasicInformation(profile);

    const { firstName, lastName, dateOfBirth } = userMedicareInformation || {};

    try {
      toggleValidating(true);

      await validateMedicareAsync(
        {
          firstName: defaultTo(firstName, ""),
          lastName: defaultTo(lastName, ""),
          dateOfBirth: defaultTo(dateOfBirth, ""),
          number: toNumber(formData.medicareNumber),
          expiryDate: toExpiryDate(formData.expiryDate),
          irn: toNumber(formData.irnNumber),
          dva: formData.dvaCardNumber,
          shouldRejectInvalidDetails: true,
        },
        accessToken
      );

      clearErrors("dvaCardNumber");
    } catch (err) {
      console.log("[error]: >>", err);

      setError("dvaCardNumber", {
        type: "manual",
        message: "Error while validating your DVA card",
      });
    } finally {
      toggleValidating(false);
    }
  };

  const debounceValidateMedicareCardAsync = useCallback(
    debounce(validateMedicareCardByTacklit, DEFAULT_AUTOSAVE_DEBOUNCED_MS),
    [accessToken]
  );

  const getDVACardTypeHint = (): ReactElement | undefined => {
    return match(dvaCardType)
      .with(DVACardType.WHITE, () => getHintFromStoryBlok(blok.dvaCardHints))
      .with(DVACardType.GOLD, () =>
        getHintFromStoryBlok(blok.secondDvaCardHints)
      )
      .otherwise(() => undefined);
  };

  const getStreetAddressHint = (): HintStoryblok[] | undefined => {
    return match(dvaCardType)
      .with(DVACardType.WHITE, () => blok.homeAddressHints)
      .with(DVACardType.GOLD, () => blok.secondAddressHints)
      .otherwise(() => undefined);
  };

  const onSubmit: SubmitHandler<BookDVAFormData> = async (formData) => {
    if (!userMedicareInformation || !accessToken) return;

    toggleValidating(true);

    const whiteDVACardParam = isChooseWhiteDVACard
      ? {
          number: toNumber(medicareNumber),
          expiryDate: toExpiryDate(expiryDate),
          irn: toNumber(irnNumber),
        }
      : undefined;

    const requestParam: UpdateMedicareParams = {
      firstName: userMedicareInformation.firstName,
      lastName: userMedicareInformation.lastName,
      dateOfBirth: userMedicareInformation.dateOfBirth,
      dva: dvaCardNumber,
      dvaCardDetails: {
        type: dvaCardType ?? "",
      },
      shouldRejectInvalidDetails: true,

      ...whiteDVACardParam,
    };

    try {
      await validateMedicareAsync(requestParam, accessToken);

      customToast.success("Validate your DVA card successfully");

      await Promise.all([
        TacklitService.putContactAddressAsync(formData, accessToken),
      ]);

      const isUploaded = await handleUploadNoReferralAsync(
        accessToken,
        isExistingClient
      );

      if (!isUploaded) return;

      redirectTo(Routes.BOOKING_CONFIRM);
    } catch (err) {
      console.log("[error]: >>", err);

      const errorResponse = (err as AxiosError<any>).response?.data;
      const errorMessage =
        errorResponse?.errors?.dva ||
        errorResponse?.message ||
        "Error while validating your DVA card";

      setError("dvaCardNumber", { type: "manual", message: errorMessage });
    } finally {
      toggleValidating(false);
    }
  };

  if (!userMedicareInformation) return;

  return (
    <div
      className="flex flex-col w-full gap-12 px-0 py-10 md:flex-row lg:px-20"
      {...storyblokEditable(blok)}
    >
      <FormProvider {...formContext}>
        <form
          className="flex flex-[2] gap-y-5 flex-col order-2 md:order-1"
          onSubmit={handleSubmit(onSubmit)}
        >
          <StoryblokComponent blok={getFormHeadingBlok(blok.title)} />

          <BookingInformation />

          <div className="flex flex-col flex-1 gap-y-1">
            <Controller
              name="dvaCardNumber"
              control={control}
              render={({ field }) => (
                <TextInput
                  {...field}
                  title="DVA Card number"
                  onChangeValue={field.onChange}
                />
              )}
            />

            {isValidating ? (
              <InputValidationSpinner />
            ) : (
              <TextError fieldError={errors.dvaCardNumber} />
            )}
          </div>

          <div className="flex flex-col p-5 gap-y-3 bg-neutral-100 rounded-xl">
            <div className="flex flex-row items-center justify-between">
              <span>Gold or White Card?</span>
              {getDVACardTypeHint()}
            </div>

            <Controller
              name="dvaCardType"
              control={control}
              render={({ field }) => (
                <div className="flex flex-row gap-x-5">
                  <RadioInput
                    {...field}
                    label="Gold"
                    value={DVACardType.GOLD}
                    checked={isChooseGoldDVACard}
                  />

                  <RadioInput
                    {...field}
                    label="White Card (approved condition)"
                    value={DVACardType.WHITE}
                    checked={isChooseWhiteDVACard}
                  />
                </div>
              )}
            />
          </div>

          <div className="flex flex-col p-5 bg-neutral-100 rounded-xl gap-y-3">
            {isChooseWhiteDVACard && <DvaWhiteCardMedicareFields blok={blok} />}

            {/* ADDRESS */}
            <AddressFields<BookDVAFormData>
              streetAddressHints={getStreetAddressHint()}
              control={control}
              errors={errors}
            />
          </div>

          <div className="flex items-center justify-start mt-2">
            <button
              type="submit"
              disabled={isDisabledForm}
              className={getDefaultButtonStyles(isValidating)}
            >
              Next
            </button>
            {isValidating && <SpinnerLoading className="w-12 h-12 ml-3" />}
          </div>
        </form>
      </FormProvider>

      {/* ACTIONS BLOK */}
      <div className="flex flex-col flex-1 gap-y-3 order-1 h-fit md:order-2">
        {blok.actions?.map((actionBlok) => (
          <StoryblokComponent key={actionBlok._uid} blok={actionBlok} />
        ))}

        <div className="flex bg-light-grey p-7 min-w-[280px] w-full rounded-xl">
          {/* COUNTDOWN CLOCK */}
          <CountDownClock />
        </div>
      </div>
    </div>
  );
};
