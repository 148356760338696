/* eslint-disable react-hooks/exhaustive-deps */
import * as Yup from "yup";

import {
  ControllerRenderProps,
  FieldValues,
  useFormContext,
} from "react-hook-form";
import {
  DEFAULT_INPUT_DEBOUNCED_MS,
  EMAIL_ADDRESS_EXISTED_MESSAGE,
} from "core/booking.constant";
import { useCallback, useState } from "react";

import { EMAIL_REGEX } from "core/regex.constant";
import { InputValidationSpinner } from "components/forms/shared/InputValidationSpinner";
import { TextError } from "components/forms/TextError";
import { TextInput } from "components/forms/TextInput";
import { debounce } from "lodash";
import { useAsyncFn } from "react-use";
import { useFormContextLoadingState } from "hooks/useFormContextLoadingState";
import { useLoginNavigate } from "hooks/useLoginNavigate";
import { InputSuccessInfo } from "components/forms/shared/InputSuccessInfo";
import { cn } from "utils";
import { validateEmailByTacklitAsync } from 'services/signup.service';

type Props<T extends FieldValues> = {
  field: ControllerRenderProps<T>;

  label?: string;
  skipAsyncValidation?: boolean;
};

export const EmailValidatorInput = <T extends FieldValues>({
  label = "Email address",

  field,
  skipAsyncValidation = false,
  ...props
}: Props<T>) => {
  const { loginRedirectSOH } = useLoginNavigate();

  const {
    formState: { errors, isDirty },
    setError,
  } = useFormContext();

  const [isEmailInUsed, toggleEmailInUsed] = useState<boolean>();

  const [validationState, validateEmailHookAsync] = useAsyncFn(
    async (email: string) => {
      if (skipAsyncValidation) return false;
      const isUsed = await validateEmailByTacklitAsync(email);

      return isUsed;
    },
    [setError, skipAsyncValidation]
  );

  useFormContextLoadingState({
    name: field.name,
    loading: validationState.loading,
  });

  const handleInput = async (e: React.ChangeEvent<HTMLInputElement>) => {
    field.onChange(e);

    const rawEmail = e.target.value;

    const isValid = Yup.string()
      .required()
      .trim()
      .email()
      .matches(EMAIL_REGEX)
      .isValidSync(rawEmail);

    if (isValid) {
      const isUsed = await validateEmailHookAsync(rawEmail);

      if (isUsed) {
        setError(field.name, {
          type: "manual",
          message: EMAIL_ADDRESS_EXISTED_MESSAGE,
        });
      }

      toggleEmailInUsed(isUsed);
    }
  };

  const renderFeedback = useCallback(() => {
    if (validationState.loading) {
      return <InputValidationSpinner />;
    }

    if (errors?.email && isDirty) {
      return <TextError fieldError={errors.email} />;
    }

    if (isEmailInUsed === true) {
      return (
        <div className="text-alerts-error text-xs">
          Email address is already in use. Please use a different email or{" "}
          <span
            className="underline underline-offset-2 cursor-pointer"
            onClick={loginRedirectSOH}
          >
            login
          </span>{" "}
          to your account
        </div>
      );
    }
  }, [errors.email, isEmailInUsed, validationState.loading]);

  const shouldShowSuccess = isEmailInUsed === false && !errors?.email;

  return (
    <>
      <span className="relative">
        <TextInput
          name={field.name}
          title={label}
          onChangeValue={debounce(handleInput, DEFAULT_INPUT_DEBOUNCED_MS)}
          className={cn(shouldShowSuccess && "pr-10")}
          {...props}
        />

        {shouldShowSuccess && (
          <span className="absolute top-[50%] mt-[2px] right-[12px]">
            <InputSuccessInfo />
          </span>
        )}
      </span>

      {renderFeedback()}
    </>
  );
};
