import {
  BadgeStoryblok,
  HeadingStoryblok,
  PsychologistDetailHelpWithStoryblok,
} from "@/types/component-types-sb";
import { Props } from "@/types/core";
import { StoryblokComponent, storyblokEditable } from "@storyblok/react";
import { defaultTo } from "lodash";

import { PsychologistResponse } from "@/services/psychologist.service";
import { useBreakpoint } from "hooks/useBreakpoint";
import { specializationsSingleValue } from "utils";
import { Separator } from "./Separator";

type CustomProps = Props<PsychologistDetailHelpWithStoryblok> & {
  psychologist: PsychologistResponse;
};

export const PsychologistDetailHelpWith = ({
  blok,
  psychologist,
}: CustomProps) => {
  const breakpoint = useBreakpoint();

  const { helmControl } = psychologist || {};

  const headingBlok = {
    content: blok.title,
    level: "h3",
    fontWeight: 400,
    align: "left",
    component: "heading",
  } as HeadingStoryblok;

  return (
    <div
      {...storyblokEditable(blok)}
      className="flex flex-col items-start justify-center gap-5 w-full"
    >
      <StoryblokComponent blok={headingBlok} />

      <div className="flex flex-wrap items-center justify-start gap-3">
        {helmControl?.secondarySpecialisations?.map(
          (secondarySpecialisation, index) => {
            const badge = {
              name: defaultTo(
                specializationsSingleValue[secondarySpecialisation],
                "Unknown"
              ),
              type: "plain",
              size: "md",
              isUpperCase: false,
              component: "badge",
            } as BadgeStoryblok;

            return <StoryblokComponent key={index} blok={badge} />;
          }
        )}
      </div>

      {breakpoint.isMobile && <Separator />}
    </div>
  );
};
