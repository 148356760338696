import { SpinnerLoading } from "assets/icons/SpinnerLoading";
import { SelectionDropdown } from "components/selection-dropdown/SelectionDropdown";
import { PreferredContactMethodType, UrlReturn } from "enums";
import { ContactUsFormData, SelectionOption } from "models";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { useToggle } from "react-use";
import { Routes } from "routes/main.routes";
import { submitContactUsToHubspot } from "services/hubspot.service";
import {
  getDefaultButtonStyles,
  isPsyUnavailablePage,
  redirectTo,
} from "utils";
import { renderBackToPsychologistsButton } from "utils/warning.util";

import { yupResolver } from "@hookform/resolvers/yup";

import { PhoneValidatorInput } from "components/forms/shared/PhoneValidatorInput";
import { CheckboxInput } from "../CheckboxInput";
import { RadioInput } from "../RadioInput";
import { TextAreaInput } from "../TextAreaInput";
import { TextError } from "../TextError";
import { TextInput } from "../TextInput";
import { getContactUsFormResolver } from "./contact-us.resolver";

export const ContactUsForm = () => {
  const [isLoading, toggleLoading] = useToggle(false);

  const subjectOptions: SelectionOption[] = [
    { label: "Another question", value: "Another question" },
    { label: "Help with an appointment", value: "Help with an appointment" },
    { label: "Help with signing up", value: "Help with signing up" },
    { label: "I have feedback", value: "I have feedback" },
  ];

  const formContext = useForm<ContactUsFormData>({
    mode: "onChange",
    defaultValues: {
      isReceiveUpdates: false,
    },
    resolver: yupResolver(getContactUsFormResolver()),
  });

  const {
    control,
    handleSubmit,
    formState: { errors, isValid, isValidating },
  } = formContext;

  const submitToHubspot = async (submissionData: ContactUsFormData) => {
    try {
      toggleLoading(true);
      const { data } = await submitContactUsToHubspot(submissionData);

      if (data) {
        toggleLoading(false);

        let thankyouUrl: string = Routes.CONTACT_US_THANK_YOU;

        if (isPsyUnavailablePage()) {
          const queryParams = new URLSearchParams({
            return: UrlReturn.OUR_PSYCHOLOGISTS,
          }).toString();

          thankyouUrl = `${thankyouUrl}?${queryParams}`;
        }

        redirectTo(thankyouUrl);
      }
    } catch (err) {
      console.log("err :>> ", err);
    }
  };

  const handleFormSubmit = (submissionData: ContactUsFormData) => {
    submitToHubspot(submissionData);
  };

  return (
    <div className="max-w-[750px] md:max-w-none">
      <FormProvider {...formContext}>
        <form
          onSubmit={handleSubmit(handleFormSubmit)}
          className="flex flex-col gap-4 lg:gap-5 xl:gap-7 xl:grid xl:grid-cols-2"
        >
          <div className="xl:col-start-1">
            {/* Subject */}
            <Controller
              name="subject"
              control={control}
              render={({ field }) => (
                <div className="flex flex-col gap-1.5">
                  <SelectionDropdown
                    title="Subject"
                    placeHolder="Please select"
                    options={subjectOptions}
                    onChangeSingleSelection={(
                      selectedOption: SelectionOption
                    ) => {
                      field.onChange(selectedOption.value);
                    }}
                    isShowTitle
                    isShowHint={false}
                  />
                </div>
              )}
            />
          </div>

          <div className="flex flex-row w-full gap-4">
            {/* First name */}
            <Controller
              name="firstName"
              control={control}
              render={({ field }) => (
                <div className="flex flex-col w-full gap-1.5">
                  <TextInput
                    name={field.name}
                    title={"First name"}
                    onChangeValue={field.onChange}
                  />

                  <TextError fieldError={errors.firstName} />
                </div>
              )}
            />

            {/* Last name */}
            <Controller
              name="lastName"
              control={control}
              render={({ field }) => (
                <div className="flex flex-col w-full gap-1.5">
                  <TextInput
                    name={field.name}
                    title={"Last name"}
                    onChangeValue={field.onChange}
                  />

                  <TextError fieldError={errors.lastName} />
                </div>
              )}
            />
          </div>

          <div className="flex flex-row gap-4 xl:col-start-2 xl:row-start-1">
            {/* Email */}
            <Controller
              name="email"
              control={control}
              render={({ field }) => (
                <div className="flex flex-col w-full gap-1.5">
                  <TextInput
                    name={field.name}
                    title={"Email"}
                    onChangeValue={field.onChange}
                  />

                  <TextError fieldError={errors.email} />
                </div>
              )}
            />

            {/* Phone number */}
            <Controller
              name="phoneNumber"
              control={control}
              render={({ field }) => (
                <div className="flex flex-col w-full gap-y-1">
                  <PhoneValidatorInput field={field} />
                </div>
              )}
            />
          </div>

          <div className="xl:row-span-2">
            <Controller
              name="comments"
              control={control}
              render={({ field }) => (
                <div className="flex flex-col w-full gap-1.5 xl:h-full">
                  <TextAreaInput
                    name={field.name}
                    title={"Comments"}
                    numberRows={3}
                    onChangeValue={field.onChange}
                  />

                  <TextError fieldError={errors.comments} />
                </div>
              )}
            />
          </div>

          <div className="flex flex-col gap-1.5 p-8 bg-light-grey rounded-2xl">
            <div>Preferred contact method?</div>
            <Controller
              name="preferredContactMethod"
              control={control}
              render={({ field }) => (
                <div className="flex flex-row gap-x-2">
                  <RadioInput
                    {...field}
                    label="By phone"
                    value={PreferredContactMethodType.BY_PHONE}
                    checked={
                      field.value === PreferredContactMethodType.BY_PHONE
                    }
                  />

                  <RadioInput
                    {...field}
                    label="By email"
                    value={PreferredContactMethodType.BY_EMAIL}
                    checked={
                      field.value === PreferredContactMethodType.BY_EMAIL
                    }
                  />
                </div>
              )}
            />
          </div>

          <div className="xl:col-span-2">
            <Controller
              name="isReceiveUpdates"
              control={control}
              render={({ field }) => (
                <CheckboxInput
                  isChecked={field.value}
                  title="I would like to receive occasional updates from Someone.health, providing mental health tips and information."
                  onCheck={field.onChange}
                />
              )}
            />
          </div>

          <div className="flex flex-col md:items-center gap-5 md:flex-row xl:col-span-2">
            <div className="flex items-center gap-x-2">
              {/* Submit button */}
              <button
                type="submit"
                disabled={!isValid || isValidating || isLoading}
                className={getDefaultButtonStyles()}
              >
                Submit
              </button>

              {isLoading && <SpinnerLoading className="w-12 h-12" />}
            </div>

            <div>
              {/* Back to psychologists button */}
              {renderBackToPsychologistsButton({
                visible: isPsyUnavailablePage(),
              })}
            </div>
          </div>
        </form>
      </FormProvider>
    </div>
  );
};
