import { useAuth0 } from "@auth0/auth0-react";
import { forwardRef, ReactElement, useImperativeHandle } from "react";
import { useToggle } from "react-use";

import { WarningSvg } from "assets/icons/WarningSvg";

import { Routes } from "routes/main.routes";
import { AlertModal } from "../shared/AlertModal";

type Props = {};

export type LoggedInWarningModalRef = {
  show: () => void;
};

export const LoggedInWarningModal = forwardRef(
  ({ ...props }: Props, ref: React.Ref<unknown>): ReactElement => {
    useImperativeHandle(ref, () => ({ show }));

    const { isAuthenticated, user, logout } = useAuth0();

    const [isShowModal, toggleIsShowModal] = useToggle(false);

    const show = () => {
      toggleIsShowModal(true);
    };

    const handleClickLogOut = () => {
      if (!isAuthenticated) return;

      logout({
        logoutParams: {
          returnTo: `${window.location.origin}?redirectTo=${Routes.SIGNUP_INVITE}`,
        },
      });
    };

    return (
      <AlertModal isShow={isShowModal} preventEsc className="max-w-2xl">
        <div className="flex flex-row gap-x-5 py-5">
          <div className="flex mt-1">
            <WarningSvg />
          </div>

          <div className="flex flex-1 text-sm lg:text-base flex-col gap-y-3 overflow-hidden">
            <div className="break-words">
              You’re already signed in as{" "}
              <strong className="font-semibold">{user?.email}</strong>
            </div>

            <div className="break-words">
              Please{" "}
              <span
                className="underline text-primary cursor-pointer"
                onClick={handleClickLogOut}
              >
                sign out
              </span>{" "}
              to continue with this process.
            </div>
          </div>
        </div>
      </AlertModal>
    );
  }
);
