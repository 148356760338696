import {
  CheckpointStoryblok,
  HeadingStoryblok,
  PsychologistDetailServicesStoryblok,
} from "@/types/component-types-sb";
import { Props } from "@/types/core";
import { StoryblokComponent, storyblokEditable } from "@storyblok/react";

import { PsychologistResponse } from "@/services/psychologist.service";
import { useBreakpoint } from "hooks/useBreakpoint";
import { CheckpointCarousel } from "./CheckpointCarousel";

type CustomProps = Props<PsychologistDetailServicesStoryblok> & {
  psychologist: PsychologistResponse;
};

export const PsychologistDetailServices = ({
  blok,
  psychologist,
}: CustomProps) => {
  const breakpoint = useBreakpoint();

  const { helmControl } = psychologist || {};

  const headingBlok = {
    content: blok.title,
    level: "h3",
    fontWeight: 400,
    align: "left",
    component: "heading",
  } as HeadingStoryblok;

  return (
    <div
      {...storyblokEditable(blok)}
      className="flex flex-col items-start justify-center md:gap-5 w-full"
    >
      <StoryblokComponent blok={headingBlok} />

      {breakpoint.isMobile ? (
        <CheckpointCarousel
          checkpointItems={helmControl?.servicesAvailable}
          iconBlok={blok.icon}
          iconAlt={blok.icon_alt}
        />
      ) : (
        <div className="flex flex-col gap-5">
          {helmControl?.servicesAvailable?.map((service, index) => {
            const checkpointBlok = {
              icon: blok.icon,
              text: service,
              icon_alt: blok.icon_alt,
              component: "checkpoint",
            } as CheckpointStoryblok;

            return <StoryblokComponent key={index} blok={checkpointBlok} />;
          })}
        </div>
      )}
    </div>
  );
};
