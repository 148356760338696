import * as Yup from "yup";

import { EMAIL_REGEX } from "core/regex.constant";
import { SignUpFormType } from "enums";
import { isValidPhoneNumber } from "libphonenumber-js";
import { DEFAULT_PHONE_COUNTRY_CODE } from "core/booking.constant";

export const GetSignupFormResolver = (formType: SignUpFormType) =>
  Yup.object().shape({
    firstName: Yup.string().trim().required("First name is required"),
    lastName: Yup.string().trim().required("Last name is required"),
    email: Yup.string()
      .trim()
      .required("Email is required")
      .email("Email is not valid")
      .matches(EMAIL_REGEX, {
        message: "Invalid email format",
        name: "email",
        excludeEmptyString: true,
      }),
    phone: Yup.string()
      .trim()
      .required("Phone number is required")
      .test((phoneNumber = "", context) => {
        if (!isValidPhoneNumber(phoneNumber, DEFAULT_PHONE_COUNTRY_CODE)) {
          return context.createError({
            path: "phoneNumber",
            message: "Phone number is not valid",
          });
        }

        return true;
      }),
    dob: Yup.date().required("Date of birth is required"),
    password: Yup.string().trim().required("A secure password is required"),
    isPoliciesConfirmed: Yup.boolean()
      .default(false)
      .oneOf(
        [true],
        "Please accept the Privacy policy and Terms and conditions in order to continue"
      ),
    isAtsi: Yup.boolean().default(false),
    isRegistered: Yup.boolean().default(false),
  });
