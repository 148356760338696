import { HowItWorksStoryblok } from "@/types/component-types-sb";
import { Props } from "@/types/core";
import { StoryblokComponent, storyblokEditable } from "@storyblok/react";
import { ScreenBreakpoints } from "enums";
import { useWindowSize } from "react-use";
import { cn } from "utils";

export const HowItWorks = ({ blok }: Props<HowItWorksStoryblok>) => {
  const { width } = useWindowSize();

  const isScreenLGorSmaller = width <= ScreenBreakpoints.LG;

  return (
    <div {...storyblokEditable(blok)} className="my-20">
      <div className="flex justify-center my-5 text-2xl md:text-3xl lg:text-[40px] text-primary">
        {blok.title}
      </div>

      <div className="grid grid-cols-1 mx-auto md:grid-cols-5 overflow-hidden">
        <div
          className="hidden md:flex justify-center md:col-span-2"
          data-aos="fade-right"
        >
          <img
            src={blok.background}
            alt={blok.background_alt}
            className="object-contain"
          />
        </div>

        <div className="md:col-span-3" data-aos="fade-left">
          <div className="flex-col items-center justify-center md:mt-5 md:mr-16 xl:mr-40">
            {blok.works?.map((work, index) => (
              <StoryblokComponent key={work._uid} blok={work} index={index} />
            ))}
          </div>

          <div
            className={cn(
              "lg:mt-7 lg:flex gap-x-2 mt-5 md:mr-16 xl:mr-40",
              blok.buttonsAlignment
            )}
          >
            {blok.buttons?.map((button) => {
              button.isFullWidth = isScreenLGorSmaller;

              return (
                <div key={button._uid} className="mb-3 lg:mr-3 max-md:px-0.5">
                  <StoryblokComponent blok={button} />
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};
