/* eslint-disable react-hooks/exhaustive-deps */
import { yupResolver } from "@hookform/resolvers/yup";
import { StoryblokComponent } from "@storyblok/react";
import { isAxiosError } from "axios";
import { defaultTo, first, toNumber } from "lodash";
import {
  FormProvider,
  SubmitHandler,
  useForm,
  useWatch,
} from "react-hook-form";
import { useToggle } from "react-use";
import { BookMedicareFormStoryblok } from "types/component-types-sb";

import { useAuth0 } from "@auth0/auth0-react";
import { SpinnerLoading } from "assets/icons/SpinnerLoading";
import { BookBehalfOfType, ConfirmRadioType } from "enums";
import { useClientType } from "hooks/useClientType";
import { useLoginNavigate } from "hooks/useLoginNavigate";
import { ExternalRoutes, Routes } from "routes/main.routes";
import {
  postEmergencyContactAsync,
  processReserveWithPaymentAsync,
} from "services/booking.service";
import { validateMedicareAsync } from "services/psychologist.service";
import { TacklitService } from "services/tacklit.service";
import { useProfileStore } from "stores/profile.store";
import {
  getDefaultButtonStyles,
  getDefaultOutlinedButtonStyles,
  redirectTo,
  toDateOfBirth,
  toExpiryDate,
} from "utils";
import {
  getBehalfOfMySelfFormDefaultData,
  getUserMedicareBasicInformation,
  handleMedicareUpdateReferralAsync,
  isEmergencyContactExisted,
  reclaimReserveAppointment,
} from "utils/booking.util";
import {
  getStoredReserveData,
  setStoredAppointmentHasGpReferral,
} from "utils/storage.util";
import { customToast } from "utils/toast.util";
import {
  EmergencyContactField,
  EmergencyContactFieldType,
} from "../shared/EmergencyContactField";
import {
  MedicareMySelfFields,
  MedicareMySelfFieldsType,
} from "../shared/MedicareMySelfFields";
import { UploadFields, UploadFieldsType } from "../shared/UploadFields";
import { getBookMedicareMySelfFormResolver } from "./book-medicare.resolver";
import { renderMedicareNotice } from "./booking.medicare.util";

export type BookMedicareMySelfFormData = MedicareMySelfFieldsType &
  EmergencyContactFieldType &
  UploadFieldsType;

type Props = {
  bookMedicareFormBlok: BookMedicareFormStoryblok;
  accessToken: string;
};

export const BehalfOfMySelfForm = ({
  bookMedicareFormBlok,
  accessToken,
}: Props) => {
  const profile = useProfileStore((state) => state.profile);

  const { isAuthenticated } = useAuth0();
  const { loginRedirectTacklit } = useLoginNavigate();

  const { isExistingClient } = useClientType();

  const [isValidating, toggleValidating] = useToggle(false);

  const formContext = useForm<BookMedicareMySelfFormData>({
    mode: "onChange",
    criteriaMode: "all",
    defaultValues: getBehalfOfMySelfFormDefaultData(profile),
    resolver: yupResolver(getBookMedicareMySelfFormResolver()),
  });

  const {
    control,
    formState: { errors, isValid: isFormValid },
    handleSubmit,
  } = formContext;

  const {
    isHaveGPReferralAndTreatmentPlan,
    isRegisteredWithMedicareName,
    videoAppointmentWithBulkBillGP,
  } = useWatch({ control });

  const userMedicareInformation = getUserMedicareBasicInformation(profile);

  if (!userMedicareInformation) return;

  const isNoReferral = isHaveGPReferralAndTreatmentPlan === ConfirmRadioType.NO;
  const isWantBookGP = videoAppointmentWithBulkBillGP === ConfirmRadioType.YES;

  const isDisabledForm = isValidating || !isFormValid;
  const showSpinner = isValidating;

  const isValidMedicareCardAsync = async (
    formData: BookMedicareMySelfFormData,
    accessToken?: string
  ): Promise<boolean> => {
    const medicareCardInfoParams = isRegisteredWithMedicareName
      ? {
          firstName: defaultTo(userMedicareInformation.firstName, ""),
          lastName: defaultTo(userMedicareInformation.lastName, ""),
          dateOfBirth: toDateOfBirth(userMedicareInformation.dateOfBirth) ?? "",
        }
      : {
          firstName: formData.firstName ?? "",
          lastName: formData.lastName ?? "",
          dateOfBirth: toDateOfBirth(formData.dateOfBirth) ?? "",
        };

    try {
      await validateMedicareAsync(
        {
          ...medicareCardInfoParams,
          number: toNumber(formData.medicareNumber),
          expiryDate: toExpiryDate(formData.expiryDate),
          irn: toNumber(formData.irnNumber),
          shouldRejectInvalidDetails: true,
        },
        accessToken
      );

      customToast.success("Valid Medicare card");

      return true;
    } catch (err) {
      if (isAxiosError(err)) {
        customToast.error("Invalid Medicare card. Please check again.");
      }

      return false;
    }
  };

  const onSubmit: SubmitHandler<BookMedicareMySelfFormData> = async (data) => {
    toggleValidating(true);

    try {
      const reserveAppointment = getStoredReserveData();
      const reserveId = first(
        reserveAppointment?.reserveAppointment.appointments
      )?.reserveId;

      if (!reserveId) {
        customToast.error(
          "Unable to get reserve appointment data. Please try again"
        );

        return;
      }

      // Validate Medicare card
      const isValidMedicareCheck = await isValidMedicareCardAsync(
        data,
        accessToken
      );

      if (!isValidMedicareCheck) return;

      // Put emergency contact
      const isDuplicatedEmergencyContact = isEmergencyContactExisted({
        profile: profile,
        firstName: data.emergencyContactName,
        mobileNumber: data.emergencyContactPhoneNumber,
      });

      if (!isDuplicatedEmergencyContact) {
        await postEmergencyContactAsync(
          {
            firstName: data.emergencyContactName,
            mobileNumber: data.emergencyContactPhoneNumber,
          },
          accessToken
        );
      }

      // Update referral
      const isUpdateReferralSuccess = await handleMedicareUpdateReferralAsync({
        formData: data,
        isExistingClient: isExistingClient,
        accessToken: accessToken,
      });

      if (!isUpdateReferralSuccess) return;

      // Put contact address
      await Promise.all([
        TacklitService.putContactAddressAsync(data, accessToken),
      ]);

      setStoredAppointmentHasGpReferral(!isNoReferral);

      // Reclaim reserve appointment
      await reclaimReserveAppointment(reserveId, accessToken);

      // Process reserve
      if (isAuthenticated && Boolean(profile.hasSavedCard)) {
        await processReserveWithPaymentAsync(reserveId, accessToken);

        redirectTo(Routes.BOOKING_THANK_YOU);
      } else {
        const { data: processResponse } = await processReserveWithPaymentAsync(
          reserveId,
          accessToken
        );

        if (processResponse?.checkoutUrl) {
          redirectTo(processResponse.checkoutUrl);
        }
      }

      customToast.success("Appointment claimed successfully");
    } catch (err) {
      console.log("[error]: >>", err);

      customToast.error(
        "Error while processing your appointment. Please try again"
      );
    } finally {
      toggleValidating(false);
    }
  };

  const handleClickBookGP = async () => {
    loginRedirectTacklit(ExternalRoutes.GP_BOOKING);
  };

  return (
    <FormProvider {...formContext}>
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="flex flex-col flex-1 gap-y-5"
      >
        <div className="flex flex-col w-full gap-y-4">
          {/* MEDICARE MYSELF */}
          <MedicareMySelfFields<BookMedicareMySelfFormData>
            control={control}
            errors={errors}
            userInformation={userMedicareInformation}
            medicareNumberHints={bookMedicareFormBlok.medicareNumberHints}
            irnHints={bookMedicareFormBlok.irnHints}
            expiryDateHints={bookMedicareFormBlok.expiryDateHints}
            streetAddressHints={bookMedicareFormBlok.streetAddressHints}
            medicareImageAsset={bookMedicareFormBlok.medicareImage}
            medicareImageAlt={bookMedicareFormBlok.medicareImageAlt}
          />

          {/* EMERGENCY CONTACT */}
          <EmergencyContactField<BookMedicareMySelfFormData>
            control={control}
            errors={errors}
            hints={bookMedicareFormBlok.emergencyContactHints}
          />

          {/* UPLOAD */}
          <UploadFields<BookMedicareMySelfFormData>
            bookMedicareFormBlok={bookMedicareFormBlok}
            control={control}
            errors={errors}
            behalfOfType={BookBehalfOfType.MY_SELF}
          />
        </div>

        {isNoReferral && (
          <div className="w-full">
            {bookMedicareFormBlok.notices?.map((noticeBlok) => (
              <StoryblokComponent key={noticeBlok._uid} blok={noticeBlok} />
            ))}
          </div>
        )}

        <div>{renderMedicareNotice(bookMedicareFormBlok)}</div>

        {isNoReferral ? (
          <div className="flex flex-wrap items-center justify-between gap-y-3">
            <div className="flex items-center justify-start">
              <button
                type="submit"
                disabled={isDisabledForm}
                className={getDefaultButtonStyles(showSpinner)}
              >
                Confirm psychology session
              </button>

              {showSpinner && <SpinnerLoading className="w-12 h-12 ml-3" />}
            </div>

            {isWantBookGP && (
              <button
                type="button"
                onClick={handleClickBookGP}
                className={getDefaultOutlinedButtonStyles()}
              >
                Login to book one of our GPs
              </button>
            )}
          </div>
        ) : (
          <div className="flex items-center justify-start">
            <button
              type="submit"
              disabled={isDisabledForm}
              className={getDefaultButtonStyles(showSpinner)}
            >
              Proceed to payment details to finalise your booking
            </button>

            {showSpinner && <SpinnerLoading className="w-12 h-12 ml-3" />}
          </div>
        )}
      </form>
    </FormProvider>
  );
};
