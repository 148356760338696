import { ScrollAnchorIds } from "enums";
import { cn, getDefaultButtonStyles, scrollSmoothById } from "utils";

type Props = {
  label: string;
  targetId: ScrollAnchorIds;
  customClasses?: string;
};

export const SmoothScrollButton = ({
  label,
  targetId,
  customClasses,
  ...props
}: Props) => {
  const handleClickButton = () => {
    scrollSmoothById(targetId);
  };

  return (
    <button
      onClick={handleClickButton}
      className={cn(getDefaultButtonStyles(), customClasses)}
    >
      {label}
    </button>
  );
};
