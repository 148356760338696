import React, { Suspense } from "react";
import { cn } from "utils";

import { Spinner } from "flowbite-react/components/Spinner";

type Props = {
  title?: string;
  className?: React.HTMLAttributes<HTMLDivElement>["className"];
};

export const InputValidationSpinner = ({
  className,
  title = "Validating",
}: Props) => {
  return (
    <span className={cn("flex gap-2 items-center text-xs", className)}>
      <Suspense>
        <Spinner size="xs" />
      </Suspense>
      <span>{title}</span>
    </span>
  );
};
