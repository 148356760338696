import * as Yup from "yup";

import {
  getAddressFormValidation,
  getMedicareChildFieldsValidation,
  getMedicareMySelfFieldsValidation,
  getMedicareSomeOneFieldsValidation,
} from "../shared/shared.resolver";

export const getBookGPMySelfFormResolver = () =>
  Yup.object()
    .concat(getAddressFormValidation())
    .concat(getMedicareMySelfFieldsValidation());

export const getBookGPSomeOneFormResolver = () =>
  Yup.object()
    .concat(getAddressFormValidation())
    .concat(getMedicareSomeOneFieldsValidation());

export const getBookGPChildFormResolver = () =>
  Yup.object({
    isConsentForParent: Yup.boolean().required(),
  })
    .concat(getAddressFormValidation())
    .concat(getMedicareChildFieldsValidation());
