/* eslint-disable react-hooks/exhaustive-deps */
import { useAuth0 } from "@auth0/auth0-react";
import { useEffect, useMemo, useState } from "react";
import { useToggle } from "react-use";

import { getStoredSignupSessionUser } from "utils/storage.util";

export const useAccessToken = () => {
  const { isAuthenticated, getAccessTokenSilently, isLoading } = useAuth0();

  const [loginAccessToken, setLoginAccessToken] = useState<
    string | undefined
  >();

  const [isFetching, toggleIsFetching] = useToggle(false);

  const accessToken = useMemo(() => {
    return isAuthenticated
      ? loginAccessToken
      : getStoredSignupSessionUser()?.authToken;
  }, [
    loginAccessToken,
    isAuthenticated,
    getStoredSignupSessionUser()?.authToken,
  ]);

  useEffect(() => {
    if (!isAuthenticated) return;

    const fetchAccessToken = async () => {
      try {
        toggleIsFetching(true);

        const token = await getAccessTokenSilently();

        setLoginAccessToken(token);
      } catch (err) {
        console.error("[LOG] err: >>>", err);
      } finally {
        toggleIsFetching(false);
      }
    };

    fetchAccessToken();
  }, [getAccessTokenSilently, isAuthenticated]);

  return {
    accessToken: accessToken,
    isLoadingToken: isLoading || isFetching,
  };
};
