import { ReactElement } from "react";

type Props = {
  title: string;
  contentElement: ReactElement;
};

export const BrowsePsychologistMessage = ({ title, ...props }: Props) => {
  return (
    <div className="w-full col-span-1 py-8 text-center sm:col-span-2 lg:col-span-3 xl:col-span-4 2xl:col-span-5 text-primary">
      <h3 className="mb-5 text-xl font-medium">{title}</h3>
      <div className="text-15">{props.contentElement}</div>
    </div>
  );
};
