import {
  HeadingStoryblok,
  PsychologistDetailIntroduceStoryblok,
  TextStoryblok,
} from "@/types/component-types-sb";
import { Props } from "@/types/core";
import { StoryblokComponent, storyblokEditable } from "@storyblok/react";
import { first } from "lodash";
import Plyr, { PlyrSource } from "plyr-react";

import { PsychologistResponse } from "@/services/psychologist.service";
import { ExpandableText } from "components/shared/ExpandableText";
import { VIDEO_INTRO_FALLBACK } from "core/booking.constant";
import { useBreakpoint } from "hooks/useBreakpoint";
import { Separator } from "./Separator";

type CustomProps = Props<PsychologistDetailIntroduceStoryblok> & {
  psychologist: PsychologistResponse;
};

export const PsychologistDetailIntroduce = ({
  blok,
  psychologist,
}: CustomProps) => {
  const breakpoint = useBreakpoint();

  const { helmControl } = psychologist || {};

  const psychologistName = first(psychologist?.name?.split(" "));

  const videoSrc: PlyrSource | null = {
    type: "video",
    sources: [
      {
        // src: "https://www.youtube.com/watch?v=UvWvhFvLq9k",
        src: blok?.introductionVideo?.url ?? VIDEO_INTRO_FALLBACK, // Vertical
        provider: "youtube",
        type: "video/mp4",
        size: 720,
      },
    ],
  };

  const headingBlok = {
    content: `${blok.title} ${psychologistName}`,
    level: "h3",
    fontWeight: 400,
    align: "left",
    component: "heading",
  } as HeadingStoryblok;

  const textBlok = {
    text: helmControl?.shortDescription,
    fontSize: "20px",
    fontWeight: 400,
    component: "text",
  } as TextStoryblok;

  return (
    <div
      {...storyblokEditable(blok)}
      className="w-full flex flex-col items-start justify-center gap-5"
    >
      <StoryblokComponent blok={headingBlok} />

      <div className="leading-7 w-full" style={{ wordBreak: "break-word" }}>
        {breakpoint.isMobile ? (
          <>
            <ExpandableText text={helmControl?.shortDescription} />
            <Separator className="mt-[1.5rem]" />
          </>
        ) : (
          <StoryblokComponent blok={textBlok} />
        )}
      </div>

      <div className="m-auto w-full max-w-[360px] hidden">
        <Plyr
          options={{
            controls: [
              "play-large",
              "rewind",
              "play",
              "fast-forward",
              "progress",
              "current-time",
              "mute",
              "volume",
              "fullscreen",
            ],
            captions: { active: true, language: "auto", update: true },
            previewThumbnails: { enabled: false, src: "" },
            ratio: "9:16",
          }}
          source={videoSrc}
        />
      </div>
    </div>
  );
};
