/* eslint-disable react-hooks/exhaustive-deps */
import { useAuth0 } from "@auth0/auth0-react";
import { useSwrMyProfile } from "hooks/useSwrMyProfile";
import { defaultTo, first, get } from "lodash";
import { useEffect, useMemo, useRef } from "react";

import { DateFormatType, MedicareRole } from "enums";
import {
  auLabelledTimeZone,
  calculateMinute,
  consultMethodList,
  fundingMethodOptions,
  isTimezoneDST,
  toFundingType,
  toZonedTimeFormat
} from "utils";
import {
  getStoredFundingMethod,
  getStoredReserveData,
  getStoredTimeZone,
} from "utils/storage.util";
import { CountDownClock } from "../CountDownClock";
import {
  renderAppointmentCost,
  renderPsychologistDisplayRole,
} from "./booking-finalise.util";

type CustomProps = {
  shouldCountDown?: boolean;
  retrieveStickyHeightFunction?: (height: number) => void;
};

export const AppointmentSummarySection = ({
  shouldCountDown = true,
  retrieveStickyHeightFunction,
}: CustomProps) => {
  const { isAuthenticated } = useAuth0();
  const { data: profile } = useSwrMyProfile();
  const profileTimeZone = profile?.timeZone;

  const storedTimeZone = getStoredTimeZone();
  const reserveAppointmentData = getStoredReserveData();

  const summaryDivRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!summaryDivRef?.current) return;

    const height = summaryDivRef.current?.offsetHeight;

    if (height && typeof retrieveStickyHeightFunction === "function") {
      retrieveStickyHeightFunction(height);
    }
  }, [summaryDivRef.current?.offsetHeight]);

  const psychologistData = reserveAppointmentData?.psychologist;
  const appointmentData = first(
    reserveAppointmentData?.reserveAppointment.appointments
  );

  const { startDateTime, endDateTime } = {
    ...appointmentData,
  };

  const patientName = useMemo(() => {
    if (isAuthenticated) {
      return profile ? `${profile.firstName} ${profile.lastName}` : "";
    }

    return appointmentData?.clientRecord?.clientProfiles?.[0]?.name || "";
  }, [isAuthenticated, profile, appointmentData?.clientRecord?.clientProfiles]);

  const appointmentConsult = useMemo(() => {
    const fundingType =
      toFundingType(appointmentData?.claimType) ?? getStoredFundingMethod();

    const fundingMethod = fundingMethodOptions.find(
      ({ value }) => value === fundingType
    )?.label;

    const fallbackConsult = defaultTo(fundingMethod, "");

    return get(appointmentData, "sessionTypeName", fallbackConsult);
  }, [fundingMethodOptions, appointmentData?.claimType]);

  const appointmentMinutes = useMemo(() => {
    return calculateMinute(startDateTime, endDateTime);
  }, [startDateTime, endDateTime]);

  const appointmentDateFormatted = useMemo(() => {
    if (!appointmentData) return "";

    const { startDateTime, endDateTime } = appointmentData;

    const timeZone = defaultTo(profileTimeZone, storedTimeZone);

    const appointmentDate = toZonedTimeFormat(
      startDateTime,
      timeZone,
      DateFormatType.APPOINTMENT_DATE
    );

    const startTimeFormatted = toZonedTimeFormat(startDateTime, timeZone);
    const endTimeFormatted = toZonedTimeFormat(endDateTime, timeZone);

    const getTimeZoneLabel = get(auLabelledTimeZone, timeZone);

    if (!getTimeZoneLabel) {
      return `${appointmentDate}, ${startTimeFormatted} - ${endTimeFormatted}, ${timeZone}`;
    }

    const isDst = isTimezoneDST(timeZone);

    const dstCode = isDst
      ? getTimeZoneLabel.dstCode
      : getTimeZoneLabel.nonDstCode;

    const dstHr = isDst ? getTimeZoneLabel.dstHr : getTimeZoneLabel.nonDstHr;

    return `${appointmentDate}, ${startTimeFormatted} - ${endTimeFormatted} (GMT+${dstHr} ${dstCode})`;
  }, [appointmentData, profileTimeZone]);

  const appointmentDeliveryType = useMemo(() => {
    const consultMethod = consultMethodList.find(
      ({ value }) => value === appointmentData?.deliveryType
    )?.label;

    return defaultTo(consultMethod, "");
  }, [appointmentData]);

  const medicareRole = psychologistData?.medicare?.role as MedicareRole;

  return (
    <div>
      <div className="rounded-xl overflow-hidden">
        <div
          ref={summaryDivRef}
          className="flex flex-col gap-3 bg-highlight p-7 text-15 leading-loose"
        >
          <div className="mb-2">
            <span className="text-14 text-primary leading-7 md:text-18 md:leading-6 xl:text-24 xl:leading-9">
              Appointment summary
            </span>
          </div>

          <div className="flex">
            <div className="flex-1 font-bold">Appointment type</div>
            <div className="flex-1">{appointmentConsult}</div>
          </div>

          <div className="flex">
            <div className="flex-1 font-bold">For</div>
            <div className="flex-1">{patientName}</div>
          </div>

          <div className="flex">
            <div className="flex-1 font-bold">Psychologist</div>
            <div className="flex-1">
              <div>{psychologistData?.name},</div>
              <div>{renderPsychologistDisplayRole(medicareRole)}</div>
            </div>
          </div>

          <div className="flex">
            <div className="flex-1 font-bold">Duration</div>
            <div className="flex-1">{appointmentMinutes} minutes</div>
          </div>

          <div className="flex">
            <div className="flex-1 font-bold">Date/Time</div>
            <div className="flex-1">{appointmentDateFormatted}</div>
          </div>

          <div className="flex">
            <div className="flex-1 font-bold">Mode</div>
            <div className="flex-1">{appointmentDeliveryType}</div>
          </div>

          <div className="flex">
            <div className="flex-1 font-bold">Cost</div>
            <div className="flex-1">{renderAppointmentCost()}</div>
          </div>
        </div>

        {shouldCountDown && (
          <div className="flex flex-col gap-3 bg-light-grey p-7">
            <CountDownClock />
          </div>
        )}
      </div>
    </div>
  );
};
