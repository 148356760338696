import * as Yup from "yup";

import { DEFAULT_PHONE_COUNTRY_CODE } from "core/booking.constant";
import { NDIS_NUMBER_REGEX } from "core/regex.constant";
import { isValidPhoneNumber } from "libphonenumber-js";
import { get } from "lodash";
import {
  toInvalidMessage,
  toRequiredMessage,
  validateBehalfOfChild,
  validateBehalfOfSomeOne,
} from "utils";
import { getAddressFormValidation } from "../shared/shared.resolver";

export const getBookNDISFormResolver = (accessToken: string) =>
  Yup.object({
    behalfOf: Yup.string().required(toRequiredMessage("Behalf of")),
    bookerName: Yup.string()
      .trim()
      .when("behalfOf", ([behalfOf], field) =>
        validateBehalfOfSomeOne(behalfOf)
          ? field.required(toRequiredMessage("Your name"))
          : field.optional()
      ),
    bookerPhone: Yup.string()
      .trim()
      .test({
        name: "phone",
        message: "Phone number is not valid",
        test: (phoneNumber, context) => {
          const behalfOf = get(context.parent, "behalfOf", "");

          if (!validateBehalfOfSomeOne(behalfOf)) {
            return true;
          }

          if (
            !isValidPhoneNumber(phoneNumber || "", DEFAULT_PHONE_COUNTRY_CODE)
          ) {
            return context.createError({
              path: "bookerPhone",
              message: "Phone number is not valid",
            });
          }

          return true;
        },
      })
      .when("behalfOf", ([behalfOf], field) =>
        validateBehalfOfSomeOne(behalfOf)
          ? field.required(toRequiredMessage("Your contact"))
          : field.optional()
      ),
    parentFirstName: Yup.string()
      .trim()
      .when("behalfOf", ([behalfOf], field) =>
        validateBehalfOfChild(behalfOf)
          ? field.required(toRequiredMessage("Parent first name"))
          : field.optional()
      ),
    parentLastName: Yup.string()
      .trim()
      .when("behalfOf", ([behalfOf], field) =>
        validateBehalfOfChild(behalfOf)
          ? field.required(toRequiredMessage("Parent last name"))
          : field.optional()
      ),
    parentEmail: Yup.string().when("behalfOf", ([behalfOf], field) =>
      validateBehalfOfChild(behalfOf)
        ? field
            .email(toInvalidMessage("Parent email"))
            .required(toRequiredMessage("Parent email"))
        : field.optional()
    ),
    ndisNumber: Yup.string()
      .required(toRequiredMessage("NDIS number"))
      .matches(NDIS_NUMBER_REGEX, "NDIS number must have 9 digits"),
    plan: Yup.string().required(toRequiredMessage("Plan")),
    uploadedFiles: Yup.array().max(6, "The maximum file is six"),
  }).concat(getAddressFormValidation());
