import { cn } from "utils";

type Props = {
  className?: string;
};

export const Separator = ({ className: customClasses, ...props }: Props) => {
  return (
    <hr
      className={cn("w-full border-secondary-darker border-t", customClasses)}
    />
  );
};
