import {
  PsychologistDetailQuoteStoryblok,
  QuoteStoryblok,
} from "@/types/component-types-sb";
import { Props } from "@/types/core";
import { StoryblokComponent, storyblokEditable } from "@storyblok/react";

import { PsychologistResponse } from "@/services/psychologist.service";
import { ScrollAnchorIds } from "enums";
import { useBreakpoint } from "hooks/useBreakpoint";
import { Separator } from "./Separator";
import { SmoothScrollButton } from "./SmoothScrollButton";

type CustomProps = Props<PsychologistDetailQuoteStoryblok> & {
  psychologist: PsychologistResponse;
};

export const PsychologistDetailQuote = ({
  blok,
  psychologist,
}: CustomProps) => {
  const breakpoint = useBreakpoint();

  const { helmControl } = psychologist || {};

  const quoteBlok = {
    content: helmControl?.quote || "Unknown",
    component: "quote",
  } as QuoteStoryblok;

  return (
    <div
      {...storyblokEditable(blok)}
      className="flex w-full flex-col items-center justify-start text-xl font-normal leading-9 md:px-12 md:py-7"
    >
      <StoryblokComponent blok={quoteBlok} />

      {breakpoint.isMobile && (
        <>
          <Separator className="mb-[1.25rem]" />
          <SmoothScrollButton
            targetId={ScrollAnchorIds.PSYCHOLOGIST_DETAIL_FUNCTIONS}
            label="Book now"
            customClasses="w-[90%] mx-auto"
          />
          <Separator className="mt-[1.25rem]" />
        </>
      )}
    </div>
  );
};
