import { StoryblokComponent, storyblokEditable } from "@storyblok/react";
import { useClickAway, useToggle } from "react-use";

import { HeaderStoryblok } from "@/types/component-types-sb";
import { Props } from "@/types/core";
import { useAuth0 } from "@auth0/auth0-react";
import { CloseSvg } from "assets/icons/CloseSvg";
import { MenuSvg } from "assets/icons/MenuSvg";
import { UserSvg } from "assets/icons/UserSvg";
import { ProfileMenuMobile } from "components/header/ProfileMenuMobile";
import { useHomePageRedirection } from "hooks/useHomePageRedirection";
import { useLoginNavigate } from "hooks/useLoginNavigate";
import { useSwrAttachedClinician } from "hooks/useSwrAttachedClinician";
import { useSwrMyProfile } from "hooks/useSwrMyProfile";
import { defaultTo } from "lodash";
import { useMemo, useRef } from "react";
import { Link } from "react-router-dom";
import { Routes } from "routes/main.routes";
import { cn, getDefaultButtonStyles, isDetailsPage, isMatchedUrl } from "utils";
import { UserDataUpdater } from "./UserDataUpdater";

export const Header = ({ blok }: Props<HeaderStoryblok>) => {
  const session = useAuth0();

  const menuRef = useRef<HTMLDivElement>(null);

  const { loginRedirectSOH } = useLoginNavigate();
  const { data: attachedClinician } = useSwrAttachedClinician();

  useSwrMyProfile();
  useHomePageRedirection();

  const [isShowSideMenu, toggleShowSideMenu] = useToggle(false);
  const [isShowMobileProfileMenu, toggleShowSideProfile] = useToggle(false);

  useClickAway(menuRef, () => {
    if (!isShowSideMenu) return;

    toggleShowSideMenu(false);
  });

  const shouldHideFindAPsychologistButton: boolean = useMemo(() => {
    const hiddenLocationUrls = [
      Routes.OUR_PSYCHOLOGISTS,
      Routes.SIGNUP,
      Routes.BOOKING,
    ];

    return (
      hiddenLocationUrls.some(isMatchedUrl) ||
      (session.isAuthenticated && Boolean(attachedClinician?.clinician))
    );
  }, [session.isAuthenticated, attachedClinician?.clinician]);

  const menuIconStyles = "w-5 h-5 sm:w-7 sm:h-7 md:w-10 md:h-10";

  return (
    <header
      {...storyblokEditable(blok)}
      className="sticky top-0 z-50 -mx-4 xl:pt-0 sm:-mx-6 md:-mx-8 lg:-mx-12"
    >
      <UserDataUpdater />
      {/* Large screen */}
      <div
        className={cn("hidden py-5 bg-light xl:block shadow-bottom", {
          "shadow-lg": isDetailsPage(),
        })}
      >
        <div className="flex mx-4 sm:mx-6 md:mx-8 lg:mx-12">
          {blok.logo?.map((logo) => (
            <div key={logo._uid} className="max-w-[200px] flex self-center">
              <StoryblokComponent blok={logo} />
            </div>
          ))}

          <div className="flex items-center justify-center flex-1 font-medium gap-x-5 whitespace-nowrap">
            {blok.nav_items?.map((item) => (
              <StoryblokComponent key={item._uid} blok={item} />
            ))}

            {blok.login_button?.map((btn) => (
              <StoryblokComponent key={btn._uid} blok={btn} />
            ))}
          </div>

          {blok.nav_button?.map((btn) => (
            <StoryblokComponent key={btn._uid} blok={btn} />
          ))}
        </div>
      </div>

      {/* Small screen */}
      <div
        ref={menuRef}
        className={cn("bg-white rounded-b-xl no-scrollbar xl:hidden", {
          "shadow-lg": !isShowSideMenu,
        })}
      >
        <div className="flex flex-row px-6 py-5">
          <div className="flex flex-[3]">
            {blok.logo?.map((logo) => (
              <div
                key={logo._uid}
                className="max-w-[150px] xl:max-w-[200px] self-center"
              >
                <StoryblokComponent blok={logo} />
              </div>
            ))}
          </div>

          <div className="flex flex-[1] items-center justify-end gap-3 sm:gap-3 xl:gap-4">
            <div
              className={cn("cursor-pointer", {
                hidden: !session.isAuthenticated,
              })}
            >
              {blok.login_button?.map((btn) => (
                <StoryblokComponent
                  key={btn._uid}
                  blok={btn}
                  onSmallScreenAvatarClick={() => {
                    toggleShowSideProfile();
                    toggleShowSideMenu(false);
                  }}
                />
              ))}
            </div>

            <div
              className="cursor-pointer"
              onClick={() => {
                toggleShowSideMenu();
                toggleShowSideProfile(false);
              }}
            >
              {isShowSideMenu ? (
                <CloseSvg className={cn(menuIconStyles, "fill-primary")} />
              ) : (
                <MenuSvg className={menuIconStyles} />
              )}
            </div>
          </div>
        </div>

        {/* Side menu */}
        <div
          className={cn(
            "relative w-full overflow-y-scroll no-scrollbar",
            isShowSideMenu ? "h-screen duration-500 pb-52" : "h-0 duration-500"
          )}
        >
          <div className="bg-secondary-darker h-[1px] mx-6"></div>
          <ul
            className={cn(
              "font-medium pt-3 pb-2 ease-in-out duration-500 mx-6"
            )}
          >
            {blok.nav_items?.map((item) => (
              <li key={item._uid}>
                <StoryblokComponent blok={item} />
              </li>
            ))}
          </ul>

          {isShowSideMenu &&
            !session.isAuthenticated &&
            blok.login_button?.map((btn) => (
              <div
                key={btn._uid}
                className={cn(
                  "fixed bottom-5 w-full group cursor-pointer duration-0 mb-[64px]",
                  isShowSideMenu
                    ? "grid-rows-[1fr] opacity-100 duration-1000"
                    : "grid-rows-[0fr] opacity-0"
                )}
              >
                <Link
                  to={defaultTo(btn.link?.cached_url, Routes.PLACEHOLDER)}
                  reloadDocument
                  className="flex items-center justify-center h-12 gap-2 mx-5 bg-white border-2 rounded-full border-primary group-hover:border-secondary-darker group-hover:bg-secondary-darker group-hover:duration-300 sm:mx-6"
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();

                    loginRedirectSOH();
                  }}
                >
                  <UserSvg className="group-hover:fill-primary group-hover:duration-300" />
                  <span className="font-medium text-primary">Login</span>
                </Link>
              </div>
            ))}
        </div>

        {/* Side profile */}
        {session.isAuthenticated && (
          <ProfileMenuMobile
            isShowMobileMenu={isShowMobileProfileMenu}
            shouldHideFindPsychologistButton={shouldHideFindAPsychologistButton}
          />
        )}

        <div
          className={cn(
            "fixed bottom-0 flex flex-row w-screen h-[80px]",
            "xl:hidden",
            {
              hidden: shouldHideFindAPsychologistButton,
            }
          )}
        >
          {renderMobileFindAPsychologistButton(
            shouldHideFindAPsychologistButton
          )}
          <div className="w-[110px]">{/* PLACEHOLDER FOR CHAT ICON */}</div>
        </div>
      </div>
    </header>
  );
};

const renderMobileFindAPsychologistButton = (
  shouldHideFindAPsychologistButton: boolean
) => {
  if (shouldHideFindAPsychologistButton) {
    return <></>;
  }

  return (
    <Link
      to={Routes.OUR_PSYCHOLOGISTS}
      className="flex flex-col justify-center w-full"
      reloadDocument
    >
      <button
        className={cn(
          getDefaultButtonStyles(),
          "ml-3 mb-3 ring-3 !ring-light-grey"
        )}
      >
        <span className="line-clamp-1">Find a psychologist</span>
      </button>
    </Link>
  );
};
