import { ReactElement, forwardRef } from "react";
import { cn } from "utils";
import { v4 } from "uuid";

type TextInputProps = {
  name?: string;
  title?: string;
  placeholder?: string;
  hintElement?: ReactElement;
  numberOnly?: boolean;
  isPhoneNumber?: boolean;
  type?: string;

  onChangeValue: (event: React.ChangeEvent<HTMLInputElement>) => void;
} & Pick<
  React.InputHTMLAttributes<HTMLInputElement>,
  "defaultValue" | "className"
>;

export const TextInput = forwardRef<HTMLInputElement, TextInputProps>(
  (
    {
      name = "",
      title = "",
      placeholder,
      hintElement,
      numberOnly = false,
      isPhoneNumber = false,
      type = "text",
      onChangeValue,
      ...props
    },
    ref
  ) => {
    const elemId = v4();

    const handleChangeValue = (e: React.ChangeEvent<HTMLInputElement>) => {
      onChangeValue(e);
    };

    return (
      <div className="flex flex-col justify-between w-full gap-y-1">
        <label
          htmlFor={elemId}
          className="flex items-center justify-between text-dark-grey"
        >
          {title}
          {Boolean(hintElement) && hintElement}
        </label>

        <input
          ref={ref}
          {...props}
          id={elemId}
          name={name}
          type={type}
          className={cn(
            "w-full py-3 pl-3 border rounded-md border-light-grey-3 focus:border-secondary-darker focus:ring-secondary-darker",
            props?.className
          )}
          placeholder={placeholder}
          onChange={handleChangeValue}
          onKeyPress={
            numberOnly
              ? isPhoneNumber
                ? handlePhoneNumber
                : handleNumberOnly
              : undefined
          }
        />
      </div>
    );
  }
);

const handleNumberOnly = (event: React.KeyboardEvent<HTMLInputElement>) => {
  const keyCode = event.keyCode || event.which;
  const keyValue = String.fromCharCode(keyCode);

  if (!/^\d+$/.test(keyValue)) event.preventDefault();
};

const handlePhoneNumber = (event: React.KeyboardEvent<HTMLInputElement>) => {
  const keyCode = event.keyCode || event.which;
  const keyValue = String.fromCharCode(keyCode);

  if (!/^[\d()+\s]*$/.test(keyValue)) event.preventDefault();
};
