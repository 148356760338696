import { isEqual } from "lodash";
import * as Yup from "yup";

import { MEDICARE_NUMBER_REGEX, ONLY_DIGITS_REGEX } from "core/regex.constant";
import { DVACardType } from "enums";
import { toRequiredMessage } from "utils";
import { getAddressFormValidation } from "../shared/shared.resolver";

export const isDVAWhiteCard = (dvaCardType: string): boolean =>
  isEqual(dvaCardType, DVACardType.WHITE);

export const getBookDVAFormResolver = () =>
  Yup.object({
    dvaCardNumber: Yup.string()
      .required(toRequiredMessage("DVA card number"))
      .length(8, "DVA number must be 8 characters long"),
    dvaCardType: Yup.string().required(toRequiredMessage("DVA card type")),

    medicareNumber: Yup.string().when("dvaCardType", ([dvaCardType], field) =>
      isDVAWhiteCard(dvaCardType)
        ? field
            .required(toRequiredMessage("Medicare card number"))
            .matches(
              MEDICARE_NUMBER_REGEX,
              "Medicare card number must have 10 digits"
            )
        : field.optional()
    ),
    irnNumber: Yup.string().when("dvaCardType", ([dvaCardType], field) =>
      isDVAWhiteCard(dvaCardType)
        ? field
            .required(toRequiredMessage("IRN number"))
            .matches(ONLY_DIGITS_REGEX, "Must be only digits")
        : field.optional()
    ),
    expiryDate: Yup.string()
      .trim()
      .when("dvaCardType", ([dvaCardType], field) =>
        isDVAWhiteCard(dvaCardType)
          ? field.required(toRequiredMessage("Expiry date"))
          : field.optional()
      ),
  }).concat(getAddressFormValidation());
