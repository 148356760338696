export const PHONE_NUMBER_REGEX =
  /^(02|04|03|07|08|\+612|\+613|\+614|\+617|\+618|\+6104|\+6102|\+6103|\+6107|\+6108)(\s?\d){8}$/g;

//  Only digits
export const ONLY_DIGITS_REGEX = /^[0-9]+$/;

//  Postcode - Postcodes in Australia are just 4 numbers
export const POSTCODE_REGEX = /^[0-9]{4}$/;

//  Medicare card number - Medicare Card Number is the 10 digit number that appears above your name
export const MEDICARE_NUMBER_REGEX = /^[0-9]{10}$/;

//  DVA - 8 alphanumeric characters - Starts with 3 letters followed by 5 numbers
export const DVA_NUMBER_REGEX = /^[a-zA-Z]{3}[0-9]{5}$/;

//  NDIS number - only 9 numbers
export const NDIS_NUMBER_REGEX = /^[0-9]{9}$/;

//  WorkCover - at least 7-12 characters, alphanumeric
export const WORKCOVER_NUMBER_REGEX = /^[a-zA-Z0-9]{7,12}$/;

// Start with "/", "http", "mailto" or "tel"
export const SAFE_LINK_REGEX = /^(\/|http|mailto|tel)/;

export const EMAIL_REGEX = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
