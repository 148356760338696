/* eslint-disable react-hooks/exhaustive-deps */
import { isEmpty } from "lodash";
import { useEffect } from "react";
import { useFormContext } from "react-hook-form";

type Props = {
  loading: boolean;
  name: string;
};

// This hook has to be used within context of <FormProvider /> by react-hook-form
export const useFormContextLoadingState = (props: Props) => {
  const {
    setError,
    clearErrors,
    getFieldState,
    trigger,
    formState: { errors },
  } = useFormContext();
  const fieldState = getFieldState(props.name);

  useEffect(() => {
    if (!fieldState.isDirty) return;

    if (props.loading) {
      setError(`${props.name}.loading`, { type: "manual" });
    } else {
      clearErrors(`${props.name}.loading`);
    }
  }, [props.loading]);

  useEffect(() => {
    const isFormValid =
      fieldState.isDirty &&
      !fieldState.invalid &&
      !props.loading &&
      isEmpty(errors);

    // Re-trigger validation when field is valid
    if (isFormValid) {
      trigger(props.name);
    }
  }, [fieldState.invalid]);

  return null;
};
