import { cn } from "utils";
import { motion } from "framer-motion";

type Props = React.SVGAttributes<SVGSVGElement>;

export const InputSuccessInfo = ({ ...props }: Props) => {
  return (
    <motion.svg
      className={cn(
        "w-[24px] h-[24px] text-green-400 dark:text-white",
        props?.className
      )}
      aria-hidden="true"
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
      initial={{ scale: 0 }}
      animate={{ scale: 1 }}
      exit={{ scale: 0 }}
      transition={{ duration: 0.3 }}
    >
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M8.5 11.5 11 14l4-4m6 2a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
      />
    </motion.svg>
  );
};
