/* eslint-disable react-hooks/exhaustive-deps */
import { motion } from "framer-motion";
import { isEmpty } from "lodash";
import { useEffect, useRef } from "react";
import { useToggle, useWindowSize } from "react-use";

import { ChevronSvg } from "assets/icons/ChevronSvg";

import { cn } from "utils";

type Props = {
  text?: string;
};

// Maximum 5, if greater ensure this number is defined in tailwind.config.js
const NUMBER_OF_LINES = 3;
const LINE_HEIGHT_PX = 30;

const MAX_HEIGHT = NUMBER_OF_LINES * LINE_HEIGHT_PX;

export const ExpandableText = ({ text, ...props }: Props) => {
  const { width } = useWindowSize();

  const [isTextExpanded, toggleIsTextExpanded] = useToggle(false);
  const [shouldShowExpand, toggleShouldShowExpand] = useToggle(false);

  const componentRef = useRef<HTMLDivElement>(null);

  const isShowExpand = !isEmpty(text) && shouldShowExpand;

  useEffect(() => {
    if (!componentRef?.current || isEmpty(text)) return;

    const isOverflowing = componentRef?.current?.scrollHeight > MAX_HEIGHT;

    toggleShouldShowExpand(isOverflowing);
  }, [text, width]);

  return (
    <div className="flex flex-col justify-start items center">
      <motion.p
        className={cn(
          `text-base leading-[${LINE_HEIGHT_PX}px] transition-all`,
          {
            [`line-clamp-${NUMBER_OF_LINES}`]: !isTextExpanded,
          }
        )}
        ref={componentRef}
      >
        {text}
      </motion.p>

      {isShowExpand && (
        <div
          className={cn(
            "flex items-center text-sm cursor-pointer mt-2 font-medium text-primary"
          )}
          onClick={toggleIsTextExpanded}
        >
          {isTextExpanded ? "Collapse" : "Expand"}
          <span
            className={cn("ml-1 duration-500", {
              "-rotate-180": isTextExpanded,
            })}
          >
            <ChevronSvg />
          </span>
        </div>
      )}
    </div>
  );
};
