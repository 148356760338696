import { StoryblokComponent, storyblokEditable } from "@storyblok/react";
import { LinkWrapper } from "components/shared/LinkWrapper";
import { Alignment, ScreenBreakpoints } from "enums";
import { defaultTo, isNumber } from "lodash";
import { useWindowSize } from "react-use";
import { Routes } from "routes/main.routes";
import {
  MARK_BOLD,
  MARK_LINK,
  NODE_HEADING,
  NODE_LI,
  NODE_PARAGRAPH,
  NODE_QUOTE,
  RenderOptions,
  render,
} from "storyblok-rich-text-react-renderer";
import {
  HeadingStoryblok,
  MultilinkStoryblok,
  RichTextStoryblok,
} from "types/component-types-sb";
import { Props } from "types/core";

import { cn, toNoCachedLink, toSafeLink } from "utils";
import { v4 as GUID } from "uuid";

type CustomProps = Props<RichTextStoryblok> & {
  parentStyles: string;
};

const ListBulletPositions: Record<string, string> = {
  "text-xs": "mt-1.5 md:mt-1 xl:mt-0.5 2xl:mt-0",
  "text-sm": "mt-1.5 md:mt-1 xl:mt-0.5 2xl:mt-0",
  "text-base": "mt-2 md:mt-1.5 xl:mt-1 2xl:mt-0.5",
  "text-lg": "mt-2.5 md:mt-2 xl:mt-1.5 2xl:mt-1",
  "text-xl": "mt-3 md:mt-2.5 xl:mt-2 2xl:mt-1.5",
  "text-2xl": "mt-4 md:mt-3.5 xl:mt-3 2xl:mt-2.5",
  "text-3xl": "w-3 h-3 mt-5 md:mt-4 xl:mt-3.5 2xl:mt-3",
  "text-4xl": "w-3 h-3 mt-6 md:mt-5 xl:mt-4 2xl:mt-3.5",
  "text-5xl": "w-4 h-4 mt-7 xl:mt-6",
  "text-6xl": "w-5 h-5 mt-9 xl:mt-8",
};

const getRenderSettings = (blok: RichTextStoryblok): RenderOptions => {
  return {
    markResolvers: {
      [MARK_LINK]: (child, props) => {
        const mailTo =
          props?.linktype === "email" ? `mailto:${props.href}` : null;

        let linkTo = defaultTo(
          toNoCachedLink(props as MultilinkStoryblok),
          props.href
        );

        return (
          <LinkWrapper
            to={mailTo ?? toSafeLink(defaultTo(linkTo, Routes.PLACEHOLDER))}
            target={props.target}
            className={cn(
              "text-primary cursor-pointer font-medium underline",
              blok.fontSize
            )}
          >
            {child}
          </LinkWrapper>
        );
      },
      [MARK_BOLD]: (children) => {
        return <b className="font-semibold text-dark-grey">{children}</b>;
      },
    },
    nodeResolvers: {
      [NODE_LI]: (li) => {
        const haveCustomListIcon =
          blok.customListIcon && blok.customListIcon.filename;

        return (
          <li className="flex gap-x-3">
            <div className="md:mt-0.5 xl:mt-1 2xl:mt-1.5">
              {haveCustomListIcon ? (
                <div className="rounded-full w-5 h-5 mt-1 lg:mt-0.5 2xl:-mt-0.5">
                  <img
                    src={blok.customListIcon?.filename}
                    alt={blok.customListIcon?.filename}
                  />
                </div>
              ) : (
                <div
                  className={cn("rounded-full w-2 h-2 bg-secondary-darker", [
                    ListBulletPositions[blok.fontSize ?? ""] ??
                      "mt-2 md:mt-1.5 xl:mt-1 2xl:mt-0.5",
                  ])}
                ></div>
              )}
            </div>
            <div>{li}</div>
          </li>
        );
      },
      [NODE_PARAGRAPH]: (paragraph) => (
        <p
          className={cn(
            "mb-1 md:mb-2 xl:mb-3 leading-relaxed",
            defaultTo(blok.fontSize, "text-base")
          )}
        >
          {paragraph}
        </p>
      ),
      [NODE_QUOTE]: (quote) => (
        <blockquote className="my-5 -ml-5 border-l-2 md:ml-0 border-l-secondary-darker">
          <div className="pl-5 md:pl-8">{quote}</div>
        </blockquote>
      ),
      [NODE_HEADING]: (heading, props) => {
        const headingProperties = {
          _uid: GUID(),
          component: "heading",
          content: heading,
          level: `h${props.level}`,
        } as HeadingStoryblok;

        return (
          <div className="my-2 md:my-3 xl:my-4 2xl:my-5">
            <StoryblokComponent
              key={headingProperties._uid}
              blok={headingProperties}
            />
          </div>
        );
      },
    },
    defaultBlokResolver: (name, props) => (
      <div className="mb-2 md:mb-3 xl:mb-4 2xl:mb-5">
        <StoryblokComponent
          key={props._uid}
          blok={{ ...props, component: name }}
        />
      </div>
    ),
    defaultStringResolver: (str) => <p>{str}</p>,
  };
};

const aligns: Record<string, string> = {
  [Alignment.LEFT]: "md:text-left",
  [Alignment.CENTER]: "md:text-center",
  [Alignment.RIGHT]: "md:text-right",
  [Alignment.BETWEEN]: "md:text-justify",
};

const mobileAligns: Record<string, string> = {
  [Alignment.LEFT]: "text-left",
  [Alignment.CENTER]: "text-center",
  [Alignment.RIGHT]: "text-right",
  [Alignment.BETWEEN]: "text-justify",
};

export const RichText = ({ blok, parentStyles }: CustomProps) => {
  const { width } = useWindowSize();

  const content = render(blok.content, getRenderSettings(blok));

  const classes = cn(
    "flex justify-center",
    parentStyles,
    blok.backgroundColor,
    {
      [aligns[blok.textAlign ?? ""]]: Boolean(blok.textAlign),
      [mobileAligns[blok.mobileTextAlign ?? ""]]:
        Boolean(blok.mobileTextAlign) && width < ScreenBreakpoints.MD,
      "px-7 py-5 rounded-lg my-3 md:my-4 xl:my-5 2xl:my-6": blok.isHighlight,
    }
  );

  const hasLineClamp =
    isNumber(Number(blok.lineClamp)) && Number(blok.lineClamp) > 0;

  return (
    <div {...storyblokEditable(blok)}>
      <div className={classes}>
        <div
          style={{
            width: `${blok.width}%`,
            overflow: hasLineClamp ? "hidden" : "visible",
            WebkitLineClamp: hasLineClamp ? blok.lineClamp : "unset",
            display: hasLineClamp ? "-webkit-box" : "block",
            WebkitBoxOrient: hasLineClamp ? "vertical" : "unset",
          }}
        >
          {content}
        </div>
      </div>
    </div>
  );
};
