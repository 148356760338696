import * as Yup from "yup";

import {
  MEDICARE_NUMBER_REGEX,
  ONLY_DIGITS_REGEX,
  POSTCODE_REGEX,
} from "core/regex.constant";
import { ConfirmRadioType } from "enums";
import { isEqual, toNumber } from "lodash";
import { ChildFormData } from "models";
import { MinifiedSignupUserData, Profile } from "models/client.model";
import { validateMedicareAsync } from "services/psychologist.service";
import { toExpiryDate, toInvalidMessage, toRequiredMessage } from "utils";
import {
  getAdultRegisteredBasicInfo,
  getChildRegisteredBasicInfo,
} from "utils/booking.util";

export const getAddressFormValidation = () => {
  return Yup.object({
    streetAddress: Yup.string()
      .trim()
      .required(toRequiredMessage("Street address")),
    streetLineTwoAddress: Yup.string().optional(),
    city: Yup.string().trim().required(toRequiredMessage("City")),
    state: Yup.string().required(toRequiredMessage("State")),
    postcode: Yup.string()
      .required(toRequiredMessage("Postcode"))
      .matches(POSTCODE_REGEX, "Postcode must have 4 digits"),
  });
};

export const getMedicareMySelfFieldsValidation = () =>
  Yup.object({
    isRegisteredWithMedicareName: Yup.boolean().required(),

    firstName: Yup.string()
      .trim()
      .when(
        "isRegisteredWithMedicareName",
        ([isRegisteredWithMedicareName], field) =>
          !isRegisteredWithMedicareName
            ? field.required(toRequiredMessage("First name"))
            : field
      ),
    lastName: Yup.string()
      .trim()
      .when(
        "isRegisteredWithMedicareName",
        ([isRegisteredWithMedicareName], field) =>
          !isRegisteredWithMedicareName
            ? field.required(toRequiredMessage("Last name"))
            : field
      ),
    dateOfBirth: Yup.string()
      .trim()
      .when(
        "isRegisteredWithMedicareName",
        ([isRegisteredWithMedicareName], field) =>
          !isRegisteredWithMedicareName
            ? field.required(toRequiredMessage("Date of birth"))
            : field
      ),

    medicareNumber: Yup.string()
      .required(toRequiredMessage("Medicare card number"))
      .matches(
        MEDICARE_NUMBER_REGEX,
        "Medicare card number must have 10 digits"
      ),
    irnNumber: Yup.string()
      .required(toRequiredMessage("IRN number"))
      .matches(ONLY_DIGITS_REGEX, "Must be only digits"),

    expiryDate: Yup.string().trim().required(toRequiredMessage("Expiry date")),
  });

export const getMedicareSomeOneFieldsValidation = () =>
  Yup.object({
    bookerName: Yup.string().trim().required(toRequiredMessage("Your name")),
    bookerPhone: Yup.string()
      .trim()
      .required(toRequiredMessage("Your contact")),

    isRegisteredWithMedicareName: Yup.boolean().required(),

    firstName: Yup.string()
      .trim()
      .when(
        "isRegisteredWithMedicareName",
        ([isRegisteredWithMedicareName], field) =>
          !isRegisteredWithMedicareName
            ? field.required(toRequiredMessage("First name"))
            : field
      ),
    lastName: Yup.string()
      .trim()
      .when(
        "isRegisteredWithMedicareName",
        ([isRegisteredWithMedicareName], field) =>
          !isRegisteredWithMedicareName
            ? field.required(toRequiredMessage("Last name"))
            : field
      ),
    dateOfBirth: Yup.string()
      .trim()
      .when(
        "isRegisteredWithMedicareName",
        ([isRegisteredWithMedicareName], field) =>
          !isRegisteredWithMedicareName
            ? field.required(toRequiredMessage("Date of birth"))
            : field
      ),

    medicareNumber: Yup.string()
      .required(toRequiredMessage("Medicare card number"))
      .matches(
        MEDICARE_NUMBER_REGEX,
        "Medicare card number must have 10 digits"
      ),
    irnNumber: Yup.string()
      .required(toRequiredMessage("IRN number"))
      .matches(ONLY_DIGITS_REGEX, "Must be only digits"),

    expiryDate: Yup.string().trim().required(toRequiredMessage("Expiry date")),
  });

export const getMedicareChildFieldsValidation = () =>
  Yup.object({
    // Child from 13 to 18
    guardianFirstName: Yup.string()
      .trim()
      .required(toRequiredMessage("Guardian first name")),
    guardianLastName: Yup.string()
      .trim()
      .required(toRequiredMessage("Guardian last name")),
    guardianEmail: Yup.string()
      .email(toInvalidMessage("Guardian email"))
      .required(toRequiredMessage("Guardian email")),

    // Separate card value
    hasChildSeparateCard: Yup.string().required(
      toRequiredMessage("Has child separate card value")
    ),

    // Separate card Yes
    childSeparateNameMatchedCard: Yup.boolean().when(
      "hasChildSeparateCard",
      ([hasChildSeparateCard], field) =>
        hasChildSeparateCard === ConfirmRadioType.YES
          ? field.required()
          : field.optional()
    ),
    childSeparateFirstName: Yup.string()
      .trim()
      .when(
        ["hasChildSeparateCard", "childSeparateNameMatchedCard"],
        ([hasChildSeparateCard, childSeparateNameMatchedCard], field) =>
          hasChildSeparateCard === ConfirmRadioType.YES &&
          !childSeparateNameMatchedCard
            ? field.required(toRequiredMessage("Child first name"))
            : field.optional()
      ),
    childSeparateLastName: Yup.string()
      .trim()
      .when(
        ["hasChildSeparateCard", "childSeparateNameMatchedCard"],
        ([hasChildSeparateCard, childSeparateNameMatchedCard], field) =>
          hasChildSeparateCard === ConfirmRadioType.YES &&
          !childSeparateNameMatchedCard
            ? field.required(toRequiredMessage("Child last name"))
            : field.optional()
      ),
    childSeparateDateOfBirth: Yup.string()
      .trim()
      .when(
        ["hasChildSeparateCard", "childSeparateNameMatchedCard"],
        ([hasChildSeparateCard, childSeparateNameMatchedCard], field) =>
          hasChildSeparateCard === ConfirmRadioType.YES &&
          !childSeparateNameMatchedCard
            ? field.required(toRequiredMessage("Child date of birth"))
            : field.optional()
      ),
    childSeparateMedicareNumber: Yup.string().when(
      "hasChildSeparateCard",
      ([hasChildSeparateCard], field) =>
        hasChildSeparateCard === ConfirmRadioType.YES
          ? field
              .required(toRequiredMessage("Child Medicare card number"))
              .matches(
                MEDICARE_NUMBER_REGEX,
                "Medicare card number must have 10 digits"
              )
          : field.optional()
    ),
    childSeparateIrn: Yup.string().when(
      "hasChildSeparateCard",
      ([hasChildSeparateCard], field) =>
        hasChildSeparateCard === ConfirmRadioType.YES
          ? field
              .required(toRequiredMessage("Child IRN number"))
              .matches(ONLY_DIGITS_REGEX, "Must be only digits")
          : field.optional()
    ),
    childSeparateExpiryDate: Yup.string()
      .trim()
      .when("hasChildSeparateCard", ([hasChildSeparateCard], field) =>
        hasChildSeparateCard === ConfirmRadioType.YES
          ? field.required(toRequiredMessage("Child expiry"))
          : field.optional()
      ),

    // Separate card No
    parentFirstName: Yup.string()
      .trim()
      .when("hasChildSeparateCard", ([hasChildSeparateCard], field) =>
        hasChildSeparateCard === ConfirmRadioType.NO
          ? field.required(toRequiredMessage("Parent first name"))
          : field.optional()
      ),
    parentLastName: Yup.string()
      .trim()
      .when("hasChildSeparateCard", ([hasChildSeparateCard], field) =>
        hasChildSeparateCard === ConfirmRadioType.NO
          ? field.required(toRequiredMessage("Parent last name"))
          : field.optional()
      ),
    parentDateOfBirth: Yup.string()
      .trim()
      .when("hasChildSeparateCard", ([hasChildSeparateCard], field) =>
        hasChildSeparateCard === ConfirmRadioType.NO
          ? field.required(toRequiredMessage("Parent date of birth"))
          : field.optional()
      ),
    parentMedicareNumber: Yup.string().when(
      "hasChildSeparateCard",
      ([hasChildSeparateCard], field) =>
        hasChildSeparateCard === ConfirmRadioType.NO
          ? field
              .required(toRequiredMessage("Parent Medicare card number"))
              .matches(
                MEDICARE_NUMBER_REGEX,
                "Medicare card number must have 10 digits"
              )
          : field.optional()
    ),
    parentIrn: Yup.string().when(
      "hasChildSeparateCard",
      ([hasChildSeparateCard], field) =>
        hasChildSeparateCard === ConfirmRadioType.NO
          ? field
              .required(toRequiredMessage("Parent IRN number"))
              .matches(ONLY_DIGITS_REGEX, "Must be only digits")
          : field.optional()
    ),
    parentExpiryDate: Yup.string()
      .trim()
      .when("hasChildSeparateCard", ([hasChildSeparateCard], field) =>
        hasChildSeparateCard === ConfirmRadioType.NO
          ? field.required(toRequiredMessage("Parent expiry date"))
          : field.optional()
      ),
    childIrn: Yup.string().when(
      "hasChildSeparateCard",
      ([hasChildSeparateCard], field) =>
        hasChildSeparateCard === ConfirmRadioType.NO
          ? field
              .required(toRequiredMessage("Child IRN number"))
              .matches(ONLY_DIGITS_REGEX, "Must be only digits")
          : field.optional()
    ),
  });

export const isValidChildMedicareFields = (
  childFormData: ChildFormData
): boolean => {
  const { hasChildSeparateCard } = childFormData || {};

  const usingChildCard = isEqual(hasChildSeparateCard, ConfirmRadioType.YES);

  const optionalByMatchedName = Yup.string()
    .trim()
    .when("childSeparateNameMatchedCard", {
      is: true,
      then: (field) => field.optional(),
      otherwise: (field) => field.required(),
    });

  const childCardSchema = Yup.object().shape({
    childSeparateFirstName: optionalByMatchedName,
    childSeparateLastName: optionalByMatchedName,
    childSeparateDateOfBirth: optionalByMatchedName,
    childSeparateMedicareNumber: Yup.string()
      .matches(MEDICARE_NUMBER_REGEX)
      .required(),
    childSeparateIrn: Yup.string().matches(ONLY_DIGITS_REGEX).required(),
    childSeparateExpiryDate: Yup.string().trim().required(),
  });

  const parentCardSchema = Yup.object().shape({
    parentFirstName: Yup.string().trim().required(),
    parentLastName: Yup.string().trim().required(),
    parentDateOfBirth: Yup.string().trim().required(),
    parentMedicareNumber: Yup.string()
      .matches(MEDICARE_NUMBER_REGEX)
      .required(),
    parentIrn: Yup.string().matches(ONLY_DIGITS_REGEX).required(),
    childIrn: Yup.string().matches(ONLY_DIGITS_REGEX).required(),
    parentExpiryDate: Yup.string().trim().required(),
  });

  const schema = usingChildCard ? childCardSchema : parentCardSchema;

  const isValid = schema.isValidSync(childFormData);

  return isValid;
};

export const validateChildMedicareCardByTacklitAsync = async (
  formData: {
    hasChildSeparateCard?: string | undefined;
    childSeparateNameMatchedCard?: boolean | undefined;
    childSeparateFirstName?: string | undefined;
    childSeparateLastName?: string | undefined;
    childSeparateDateOfBirth?: string | undefined;
    childSeparateMedicareNumber?: string | undefined;
    childSeparateIrn?: string | undefined;
    childSeparateExpiryDate?: string | undefined;
    childIrn?: string | undefined;
    parentFirstName?: string | undefined;
    parentLastName?: string | undefined;
    parentDateOfBirth?: string | undefined;
    parentMedicareNumber?: string | undefined;
    parentIrn?: string | undefined;
    parentExpiryDate?: string | undefined;
  },
  profile: Profile,
  accessToken: string
) => {
  const userBasicData = getChildRegisteredBasicInfo(
    formData,
    profile,
    accessToken
  );

  try {
    const usingChildCard = isEqual(
      formData.hasChildSeparateCard,
      ConfirmRadioType.YES
    );

    const medicareNumber = usingChildCard
      ? toNumber(formData.childSeparateMedicareNumber)
      : toNumber(formData.parentMedicareNumber);
    const medicareExpiryDate = usingChildCard
      ? toExpiryDate(formData.childSeparateExpiryDate)
      : toExpiryDate(formData.parentExpiryDate);
    const irnNumber = usingChildCard
      ? toNumber(formData.childSeparateIrn)
      : toNumber(formData.childIrn);

    await validateMedicareAsync(
      {
        firstName: userBasicData.firstName,
        lastName: userBasicData.lastName,
        dateOfBirth: userBasicData.dateOfBirth,
        number: medicareNumber,
        expiryDate: medicareExpiryDate,
        irn: irnNumber,
        // Parent profile has to be created first before validation
        // parent: undefined,
        shouldRejectInvalidDetails: true,
      },
      userBasicData.authToken
    );

    return true;
  } catch (err) {
    return false;
  }
};

export const validateAdultMedicareCardByTacklitAsync = async (
  formData: {
    firstName?: string | undefined;
    lastName?: string | undefined;
    dateOfBirth?: string | undefined;
    isRegisteredWithMedicareName: NonNullable<boolean | undefined>;
    medicareNumber: string;
    irnNumber: string;
    expiryDate: string;
  },
  profile: Profile,
  accessToken: string
) => {
  const userBasicData: MinifiedSignupUserData = getAdultRegisteredBasicInfo(
    formData,
    profile,
    accessToken
  );

  try {
    await validateMedicareAsync(
      {
        firstName: userBasicData.firstName,
        lastName: userBasicData.lastName,
        dateOfBirth: userBasicData.dateOfBirth,
        number: toNumber(formData.medicareNumber),
        expiryDate: toExpiryDate(formData.expiryDate),
        irn: toNumber(formData.irnNumber),
        shouldRejectInvalidDetails: true,
      },
      userBasicData.authToken
    );

    return true;
  } catch (err) {
    return false;
  }
};
