import {
  ButtonStoryblok,
  HeadingStoryblok,
  LinkStoryblok,
  RichTextHeadingStoryblok,
  TagsFrameStoryblok,
} from "@/types/component-types-sb";
import classNames from "classnames";
import { GroupBase, StylesConfig } from "react-select";

import {
  Alignment,
  ButtonHoverStyles,
  ButtonSizes,
  ButtonTypes,
  Displays,
  HeadingLevels,
  Layout,
  ScreenBreakpoints,
} from "enums";
import { SelectionOption } from "models";

export { classNames as cn };

// Button
const buttonStylesByTypes: Record<string, string> = {
  [ButtonTypes.SOLID]: "bg-primary text-light ring-2 ring-primary",
  [ButtonTypes.OUTLINED]: "bg-none ring-2 ring-primary text-primary",
  [ButtonTypes.SOFT]: "bg-secondary text-primary ring-2 ring-secondary",
  [ButtonTypes.PLAIN]: "text-primary hover:underline",
};

const buttonStylesBySizes: Record<string, string> = {
  [ButtonSizes.LARGE]: "px-7 h-[56px] text-base md:px-6",
  [ButtonSizes.MEDIUM]: "px-5 h-[50px] text-sm",
  [ButtonSizes.SMALL]: "px-2 h-[32px] text-xs md:px-3",
  [ButtonSizes.EXTRA_SMALL]: "px-2 h-[24] text-xs",
};

const buttonStylesByHover: Record<string, string> = {
  [ButtonHoverStyles.NONE]: "",
  [ButtonHoverStyles.SOLID]:
    "hover:bg-primary hover:text-light hover:ring-2 hover:ring-primary duration-200",
  [ButtonHoverStyles.PLAIN]:
    "hover:text-primary hover:underline hover:bg-white hover:ring-2 hover:ring-light duration-200",
  [ButtonHoverStyles.OUTLINED]:
    "hover:bg-light hover:ring-2 hover:ring-primary hover:text-primary duration-200",
  [ButtonHoverStyles.SOFT]:
    "hover:bg-secondary hover:ring-2 hover:ring-secondary hover:text-primary duration-200",
  [ButtonHoverStyles.TRANSITION]:
    "hover:-translate-y-1 duration-200 hover:shadow-lg",
};

export const getButtonStyles = (btn: ButtonStoryblok): string => {
  const typeCss = buttonStylesByTypes[btn.type];
  const sizeCss = buttonStylesBySizes[btn.size];
  const hoverCss = buttonStylesByHover[btn.hoverStyle];

  return classNames(
    "font-semibold cursor-pointer rounded-full",
    {
      [typeCss]: Boolean(btn.type),
      [sizeCss]: Boolean(btn.size),
      [hoverCss]: Boolean(btn.hoverStyle),
      "w-full": btn?.isFullWidth,
    },
    btn.customStyles
  );
};

export const getDefaultButtonStyles = (isLoading: boolean = false): string =>
  `min-w-[100px] min-h-[50px] font-semibold cursor-pointer rounded-full bg-primary ring-2 ring-primary text-light px-7 text-sm duration-300 hover:bg-secondary-darker hover:text-primary hover:ring-secondary-darker disabled:bg-neutral disabled:ring-neutral disabled:text-dark-grey ${
    isLoading ? "cursor-wait" : "disabled:cursor-not-allowed"
  }`;

export const getDefaultSoftButtonStyles = (): string =>
  "min-w-[100px] min-h-[50px] font-semibold cursor-pointer rounded-full bg-secondary text-primary ring-2 ring-secondary px-5 text-sm hover:bg-light hover:ring-primary hover:text-primary duration-200 disabled:bg-neutral disabled:ring-neutral disabled:text-dark-grey disabled:cursor-not-allowed";

export const getDefaultOutlinedButtonStyles = (): string =>
  "min-w-[100px] min-h-[50px] font-semibold cursor-pointer rounded-full bg-none ring-2 ring-primary text-primary px-5 text-sm disabled:bg-neutral disabled:ring-neutral disabled:text-dark-grey disabled:cursor-not-allowed";

// Tags frame
const tagsFrameStylesByAlignments: Record<string, string> = {
  [Alignment.LEFT]: "justify-start text-left",
  [Alignment.CENTER]: "justify-center text-center",
  [Alignment.RIGHT]: "justify-end text-right",
  [Alignment.BETWEEN]: "justify-between",
};

const tagsFrameStylesByDisplays: Record<string, string> = {
  [Displays.BLOCK]: "block",
  [Displays.FLEX]: "flex flex-wrap",
};

export const getTagsFrameStyles = (frame: TagsFrameStoryblok): string => {
  const alignCss = tagsFrameStylesByAlignments[frame.align];
  const displayCss = tagsFrameStylesByDisplays[frame.display];

  return classNames({
    [alignCss]: Boolean(frame.align),
    [displayCss]: Boolean(frame.display),
    "border rounded-3xl border-primary": frame.isBorder,
  });
};

// Heading
const HeadingStylesByLevels: Record<string, string> = {
  [HeadingLevels.H1]:
    "text-30 leading-10 md:text-40 md:leading-48 xl:text-58 xl:leading-61",
  [HeadingLevels.H2]:
    "text-24 leading-9 md:text-30 md:leading-10 xl:text-40 xl:leading-48",
  [HeadingLevels.H3]:
    "text-18 leading-6 md:text-24 md:leading-9 xl:text-30 xl:leading-10",
  [HeadingLevels.H4]:
    "text-14 leading-7 md:text-18 md:leading-6 xl:text-24 xl:leading-9",
  [HeadingLevels.H5]: "text-14 leading-7 md:text-18 md:leading-6",
  [HeadingLevels.H6]: "text-14 leading-7",
};

export const StylesByAlignments: Record<string, string> = {
  [Alignment.LEFT]: "md:text-left",
  [Alignment.CENTER]: "md:text-center",
  [Alignment.RIGHT]: "md:text-right",
};

export const mobileStylesByAlignments: Record<string, string> = {
  [Alignment.LEFT]: "text-left",
  [Alignment.CENTER]: "text-center",
  [Alignment.RIGHT]: "text-right",
};

export const getHeadingStyles = (
  heading: HeadingStoryblok | RichTextHeadingStoryblok,
  width?: number
): string => {
  const levelCss = HeadingStylesByLevels[heading.level];
  const alignCss = StylesByAlignments[heading.align ?? ""];
  const mobileAlignCss = mobileStylesByAlignments[heading.mobileAlign ?? ""];
  const isMobile = width && width < ScreenBreakpoints.MD;

  return classNames({
    [levelCss]: Boolean(heading.level),
    [alignCss]: Boolean(heading.align),
    [mobileAlignCss]: Boolean(heading.mobileAlign) && isMobile,
  });
};

export const getBannerTextAlignment = (type: string | undefined): string => {
  if (!type) return "";

  const bannerTextAlignment: Record<string, string> = {
    [Alignment.LEFT]: "justify-start",
    [Alignment.CENTER]: "text-center",
    [Alignment.RIGHT]: "justify-end",
  };

  return bannerTextAlignment[type] || "";
};

export const specializationsCombinedValue: SelectionOption[] = [
  {
    label: "Addiction",
    value: "addictionOrDifficultyWithSubstanceAbuse,alcoholAndOtherDrugs",
  },
  {
    label: "Adjustment difficulties",
    value: "adjustmentDisorder",
  },
  {
    label: "Anger management",
    value: "angerManagement",
  },
  {
    label: "Antisocial personality disorder (ASPD)",
    value: "personalityDisorder,dissociativeDisorders",
  },
  {
    label: "Anxiety",
    value: "anxiety",
  },
  {
    label: "Attention deficit hyperactivity disorder (ADHD)",
    value: "attentionDeficitHyperactivityDisorder",
  },
  {
    label: "Autism",
    value: "autism",
  },
  {
    label: "Bipolar",
    value: "bipolarAffectiveDisorder",
  },
  {
    label: "Body dysmorphia",
    value: "bodyDysmorphia",
  },
  {
    label: "Borderline personality",
    value: "borderlinePersonalityDisorder",
  },
  {
    label: "Burn out",
    value: "burnout",
  },
  {
    label: "Career",
    value: "career,workplaceRelations",
  },
  {
    label: "Chronic pain",
    value: "chronicPain,tinnitus",
  },
  {
    label: "Depression and mood",
    value: "depressionAndMood,downOrDepressed,lowMood",
  },
  {
    label: "Dissociative disorders",
    value: "dissociativeDisorders",
  },
  {
    label: "Domestic violence",
    value: "domesticViolence,violence",
  },
  {
    label: "Drug and alcohol",
    value: "alcoholAndOtherDrugs",
  },
  {
    label: "Dyslexia",
    value: "dyslexia",
  },
  {
    label: "Eating disorder",
    value: "eatingDisorders",
  },
  {
    label: "Family",
    value: "family",
  },
  {
    label: "Fertility and perinatal",
    value: "fertilityAPerinatal,infantSleep",
  },
  {
    label: "Financial stress",
    value: "financialStress",
  },
  {
    label: "Gambling addiction",
    value: "gamblingAddiction",
  },
  {
    label: "Gender and sexual identity",
    value: "genderAndSexualIdentity",
  },
  {
    label: "Grief and bereavement",
    value: "griefAndBereavement",
  },
  {
    label: "Health and lifestyle",
    value: "healthAndLifestyle",
  },
  {
    label: "Infant sleep counselling",
    value: "infantSleep,newParent",
  },
  {
    label: "Life transition",
    value: "lifeTransition",
  },
  {
    label: "Loneliness",
    value: "loneliness",
  },
  {
    label: "Meaning and value",
    value: "meaningValue",
  },
  {
    label: "Narcissism",
    value: "narcissism",
  },
  {
    label: "New parent",
    value: "newParent,parentTherapy",
  },
  {
    label: "Obsessive compulsive disorder (OCD)",
    value: "ocd",
  },
  {
    label: "Pain management",
    value: "painManagement",
  },
  {
    label: "Panic disorder",
    value: "panicAttacks",
  },
  {
    label: "Parent therapy",
    value: "parentTherapy,newParent",
  },
  {
    label: "Perfectionism",
    value: "perfectionism",
  },
  {
    label: "Perinatal and postnatal depression",
    value: "perinatalAndPostnatalDepressionAndAnxiety",
  },
  {
    label: "Personality disorder",
    value: "personalityDisorder",
  },
  {
    label: "Phobias",
    value: "phobias",
  },
  {
    label: "Post traumatic stress disorder (PTSD) and chronic PTSD (CPTSD)",
    value: "ptsd",
  },
  {
    label: "Relationship counselling and therapy",
    value: "relationshipCounselling,difficultyInMyRelationship",
  },
  {
    label: "Risky behaviours",
    value: "riskyBehaviours",
  },
  {
    label: "Self development",
    value: "selfDevelopment,communication",
  },
  {
    label: "Self esteem",
    value: "selfEsteem,confidence",
  },
  {
    label: "Sexual abuse",
    value: "sexualAbuse",
  },
  {
    label: "Sexual health",
    value: "sexualHealth,pornographySexualDifficulties",
  },
  {
    label: "Sleep",
    value: "sleepInsomnia,troubleSleeping",
  },
  {
    label: "Smoke addiction",
    value: "smokingCessation",
  },
  {
    label: "Social anxiety disorder (SAD)",
    value: "socialAnxiety",
  },
  {
    label: "Stress management",
    value: "stressManagement,stressAtWorkOrSchool",
  },
  {
    label: "Trauma",
    value: "traumaAndPtsd,traumaticExperience,ptsd",
  },
  {
    label: "Weight management",
    value: "weightManagement",
  },
  {
    label: "Work stress",
    value: "workStress,workplaceIncidentTherapy",
  },
];

export const specializationsSingleValue: Record<string, string> = {
  addictionOrDifficultyWithSubstanceAbuse: "Addiction",
  adjustmentDisorder: "Adjustment disorder",
  alcoholAndOtherDrugs: "Alcohol and other drugs",
  angerManagement: "Anger management",
  anxiety: "Anxiety",
  "personalityDisorder,riskyBehaviours":
    "Antisocial personality disorder (ASPD)",
  attentionDeficitHyperactivityDisorder:
    "Attention deficit hyperactivity disorder",
  autism: "Autism",
  bipolarAffectiveDisorder: "Bipolar affective disorder",
  bodyDysmorphia: "Body dysmorphia",
  borderlinePersonalityDisorder: "Borderline personality disorder",
  burnout: "Burnout",
  career: "Career",
  chronicPain: "Chronic pain",
  communication: "Communication",
  confidence: "Confidence",
  depressionAndMood: "Depression and mood",
  dissociativeDisorders: "Dissociative disorders",
  domesticViolence: "Domestic violence",
  dyslexia: "Dyslexia",
  eatingDisorders: "Eating disorders",
  family: "Family",
  fertilityAPerinatal: "Fertility a perinatal",
  financialStress: "Financial stress",
  gamblingAddiction: "Gambling addiction",
  genderAndSexualIdentity: "Gender and sexual identity",
  griefAndBereavement: "Grief and bereavement",
  healthAndLifestyle: "Health and lifestyle",
  infantSleep: "Infant sleep",
  lifeTransition: "Life transition",
  loneliness: "Loneliness",
  lowMood: "Low mood",
  meaningValue: "Meaning value",
  narcissism: "Narcissism",
  newParent: "New parent",
  ocd: "OCD",
  painManagement: "Pain management",
  panicAttacks: "Panic attacks",
  parentTherapy: "Parent therapy",
  perfectionism: "Perfectionism",
  performance: "Performance",
  perinatalAndPostnatalDepressionAndAnxiety:
    "Perinatal and postnatal depression and anxiety",
  personalityDisorder: "Personality disorder",
  phobias: "Phobias",
  pornographySexualDifficulties: "Pornography sexual difficulties",
  ptsd: "PTSD",
  relationshipCounselling: "Relationship counselling",
  riskyBehaviours: "Risky behaviours",
  selfDevelopment: "Self development",
  selfEsteem: "Self esteem",
  sexualAbuse: "Sexual abuse",
  sexualHealth: "Sexual health",
  sleepInsomnia: "Sleep insomnia",
  smokingCessation: "Smoking cessation",
  socialAnxiety: "Social anxiety",
  stressManagement: "Stress management",
  tinnitus: "Tinnitus",
  traumaAndPtsd: "Trauma and PTSD",
  violence: "Violence",
  weightManagement: "Weight management",
  workplaceIncidentTherapy: "Workplace incident therapy",
  workplaceRelations: "Workplace relations",
  workStress: "Work stress",
};

export const blogCategories: SelectionOption[] = [
  {
    label: "Addiction",
    value: "addiction",
  },
  {
    label: "Anxiety",
    value: "anxiety",
  },
  {
    label: "Depression and mood",
    value: "depressionAndMood",
  },
  {
    label: "Eating or body",
    value: "eatingOrBody",
  },
  {
    label: "Gender and sexual identity",
    value: "genderAndSexualIdentity",
  },
  {
    label: "Health and lifestyle",
    value: "healthAndLifestyle",
  },
  {
    label: "Life transition",
    value: "lifeTransition",
  },
  {
    label: "Mental health",
    value: "mentalHealth",
  },
  {
    label: "Neurodevelopmental disorders",
    value: "neurodevelopmentalDisorders",
  },
  {
    label: "New parent",
    value: "newParent",
  },
  {
    label: "Relationships",
    value: "relationships",
  },
  {
    label: "Stress or irritation",
    value: "stressOrIrritation",
  },
  {
    label: "Trauma",
    value: "trauma",
  },
];

export const languages: Record<string, string> = {
  en: "English",
  es: "Spanish",
  vi: "Vietnamese",
  fr: "French (Standard)",
  it: "Italian (Standard)",
  sr: "Serbian",
  bs: "Bosnian",
  hr: "Croatian",
  fa: "Persian/Farsi",
  af: "Afrikaans",
  pt: "Portuguese",
  ta: "Tamil",
  de: "German (Standard)",
  ko: "Korean",
  bn: "Bengali",
  zh: "Chinese (Mandarin)",
};

const statItemAlignment: Record<string, string> = {
  [Alignment.LEFT]: "justify-start items-start text-left",
  [Alignment.CENTER]: "justify-center items-center text-center",
  [Alignment.RIGHT]: "justify-end items-end text-right",
};

export const getStatItemAlignment = (type: string | undefined): string => {
  if (!type) return "";

  return statItemAlignment[type] || "";
};

export const getLinkStyle = (link: LinkStoryblok): string => {
  return classNames("cursor-pointer", {
    [link.level]: Boolean(link.level),
    [link.underline]: Boolean(link.underline),
    [`text-${link.color}`]: Boolean(link.color),
  });
};

const statLayoutMode: Record<string, string> = {
  [Layout.COLUMN]: "grid-cols-2",
  [Layout.ROW]:
    "grid-flow-row grid-cols-2 md:grid-cols-3 lg:grid-cols-none lg:grid-flow-col lg:grid-rows-1",
};

export const getStatLayoutMode = (type: string | undefined): string => {
  if (!type) return "";

  return statLayoutMode[type] || "";
};

export const defaultStyles: StylesConfig<
  SelectionOption,
  boolean,
  GroupBase<SelectionOption>
> = {
  container: (base) => ({
    ...base,
    width: "100%",
    minHeight: "3rem",
  }),
  control: (base) => ({
    ...base,
    borderColor: "transparent",
    boxShadow: "0 0 0 1px #e3e3e3",
    ":hover": {
      borderColor: "#DDCCD8",
      boxShadow: "0 0 0 1px #DDCCD8",
    },
    "*": {
      outline: "none !important",
      boxShadow: "none !important",
    },
  }),
  menu: (base) => ({
    ...base,
    marginTop: "0.3rem",
  }),
  menuList: (base) => ({
    ...base,
    overflowX: "hidden",
  }),
  input: (base) => ({
    ...base,
    color: "#000000",
    maxWidth: "20.5rem",
  }),
  placeholder: (base) => ({
    ...base,
    textOverflow: "ellipsis",
    overflow: "hidden",
    whiteSpace: "nowrap",
    color: "#cccccc",
  }),
  valueContainer: (base) => ({
    ...base,
    padding: 0,
    paddingLeft: "0.8rem",
    marginTop: 0,
    color: "#000000",
    height: "3rem",
    overflowY: "auto",
  }),
  option: (base) => ({
    ...base,
    cursor: "pointer",
    color: "#502334",
    backgroundColor: "unset",
    ":hover": {
      backgroundColor: "#DDCCD8",
    },
  }),
  indicatorsContainer: (base) => ({
    ...base,
    color: "#DDCCD8",
    ":hover": {
      color: "#502334",
    },
  }),

  //  Apply for multiple options
  multiValue: (base) => ({
    ...base,
    backgroundColor: "#DDCCD8",
    borderRadius: "5px",
  }),
  multiValueLabel: (base) => ({
    ...base,
    color: "#502334",
  }),
  multiValueRemove: (base) => ({
    ...base,
    borderTopRightRadius: "5px",
    borderBottomRightRadius: "5px",
  }),
};

const passwordBarStyles: Record<number, string> = {
  0: "bg-strength-weak duration-1000",
  1: "bg-strength-fair duration-1000",
  2: "bg-strength-good duration-1000",
  3: "bg-strength-strong duration-1000",
};

export const getPasswordBarStyles = (
  index: number,
  strength: number
): string => {
  if (index > strength) return "bg-neutral";

  return passwordBarStyles[strength];
};

export const linkStyles =
  "text-primary underline hover:text-secondary-darker hover:underline focus:outline-none focus:ring-2 focus:ring-primary focus:ring-opacity-50";

export const stepperStyles =
  "flex flex-col max-lg:items-center gap-4 lg:grid lg:grid-cols-2";

export const disabledCss = "cursor-not-allowed opacity-40 pointer-events-none";
