import { yupResolver } from "@hookform/resolvers/yup";
import { StoryblokComponent } from "@storyblok/react";
import { TextInput } from "components/forms/TextInput";

import { useAuth0 } from "@auth0/auth0-react";
import { SpinnerLoading } from "assets/icons/SpinnerLoading";
import { PhoneValidatorInput } from "components/forms/shared/PhoneValidatorInput";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { useToggle } from "react-use";
import { Routes } from "routes/main.routes";
import {
  postEmergencyContactAsync,
  processReserveWithPaymentAsync,
} from "services/booking.service";
import { useProfileStore } from "stores/profile.store";
import { FinaliseBookingContainerStoryblok } from "types/component-types-sb";
import { Props } from "types/core";
import { cn, getDefaultButtonStyles, redirectTo } from "utils";
import {
  isEmergencyContactExisted,
  populateFinaliseBookingFormData,
  reclaimReserveAppointment,
} from "utils/booking.util";
import { customToast } from "utils/toast.util";
import { TextError } from "../TextError";
import { renderConfirmBookingRichtextNotice } from "./booking-finalise.util";
import { getDefaultConfirmBookingFormResolver } from "./booking.resolver";

export type DefaultConfirmBookingFormData = {
  emergencyContactName: string;
  emergencyContactPhoneNumber: string;
};

type ConfirmBookingFormProps = Props<FinaliseBookingContainerStoryblok> & {
  reserveId: string;
};

export const ConfirmBookingDefaultForm = ({
  blok,
  reserveId,
}: ConfirmBookingFormProps) => {
  const { isAuthenticated, getAccessTokenSilently } = useAuth0();
  const profile = useProfileStore((state) => state.profile);

  const formContext = useForm<DefaultConfirmBookingFormData>({
    mode: "onChange",
    defaultValues: populateFinaliseBookingFormData(profile),
    resolver: yupResolver(getDefaultConfirmBookingFormResolver()),
  });

  const {
    control,
    handleSubmit,
    formState: { isValid, errors },
  } = formContext;

  const [isLoading, toggleIsLoading] = useToggle(false);

  const handleFormSubmitAsync = async (data: DefaultConfirmBookingFormData) => {
    if (!reserveId) return;

    toggleIsLoading(true);

    try {
      const accessToken = isAuthenticated
        ? await getAccessTokenSilently()
        : undefined;

      const isDuplicatedEmergencyContact = isEmergencyContactExisted({
        profile: profile,
        firstName: data.emergencyContactName,
        mobileNumber: data.emergencyContactPhoneNumber,
      });

      if (!isDuplicatedEmergencyContact) {
        await postEmergencyContactAsync(
          {
            firstName: data.emergencyContactName,
            mobileNumber: data.emergencyContactPhoneNumber,
          },
          accessToken
        );
      }

      // await claimReserveAppointmentAsync(reserveId, accessToken);
      await reclaimReserveAppointment(reserveId, accessToken);

      if (isAuthenticated && Boolean(profile.hasSavedCard)) {
        await processReserveWithPaymentAsync(reserveId, accessToken);

        redirectTo(Routes.BOOKING_THANK_YOU);
      } else {
        const { data: processResponse } = await processReserveWithPaymentAsync(
          reserveId,
          accessToken
        );

        if (processResponse?.checkoutUrl) {
          redirectTo(processResponse.checkoutUrl);
        }
      }

      customToast.success("Appointment claimed successfully");
    } catch (error) {
      customToast.error("Failed to claim appointment. Please try again later");
    } finally {
      toggleIsLoading(false);
    }
  };

  return (
    <FormProvider {...formContext}>
      <form onSubmit={handleSubmit(handleFormSubmitAsync)}>
        {/* Emergency contact heading */}
        <div className="flex items-center justify-between gap-3 mb-5 lg:mb-3">
          {blok?.emergencyContactTitle &&
            blok.emergencyContactTitle.map((blok) => (
              <StoryblokComponent key={blok._uid} blok={blok} />
            ))}

          {blok?.emergencyContactHint &&
            blok.emergencyContactHint.map((blok) => (
              <StoryblokComponent key={blok._uid} blok={blok} />
            ))}
        </div>

        {/* Emergency contact form */}
        <div className="flex flex-col gap-3 mb-10 lg:flex-row lg:gap-7">
          <div className="mb-2 lg:flex-1">
            {/* Name */}
            <div className="mb-2">
              <Controller
                name="emergencyContactName"
                control={control}
                render={({ field }) => (
                  <TextInput
                    {...field}
                    name={field.name}
                    title={"Name"}
                    onChangeValue={field.onChange}
                  />
                )}
              />
            </div>

            <TextError fieldError={errors.emergencyContactName} />
          </div>
          <div className="mb-2 lg:flex-1 space-y-1">
            {/* Phone number */}
            <Controller
              name="emergencyContactPhoneNumber"
              control={control}
              render={({ field }) => <PhoneValidatorInput field={field} />}
            />
          </div>
        </div>

        {/* Notice */}
        <div className="mb-12">{renderConfirmBookingRichtextNotice(blok)}</div>

        {/* Submit button */}
        <div className="flex flex-col items-center gap-5 md:flex-row">
          <button
            type="submit"
            disabled={isLoading || !isValid}
            className={cn(getDefaultButtonStyles(isLoading))}
          >
            Proceed to payment details to finalise your booking
          </button>

          {isLoading && (
            <SpinnerLoading className="hidden w-12 h-12 md:block" />
          )}
        </div>
      </form>
    </FormProvider>
  );
};
