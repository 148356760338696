import { getHintFromStoryBlok } from "components/forms/book-medicare/BookMedicareForm";
import { DateInput } from "components/forms/DateInput";
import { InputMedicareNumberValidator } from "components/forms/shared/InputMedicareNumberValidator";
import { TextError } from "components/forms/TextError";
import { TextInput } from "components/forms/TextInput";
import { BookBehalfOfType } from "enums";
import { useMedicareAutosave } from "hooks/useMedicareAutosave";
import { Controller, useFormContext } from "react-hook-form";
import { BookDvaFormStoryblok } from "types/component-types-sb";

type Props = {
  blok: BookDvaFormStoryblok;
};

export const DvaWhiteCardMedicareFields = ({ blok }: Props) => {
  const { validationState } = useMedicareAutosave({
    behalfOfType: BookBehalfOfType.MY_SELF,
    medicareFieldName: "medicareNumber",
  });

  const {
    control,
    formState: { errors },
  } = useFormContext();

  return (
    <div className="flex flex-col gap-y-3">
      <div>
        {/* MEDICARE CARD IMAGE */}
        <img src={blok.medicareImage?.filename} alt={blok.medicareImageAlt} />
      </div>

      <div className="flex flex-col flex-1 gap-y-1">
        <Controller
          name="medicareNumber"
          control={control}
          render={({ field }) => (
            <InputMedicareNumberValidator
              field={field}
              label="Medicare card number"
              hintElement={getHintFromStoryBlok(blok.medicareNumberHints)}
              validationState={validationState}
            />
          )}
        />
      </div>

      <div className="flex flex-col items-start justify-center w-full lg:flex-row gap-x-5 gap-y-3">
        <Controller
          name="irnNumber"
          control={control}
          render={({ field }) => (
            <div className="flex flex-1 lg:min-w-[275px] flex-col w-full">
              <TextInput
                {...field}
                title="Your individual reference (IRN)"
                onChangeValue={field.onChange}
                hintElement={getHintFromStoryBlok(blok.medicareIrnHints)}
                numberOnly
              />

              <TextError fieldError={errors.irnNumber} />
            </div>
          )}
        />

        <Controller
          name="expiryDate"
          control={control}
          render={({ field }) => (
            <div className="flex flex-col flex-1 w-full">
              <DateInput
                title="Expiry date"
                type="month"
                selectedDate={field.value ? new Date(field.value) : undefined}
                hintComponent={getHintFromStoryBlok(blok.medicareExpiryHints)}
                onChangeValue={field.onChange}
                showMonthYearPicker
                minDate={new Date()}
                isShowHint
              />

              <TextError fieldError={errors.expiryDate} />
            </div>
          )}
        />
      </div>
    </div>
  );
};
