import { isNil } from "lodash";
import { PropsWithChildren } from "react";
import { Link, LinkProps } from "react-router-dom";

type Props = {
  disabled?: boolean;
  to?: string | undefined;
  target?: string | undefined;
} & PropsWithChildren &
  LinkProps;

export const LinkWrapper = ({
  children,
  to,
  target,
  disabled = false,
  ...props
}: Props) => {
  const shouldReturnLink = !disabled && !isNil(to);

  return shouldReturnLink ? (
    <Link to={to} target={target} rel="preconnect" reloadDocument {...props}>
      {children}
    </Link>
  ) : (
    <>{children}</>
  );
};
