import { AxiosResponse, CancelTokenSource } from "axios";
import { globalConfig } from "core/config";
import { closestTo } from "date-fns";
import {
  AvailableTimeSelectionType,
  BackendBinary,
  ManageRadioType,
} from "enums";

import {
  AppointmentType,
  AppointmentTypesResponse,
  AttachedClinician,
  CaseLoad,
  HelmControl,
  MinifiedClinician,
  NextAvailability,
  Paging,
  ProfileSettings,
  PsychologistRequestQueries,
  PsychologistResponseMedicare,
  TimeSlots,
  WorkingSchedule,
} from "models";
import { Profile } from "models/client.model";
import {
  ClinicianApiClient,
  ScheduleApiClient,
  getSessionAuthTokenOrDefault,
} from "services/axios.service";

export type PsychologistResponse = {
  _id: string;
  index?: number;
  specialisations: string[];
  consultantLocations: string[];
  status: string;
  caseLoad: CaseLoad;
  accountId: string;
  avatar: string;
  title: string;
  name: string;
  email: string;
  slugUrl: string;
  createdAt: Date;
  updatedAt: Date;
  gender: string;
  helmControl: HelmControl;
  isProfileListed: boolean;
  medicare: PsychologistResponseMedicare;
  profileSettings: ProfileSettings;
  pronouns: string;
  workTimeZone: string;
  workingSchedule: WorkingSchedule;
  nextAvailabilities: NextAvailability[];
  hasMatchedNextAvailabilities: boolean;
  matchedNextAvailabilities: NextAvailability[];
  bio: string;
  oldWorkingSchedule: WorkingSchedule;
  appointmentAllocations?: AppointmentAllocation[];
};

export type AppointmentAllocation = {
  allocations?: Allocation[];
  /**
   * Week start date
   */
  weekStartDate?: string;
  /**
   * Working hours for the week
   */
  workingHours?: number;
};

export type Allocation = {
  /**
   * Appointment count per appointment type for the week
   */
  appointmentCount?: number;
  /**
   * Availability in percentage or session
   */
  availability?: number;
  /**
   * Total hours of appointment booked
   */
  bookedHours?: number;
  /**
   * Allocations for different categories (funding methods)
   */
  category?: FundingCategory;
  /**
   * Indicates if a psychologist has availability, returns true if there is a minimum of one
   * hour of availability.
   */
  isAvailable?: boolean;
  /**
   * Indicates allocations type
   */
  type?: AllocationType;
  /**
   * Allocated percentage or session
   */
  value?: string;
};

export type FundingCategory =
  | "rebate"
  | "bulkBill"
  | "ndis"
  | "workCover"
  | "outOfPocket";

export type AllocationType = "percentage" | "fixed";

export const AvailableTimes: Record<string, string[]> = {
  // [startTime, endTime]
  [AvailableTimeSelectionType.MORNING]: ["08:00", "12:00"],
  [AvailableTimeSelectionType.AFTERNOON]: ["12:00", "18:00"],
  [AvailableTimeSelectionType.EVENING]: ["18:00", "24:00"],
};

export type CliniciansResponse = {
  clinicians: PsychologistResponse[];
  paging: Paging;
};

export type GetPsychologistAppointmentParams = {
  clinicianId: string;
  claimType: string;
};

export type GetPsychologistAvailableAppointmentParams = {
  appointmentTypeId: string;
  clinicianId: string;
  date: string;
  to: string;
  type: string;
};

export type PsychologistAvailableAppointmentResponse = {
  appointmentType: AppointmentType;
  timeSlots: TimeSlots;
};

export const getPsychologistByIdAsync = async (
  id: string
): Promise<AxiosResponse<PsychologistResponse>> => {
  return ClinicianApiClient.get<PsychologistResponse>(
    `/accounts/someone-health/clinicians/${id}`,
    {
      params: {
        showAllocation: BackendBinary.TRUE,
      },
    }
  );
};

export const getPsychologistAvailabilitiesAsync = async (
  params: GetPsychologistAvailableAppointmentParams,
  cancelToken?: CancelTokenSource
): Promise<AxiosResponse<PsychologistAvailableAppointmentResponse>> => {
  return ScheduleApiClient.get<PsychologistAvailableAppointmentResponse>(
    `/appointment-types/${params.appointmentTypeId}/availabilities/public/`,
    {
      params: {
        accountId: globalConfig.ACCOUNT_ID,
        clinicianId: params.clinicianId,
        date: params.date,
        to: params.to,
        type: params.type,
      },
      cancelToken: cancelToken?.token,
    }
  );
};

export const getPsychologistAppointmentsAsync = async (
  params: GetPsychologistAppointmentParams
): Promise<AxiosResponse<AppointmentTypesResponse[]>> => {
  return ScheduleApiClient.get<AppointmentTypesResponse[]>(
    "/availability/appointment-types/public",
    {
      params: {
        accountId: globalConfig.ACCOUNT_ID,
        clinicianId: params.clinicianId,
        claimType: params.claimType,
      },
    }
  );
};

export const getCliniciansAsync = async (
  params: PsychologistRequestQueries,
  cancelToken?: CancelTokenSource
): Promise<AxiosResponse<CliniciansResponse>> => {
  return ClinicianApiClient.get<CliniciansResponse>(
    "/accounts/someone-health/clinicians",
    { params, cancelToken: cancelToken?.token }
  );
};

export const getAvailableTime = (availableTimes: string[]): string[] => {
  return availableTimes.reduce(
    (timing: string[], time: string) => timing.concat(AvailableTimes[time]),
    []
  );
};

export type GetMinifiedCliniciansParams = {
  excludeGP: number;
  includeUnlistedProfiles: number;
  status: string;
};

export type MinifiedCliniciansResponse = {
  clinicians: MinifiedClinician[];
  paging: Paging;
};

export const getMinifiedCliniciansAsync = async (
  params: GetMinifiedCliniciansParams
): Promise<AxiosResponse<MinifiedCliniciansResponse>> => {
  return ClinicianApiClient.get<MinifiedCliniciansResponse>(
    "/accounts/someone-health/clinicians/minified",
    {
      params: {
        excludeGP: params.excludeGP,
        includeUnlistedProfiles: params.includeUnlistedProfiles,
        status: params.status,
      },
    }
  );
};

export const getNextAvailableAppointment = (
  nextAvailabilities: NextAvailability[]
): Date | undefined => {
  const nearestDays = nextAvailabilities?.flatMap((appointment) =>
    appointment?.availabilities.map(({ date }) => date)
  );

  if (!nearestDays) return new Date();

  return closestTo(new Date(), nearestDays);
};

export type UploadFileParams = {
  file: File;
};

export type UploadFileResponse = {
  bucketName: string;
  fileName: string;
  fileUrl: string;
};

export const uploadFileAsync = async (
  params: UploadFileParams,
  accessToken: string | undefined
): Promise<AxiosResponse<UploadFileResponse>> => {
  const formData = new FormData();
  const imageFile = params.file;

  formData.append("file", imageFile);

  return ClinicianApiClient.post<UploadFileResponse>(
    "/patientUpload/encrypted",
    formData,
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
      ...getSessionAuthTokenOrDefault(accessToken),
    }
  );
};

export type UpdateReferralParams = {
  isReferredByGP: boolean;
  isHaveTreatmentPlan: boolean;

  files?: UploadFileResponse[];
  isGPSentReferral?: boolean;
  addToReferralGroup?: boolean;
};

export const updateReferralAsync = async (
  params: UpdateReferralParams,
  accessToken?: string
) => {
  return ClinicianApiClient.put(
    "/client-records/me/referral",
    {
      files: params.files,
      isReferredByGP: params.isHaveTreatmentPlan,
      isHaveTreatmentPlan: params.isHaveTreatmentPlan,
      isGPSentReferral: params.isGPSentReferral,
      addToReferralGroup: params.addToReferralGroup,
    },
    getSessionAuthTokenOrDefault(accessToken)
  );
};

export type UpdateMedicareParams = {
  dateOfBirth: string;
  firstName: string;
  lastName: string;
  number?: number;
  expiryDate?: string;
  irn?: number;
  dva?: string;
  dvaCardDetails?: DvaCardDetails;
  shouldRejectInvalidDetails?: boolean;
  parent?: Parent;
};

export type DvaCardDetails = {
  type: string;
  expiryDate?: string;
};

export type Parent = {
  dateOfBirth: string;
  firstName: string;
  lastName: string;
  irn: number;
};

export const validateMedicareAsync = async (
  params: UpdateMedicareParams,
  accessToken?: string
) => {
  return ClinicianApiClient.put(
    "/client-records/me/medicare",
    {
      dateOfBirth: params.dateOfBirth,
      firstName: params.firstName,
      lastName: params.lastName,
      number: params.number,
      expiryDate: params.expiryDate,
      irn: params.irn,
      dva: params.dva,
      dvaCardDetails: params.dvaCardDetails,
      shouldRejectInvalidDetails: params.shouldRejectInvalidDetails,
      parent: params.parent,
    },
    getSessionAuthTokenOrDefault(accessToken)
  );
};

export type UpdateNDISParams = {
  plan: ManageRadioType;
  number: string;
};

export const validateNDISAsync = async (
  params: UpdateNDISParams,
  accessToken?: string
) => {
  return ClinicianApiClient.put(
    "/client-records/me/ndis",
    {
      plan: params.plan,
      number: params.number,
    },
    getSessionAuthTokenOrDefault(accessToken)
  );
};

export type UpdateWorkCoverParams = {
  claimNumber: string;
  numberOfAppointmentApproved: number;
  caseManagerDetails: string;
  approved: string;
};

export const putWorkCoverCardAsync = async (
  params: UpdateWorkCoverParams,
  accessToken?: string
) => {
  return ClinicianApiClient.put(
    "/client-records/me/work-cover",
    {
      claimNumber: params.claimNumber,
      numberOfAppointmentApproved: params.numberOfAppointmentApproved,
      caseManagerDetails: params.caseManagerDetails,
      approved: params.approved,
    },
    getSessionAuthTokenOrDefault(accessToken)
  );
};

export const getClientRecordAsync = async (accessToken?: string) => {
  return ClinicianApiClient.get<Profile>(
    "/client-records/me",
    getSessionAuthTokenOrDefault(accessToken)
  );
};

export const getAttachedClinicianAsync = async (accessToken?: string) => {
  return ClinicianApiClient.get<AttachedClinician>(
    "/client-records/me:getAttachedAccountAndClinician",
    getSessionAuthTokenOrDefault(accessToken)
  );
};

export const getDecryptedProfileAsync = async (psychologistId: string) => {
  return ClinicianApiClient.get(
    `/me/client-records/${psychologistId}:getDecrypted`
  );
};
