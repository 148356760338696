import {
  CheckpointStoryblok,
  HeadingStoryblok,
  PsychologistDetailPracticeStoryblok,
} from "@/types/component-types-sb";
import { Props } from "@/types/core";
import { StoryblokComponent, storyblokEditable } from "@storyblok/react";
import { renderPsychologistDisplayRole } from "components/forms/book-finalise/booking-finalise.util";
import { MedicareRole, ScrollAnchorIds } from "enums";
import { useBreakpoint } from "hooks/useBreakpoint";
import { compact, isEmpty } from "lodash";
import { useState } from "react";
import { useEffectOnce } from "react-use";

import { PsychologistResponse } from "services/psychologist.service";
import { languages } from "utils";
import { CheckpointCarousel } from "./CheckpointCarousel";
import { Separator } from "./Separator";
import { SmoothScrollButton } from "./SmoothScrollButton";

type CustomProps = Props<PsychologistDetailPracticeStoryblok> & {
  psychologist: PsychologistResponse;
};

export const PsychologistDetailPractice = ({
  blok,
  psychologist,
}: CustomProps) => {
  const breakpoint = useBreakpoint();
  const isMobileView = breakpoint.isMobile;

  const { helmControl } = psychologist || {};

  const headingBlok = {
    content: blok.title,
    level: "h3",
    fontWeight: 400,
    align: "left",
    component: "heading",
  } as HeadingStoryblok;

  const [practices, setPractices] = useState<string[]>([]);

  useEffectOnce(() => {
    getPractices();
  });

  const getPractices = () => {
    const practiceList: string[] = [];
    const yearsOfExperience = helmControl?.bonaFides?.yearsOfExperience?.value;
    const medicareRole = psychologist?.medicare?.role as MedicareRole;
    const medicareRoleDisplay = renderPsychologistDisplayRole(medicareRole);

    if (!yearsOfExperience) {
      practiceList.push(medicareRoleDisplay);
    } else {
      practiceList.push(
        medicareRoleDisplay
          ? `${medicareRoleDisplay} for ${yearsOfExperience} ${
              yearsOfExperience >= 2 ? "years" : "year"
            }`
          : `${yearsOfExperience} ${
              yearsOfExperience >= 2 ? "years" : "year"
            } experience`
      );
    }

    const qualifications = helmControl?.bonaFides.qualifications.value;

    if (!isEmpty(qualifications)) {
      practiceList.push(...qualifications);
    }

    const languageList = helmControl?.bonaFides.languages.value.map(
      (language) => languages?.[language] || "Unknown"
    );

    if (isEmpty(helmControl?.bonaFides.languages.value)) {
      setPractices(compact(practiceList));

      return;
    }

    if (helmControl?.bonaFides.languages.value.length > 1) {
      practiceList.push(
        `${languageList.slice(0, -1).join(", ")}, and ${languageList.slice(-1)}`
      );
    } else {
      practiceList.push(languageList[0]);
    }

    setPractices(compact(practiceList));
  };

  return (
    <div
      {...storyblokEditable(blok)}
      className="flex flex-col items-start justify-center md:gap-5 w-full"
    >
      <StoryblokComponent blok={headingBlok} />

      {isMobileView ? (
        <CheckpointCarousel
          checkpointItems={practices}
          iconBlok={blok.icon}
          iconAlt={blok.icon_alt}
        />
      ) : (
        <div className="flex flex-col gap-5">
          {practices?.map((practice, index) => {
            const checkpointBlok = {
              icon: blok.icon,
              text: practice,
              icon_alt: blok.icon_alt,
              component: "checkpoint",
            } as CheckpointStoryblok;

            return <StoryblokComponent key={index} blok={checkpointBlok} />;
          })}
        </div>
      )}

      {isMobileView && (
        <>
          <Separator className="mb-[1.25rem]" />
          <SmoothScrollButton
            label="Book now"
            targetId={ScrollAnchorIds.PSYCHOLOGIST_DETAIL_FUNCTIONS}
            customClasses="w-[90%] mx-auto"
          />
        </>
      )}
    </div>
  );
};
