import { get, isEmpty } from "lodash";
import { Settings } from "react-slick";
import { match } from "ts-pattern";

import { DEFAULT_APPOINTMENT_TYPE } from "core/booking.constant";
import { globalConfig } from "core/config";
import {
  AvailabilityTimeFilterType,
  AvailableTimeSelectionType,
  BookBehalfOfType,
  ConsultPreferenceType,
  HeadingLevels,
  MedicareRole,
  NextAvailableSelectionType,
  PreferredDaySelectionType,
  PsychologistGenderSelectionType,
  ScreenBreakpoints,
  StateType,
  TimeZoneType,
  TreatmentStyleSelectionType,
} from "enums";
import {
  AppointmentTypesResponse,
  Funding,
  OurPsychologistsFilterType,
  OurPsychologistsSearchType,
  SelectionOption,
  TimeSlot,
} from "models";
import { Profile, RecordType } from "models/client.model";
import { getSessionStoreData } from "stores/session.store";
import { HeadingStoryblok } from "types/component-types-sb";
import {
  getSignupRecordType,
  getStoredAppointment,
  getStoredFundingMethod,
} from "utils/storage.util";
import {
  compareTimesHHmm,
  isTimezoneDST
} from "./date.util";
import { cn, languages } from "./styles.util";

export const getTestimonialsCarouselSettings = () => {
  return {
    dots: false,
    arrows: false,
    infinite: true,
    speed: 1000,
    autoplay: true,
    autoplaySpeed: 3000,
    pauseOnHover: true,
    slidesToShow: 6,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: ScreenBreakpoints.SM,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: ScreenBreakpoints.MD,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: ScreenBreakpoints.XL,
        settings: {
          slidesToShow: 4,
        },
      },
      {
        breakpoint: ScreenBreakpoints.XXL,
        settings: {
          slidesToShow: 5,
        },
      },
    ],
  };
};

const generateSettings = (
  numOfCards: number,
  slidesToShow: number
): Settings => {
  const isNumOfCardsGreaterThanSlidesToShow = numOfCards > slidesToShow;

  return {
    slidesToShow: Math.min(numOfCards, slidesToShow),
    slidesToScroll: Math.min(numOfCards, slidesToShow),
    infinite: isNumOfCardsGreaterThanSlidesToShow,
    autoplay: isNumOfCardsGreaterThanSlidesToShow,
    draggable: isNumOfCardsGreaterThanSlidesToShow,
    autoplaySpeed: 5000,
    className: cn({
      "display-arrow": isNumOfCardsGreaterThanSlidesToShow,
      "card-center": !isNumOfCardsGreaterThanSlidesToShow && slidesToShow > 1,
    }),
    arrows: isNumOfCardsGreaterThanSlidesToShow,
  };
};

export const getPsychologistCardCarouselSettings = (
  numOfCards: number
): Settings => {
  if (numOfCards === 0) return {};

  return {
    ...generateSettings(numOfCards, 5),
    dots: false,
    autoplaySpeed: 10000,
    speed: 2000,
    pauseOnHover: true,
    responsive: [
      {
        breakpoint: ScreenBreakpoints.SM,
        settings: generateSettings(numOfCards, 1),
      },
      {
        breakpoint: ScreenBreakpoints.LG,
        settings: generateSettings(numOfCards, 2),
      },
      {
        breakpoint: ScreenBreakpoints.XL,
        settings: generateSettings(numOfCards, 3),
      },
      {
        breakpoint: ScreenBreakpoints.XXL,
        settings: generateSettings(numOfCards, 4),
      },
    ],
  };
};

export const consultMethodList: SelectionOption[] = [
  {
    value: ConsultPreferenceType.PHONE_CALL,
    label: "Phone consult",
  },
  {
    value: ConsultPreferenceType.VIDEO_CALL,
    label: "Video consult",
  },
];

export const PsychologistListingRoles = [
  {
    label: "Clinical Psychologist",
    value: MedicareRole.ClinicalPsychologists,
  },
  {
    label: "Registered Psychologist",
    value: MedicareRole.RegisteredPsychologists,
  },
  {
    label: "Occupational Therapist",
    value: MedicareRole.OccupationalTherapists,
  },
  {
    label: "Social Worker",
    value: MedicareRole.SocialWorkers,
  },
  {
    label: "General Practitioner",
    value: MedicareRole.GeneralPractitioner,
  },
];

const getMorningTimeSlots = (timeSlots: TimeSlot[]): TimeSlot[] => {
  return timeSlots.filter(
    (timeSlot) => compareTimesHHmm(timeSlot.startTime, "12:00") === -1
  );
};

const getAfternoonTimeSlots = (timeSlots: TimeSlot[]): TimeSlot[] => {
  const afternoonTimeSlots = timeSlots.filter(
    (timeSlots) =>
      (compareTimesHHmm(timeSlots.startTime, "12:00") === 0 ||
        compareTimesHHmm(timeSlots.startTime, "12:00") === 1) &&
      (compareTimesHHmm(timeSlots.endTime, "18:00") === -1 ||
        compareTimesHHmm(timeSlots.endTime, "18:00") === 0)
  );

  return afternoonTimeSlots;
};

const getEveningTimeSlots = (timeSlots: TimeSlot[]): TimeSlot[] => {
  const eveningTimeSlots = timeSlots.filter(
    (timeSlots) =>
      compareTimesHHmm(timeSlots.startTime, "18:00") === 0 ||
      compareTimesHHmm(timeSlots.startTime, "18:00") === 1
  );

  return eveningTimeSlots;
};

export const getFilteredTimeSlotsByTimeOfDay = (
  timeSlots: TimeSlot[],
  timeOfDay: AvailabilityTimeFilterType
): TimeSlot[] => {
  const timeSlotResult: TimeSlot[] = match(timeOfDay)
    .with(AvailabilityTimeFilterType.ALL, () => timeSlots)
    .with(AvailabilityTimeFilterType.MORNING, () =>
      getMorningTimeSlots(timeSlots)
    )
    .with(AvailabilityTimeFilterType.AFTERNOON, () =>
      getAfternoonTimeSlots(timeSlots)
    )
    .with(AvailabilityTimeFilterType.EVENING, () =>
      getEveningTimeSlots(timeSlots)
    )
    .otherwise(() => []);

  return timeSlotResult;
};

export const psychologistAppointmentsToSelectionOptions = (
  appointments: AppointmentTypesResponse[]
): SelectionOption[] => {
  return appointments
    .filter(({ assignedClinicians }) => !isEmpty(assignedClinicians))
    .map((appointment) => {
      return {
        label: appointment.name,
        value: appointment._id,
      };
    });
};

export const getLanguageOptions = (): SelectionOption[] => {
  return Object.entries(languages)
    .map(([key, value]) => ({ label: value, value: key }))
    .sort((a, b) => a.label.localeCompare(b.label));
};

export const fundingMethodOptions: (SelectionOption & { value: Funding })[] = [
  {
    label: "Medicare rebate",
    value: Funding.REBATE,
  },
  {
    label: "Medicare bulk bill",
    value: Funding.BULK_BILL,
  },
  {
    label: "Self fund",
    value: Funding.SELF_FUNDED,
  },
  {
    label: "NDIS",
    value: Funding.NDIS,
  },
  {
    label: "WorkCover",
    value: Funding.WORK_COVER,
  },
  {
    label: "Veterans (DVA)",
    value: Funding.DVA,
  },
];

export const nextAvailableOptions: SelectionOption[] = [
  {
    label: "Available today",
    value: NextAvailableSelectionType.TODAY,
  },
  {
    label: "Available tomorrow",
    value: NextAvailableSelectionType.TOMORROW,
  },
  {
    label: "Available in next 7 days",
    value: NextAvailableSelectionType.IN_NEXT_7_DAYS,
  },
  {
    label: "Available in next 2 weeks",
    value: NextAvailableSelectionType.IN_NEXT_2_WEEKS,
  },
];

export const preferredDayOptions: SelectionOption[] = [
  {
    label: "Monday",
    value: PreferredDaySelectionType.MONDAY,
  },
  {
    label: "Tuesday",
    value: PreferredDaySelectionType.TUESDAY,
  },
  {
    label: "Wednesday",
    value: PreferredDaySelectionType.WEDNESDAY,
  },
  {
    label: "Thursday",
    value: PreferredDaySelectionType.THURSDAY,
  },
  {
    label: "Friday",
    value: PreferredDaySelectionType.FRIDAY,
  },
  {
    label: "Saturday",
    value: PreferredDaySelectionType.SATURDAY,
  },
  {
    label: "Sunday",
    value: PreferredDaySelectionType.SUNDAY,
  },
];

export const availableTimeOptions: SelectionOption[] = [
  {
    label: "Morning",
    value: AvailableTimeSelectionType.MORNING,
  },
  {
    label: "Afternoon",
    value: AvailableTimeSelectionType.AFTERNOON,
  },
  {
    label: "Evening",
    value: AvailableTimeSelectionType.EVENING,
  },
];

export const psychologistGenderOptions: SelectionOption[] = [
  {
    label: "Female",
    value: PsychologistGenderSelectionType.WOMAN,
  },
  {
    label: "Male",
    value: PsychologistGenderSelectionType.MAN,
  },
];

export const treatmentStyleOptions: SelectionOption[] = [
  {
    label: "Emotion-focused therapy",
    value: TreatmentStyleSelectionType.EMOTION_FOCUSED_THERAPY,
  },
  {
    label: "Interpersonal psychotherapy",
    value: TreatmentStyleSelectionType.INTERPERSONAL_PSYCHOTHERAPY,
  },
  {
    label: "Mindfulness",
    value: TreatmentStyleSelectionType.MINDFULNESS,
  },
  {
    label: "Cognitive Behavioural Therapy (CBT)",
    value: TreatmentStyleSelectionType.COGNITIVE_BEHAVIOURAL_THERAPY_CBT,
  },
  {
    label: "Narrative therapy",
    value: TreatmentStyleSelectionType.NARRATIVE_THERAPY,
  },
  {
    label: "Positive psychology",
    value: TreatmentStyleSelectionType.POSITIVE_PSYCHOLOGY,
  },
  {
    label: "Psychodynamic therapy",
    value: TreatmentStyleSelectionType.PSYCHODYNAMIC_THERAPY,
  },
  {
    label: "Solution-focused brief therapy",
    value: TreatmentStyleSelectionType.SOLUTION_FOCUSED_BRIEF_THERAPY,
  },
  {
    label: "Acceptance and Commitment Therapy (ACT)",
    value: TreatmentStyleSelectionType.ACCEPTANCE_AND_COMMITMENT_THERAPY_ACT,
  },
  {
    label: "Dialectical behaviour therapy",
    value: TreatmentStyleSelectionType.DIALECTICAL_BEHAVIOUR_THERAPY,
  },
  {
    label: "Attachment-based therapy",
    value: TreatmentStyleSelectionType.ATTACHMENT_BASED_THERAPY,
  },
  {
    label: "Coaching",
    value: TreatmentStyleSelectionType.COACHING,
  },
  {
    label: "Motivational interviewing",
    value: TreatmentStyleSelectionType.MOTIVATIONAL_INTERVIEWING,
  },
  {
    label: "Person-centred therapy",
    value: TreatmentStyleSelectionType.PERSON_CENTRED_THERAPY,
  },
  {
    label: "Schema therapy",
    value: TreatmentStyleSelectionType.SCHEMA_THERAPY,
  },
];

export const defaultOurPsychologistsFilter: OurPsychologistsFilterType = {
  nextAvailable: "",
  preferredDays: [],
  availableTimes: [],
  psychologistGender: "",
  treatmentStyles: [],
  languagesSpoken: "",
  isDoPhoneConsults: false,
  isDoTreatTeens: false,
};

export const defaultOurPsychologistsSearch: OurPsychologistsSearchType = {
  funding: DEFAULT_APPOINTMENT_TYPE,
  helpWithList: [],
  particularPsychologist: "",
};

export const getSingleSelectedOption = (
  options: SelectionOption[],
  value?: string
): SelectionOption | undefined => {
  if (!value) return undefined;

  return options.find((option) => option.value === value);
};

export const getMultiSelectedOptions = (
  options: SelectionOption[],
  values: string[]
): SelectionOption[] | undefined => {
  return options.filter((option) => values.includes(option.value));
};

export const safeUndefined = (value: string): string | undefined => {
  if (isEmpty(value)) {
    return undefined;
  }

  return value;
};

export const joinQuery = (values: string[]): string | undefined => {
  const result = values.join(",");

  return isEmpty(result) ? undefined : result;
};

export const safeBoolUndefined = (value: boolean): boolean | undefined => {
  if (typeof value !== "boolean") {
    return undefined;
  }

  return value;
};

export const extract = <T, K extends keyof T>(items: T[], key: K): T[K][] => {
  return items.map((item) => item[key]);
};

export const validateFileType = (
  filename: string,
  validTypes: string[]
): boolean => {
  return validTypes.some((validType) => filename.endsWith(validType));
};

export const defaultStateOptions: SelectionOption[] = [
  {
    label: "NSW",
    value: StateType.NSW,
  },
  {
    label: "VIC",
    value: StateType.VIC,
  },
  {
    label: "QLD",
    value: StateType.QLD,
  },
  {
    label: "WA",
    value: StateType.WA,
  },
  {
    label: "SA",
    value: StateType.SA,
  },
  {
    label: "TAS",
    value: StateType.TAS,
  },
  {
    label: "ACT",
    value: StateType.ACT,
  },
  {
    label: "NT",
    value: StateType.NT,
  },
];
const myselfOption = {
  label: "Myself",
  value: BookBehalfOfType.MY_SELF,
};

const someoneElseAdultOption = {
  label: "Someone else (Adult)",
  value: BookBehalfOfType.SOMEONE_ELSE_ADULT,
};

const myChildOption = {
  label: "My child (13-17 years)",
  value: BookBehalfOfType.MY_CHILD,
};

export const getDefaultBehalfOfOptions = (
  profile: Profile | null | undefined
): SelectionOption[] => {
  const recordType = Boolean(getSessionStoreData()?.isAuthenticated)
    ? profile?.recordType
    : getSignupRecordType();

  return match(recordType as RecordType)
    .with(RecordType.ADULT, () => [myselfOption, someoneElseAdultOption])
    .with(RecordType.YOUNG_PERSON, () => [myselfOption, myChildOption])
    .otherwise(() => [myselfOption, someoneElseAdultOption, myChildOption]);
};

export const getFormHeadingBlok = (
  title: string | undefined
): HeadingStoryblok =>
  ({
    content: title,
    level: HeadingLevels.H1,
    fontWeight: "font-medium",
    align: "left",
    component: "heading",
  } as HeadingStoryblok);

export const howItWorksOptions: SelectionOption[] = [
  { label: "Medicare rebate", value: "medicareRebate" },
  { label: "Medicare bulk bill", value: "medicareBulkBill" },
  { label: "Self fund", value: "selfFund" },
  { label: "WorkCover", value: "workCover" },
  { label: "NDIS", value: "ndis" },
  { label: "Veterans (DVA)", value: "dva" },
];

type LabelledTimeZone = Record<
  string,
  {
    dstCode: string;
    nonDstCode: string;
    desc: string;
    shortDesc: string;
    nonDstHr: string;
    dstHr: string;
    label: string;
  }
>;

export const auLabelledTimeZone: LabelledTimeZone = {
  "Australia/Sydney": {
    nonDstCode: "AEST",
    dstCode: "AEDT",
    desc: "NSW, VIC & ACT / Sydney, Melbourne & Canberra",
    shortDesc: "NSW, VIC & ACT",
    nonDstHr: "10",
    dstHr: "11",
    label: "New South Wales/Sydney",
  },
  "Australia/Melbourne": {
    nonDstCode: "AEST",
    dstCode: "AEDT",
    desc: "NSW, VIC & ACT / Sydney, Melbourne & Canberra",
    shortDesc: "NSW, VIC & ACT",
    nonDstHr: "10",
    dstHr: "11",
    label: "Victoria/Melbourne",
  },
  "Australia/Brisbane": {
    nonDstCode: "AEST",
    dstCode: "AEST",
    desc: "Queensland/Brisbane",
    shortDesc: "Queensland",
    nonDstHr: "10",
    dstHr: "10",
    label: "Queensland/Brisbane",
  },
  "Australia/Queensland": {
    nonDstCode: "AEST",
    dstCode: "AEST",
    desc: "Queensland/Brisbane",
    shortDesc: "Queensland",
    nonDstHr: "10",
    dstHr: "10",
    label: "Queensland/Brisbane",
  },
  "Australia/Adelaide": {
    nonDstCode: "ACST",
    dstCode: "ACDT",
    desc: "South Australia/Adelaide",
    shortDesc: "South Australia",
    nonDstHr: "9.5",
    dstHr: "10.5",
    label: "South Australia/Adelaide",
  },
  "Australia/Darwin": {
    nonDstCode: "ACST",
    dstCode: "ACST",
    desc: "Northern Territory/Darwin",
    shortDesc: "Northern Territory",
    nonDstHr: "9.5",
    dstHr: "9.5",
    label: "Northern Territory/Darwin",
  },
  "Australia/Perth": {
    nonDstCode: "AWST",
    dstCode: "AWST",
    desc: "Western Australia/Perth",
    shortDesc: "Western Australia",
    nonDstHr: "8",
    dstHr: "8",
    label: "Western Australia/Perth",
  },
  "Pacific/Norfolk": {
    nonDstCode: "NFT",
    dstCode: "NFDT",
    desc: "Norfolk Island/Kingston",
    shortDesc: "Norfolk Island",
    nonDstHr: "11",
    dstHr: "12",
    label: "Norfolk Island/Kingston",
  },
  "Indian/Christmas": {
    nonDstCode: "CXT",
    dstCode: "CXT",
    desc: "Christmas Island",
    shortDesc: "Christmas Island",
    nonDstHr: "7",
    dstHr: "7",
    label: "Christmas Island",
  },
  "Indian/Cocos": {
    nonDstCode: "CCT",
    dstCode: "CCT",
    desc: "Cocos Islands",
    shortDesc: "Christmas Island",
    nonDstHr: "6.5",
    dstHr: "6.5",
    label: "Cocos Islands",
  },
};

const toTimeZoneLabel = (timeZone: TimeZoneType) => {
  const getTimeZoneLabel = get(auLabelledTimeZone, timeZone);

  if (!getTimeZoneLabel) return timeZone;

  // const isDst = isDaylightSavingTime(timeZone);
  const isDst = isTimezoneDST(timeZone);

  const dstCode = isDst
    ? getTimeZoneLabel.dstCode
    : getTimeZoneLabel.nonDstCode;

  const dstHr = isDst ? getTimeZoneLabel.dstHr : getTimeZoneLabel.nonDstHr;

  return `${getTimeZoneLabel.label} (${dstCode}) (GMT+${dstHr})`;
};

export const timeZoneOptions: SelectionOption[] = [
  {
    value: TimeZoneType.AUSTRALIA_BRISBANE,
    label: toTimeZoneLabel(TimeZoneType.AUSTRALIA_BRISBANE),
  },
  {
    value: TimeZoneType.AUSTRALIA_MELBOURNE,
    label: toTimeZoneLabel(TimeZoneType.AUSTRALIA_MELBOURNE),
  },
  {
    value: TimeZoneType.AUSTRALIA_SYDNEY,
    label: toTimeZoneLabel(TimeZoneType.AUSTRALIA_SYDNEY),
  },
  {
    value: TimeZoneType.AUSTRALIA_ADELAIDE,
    label: toTimeZoneLabel(TimeZoneType.AUSTRALIA_ADELAIDE),
  },
  {
    value: TimeZoneType.AUSTRALIA_DARWIN,
    label: toTimeZoneLabel(TimeZoneType.AUSTRALIA_DARWIN),
  },
  {
    value: TimeZoneType.AUSTRALIA_PERTH,
    label: toTimeZoneLabel(TimeZoneType.AUSTRALIA_PERTH),
  },
  {
    value: TimeZoneType.PACIFIC_NORFOLK,
    label: toTimeZoneLabel(TimeZoneType.PACIFIC_NORFOLK),
  },
  {
    value: TimeZoneType.INDIAN_CHRISTMAS,
    label: toTimeZoneLabel(TimeZoneType.INDIAN_CHRISTMAS),
  },
  {
    value: TimeZoneType.INDIAN_COCOS,
    label: toTimeZoneLabel(TimeZoneType.INDIAN_COCOS),
  },
];

export const fundingMethodDisplayOptions: SelectionOption[] = [
  {
    label: "Bulk bill",
    value: Funding.BULK_BILL,
  },
  {
    label: "Rebate",
    value: Funding.REBATE,
  },
  {
    label: "Self fund",
    value: Funding.SELF_FUNDED,
  },
  {
    label: "NDIS",
    value: Funding.NDIS,
  },
  {
    label: "WorkCover",
    value: Funding.WORK_COVER,
  },
  {
    label: "DVA",
    value: Funding.DVA,
  },
];

export const psychologistRoleDisplayOptions: SelectionOption[] = [
  {
    label: "Clinical psychologist",
    value: MedicareRole.ClinicalPsychologists,
  },
  {
    label: "General psychologist",
    value: MedicareRole.RegisteredPsychologists,
  },
];

export const pronounsDisplayOptions: SelectionOption[] = [
  { label: "He / Him", value: "heHim" },
  { label: "She / Her", value: "sheHer" },
  { label: "They / Them", value: "theyThem" },
];

export const getGroupIdByFundingMethod = (): string | null => {
  const groupIds = globalConfig.GROUP_IDS;

  const fundingMethod = getStoredFundingMethod();
  const storedAppointment = getStoredAppointment();

  if ([groupIds, fundingMethod, storedAppointment].some(isEmpty)) return null;

  return match(fundingMethod)
    .with(Funding.BULK_BILL, () => groupIds.BULK_BILL)
    .with(Funding.REBATE, () => groupIds.REBATE)
    .with(Funding.SELF_FUNDED, () => groupIds.SELF_FUND)
    .with(Funding.DVA, () => groupIds.DVA)
    .with(Funding.NDIS, () =>
      Boolean(storedAppointment?.isNdisPlanManaged)
        ? groupIds.NDIS_PLAN_MANAGED
        : groupIds.NDIS_SELF_MANAGED
    )
    .with(Funding.WORK_COVER, () =>
      Boolean(storedAppointment?.isWorkCoverNsw)
        ? groupIds.WORKCOVER_NSW
        : groupIds.WORKCOVER
    )
    .otherwise(() => null);
};
