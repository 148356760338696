import { PsychologistResponse } from "@/services/psychologist.service";
import {
  BadgeStoryblok,
  PsychologistDetailBannerStoryblok,
} from "@/types/component-types-sb";
import { Props } from "@/types/core";
import { StoryblokComponent, storyblokEditable } from "@storyblok/react";
import { defaultTo } from "lodash";

import {
  renderPsychologistDisplayRole,
  renderPsychologistPronouns,
} from "components/forms/book-finalise/booking-finalise.util";
import { ScreenBreakpoints, ScrollAnchorIds } from "enums";
import { useBreakpoint } from "hooks/useBreakpoint";
import { useWindowSize } from "react-use";
import { cn, scrollSmoothById, specializationsSingleValue } from "utils";

type CustomProps = Props<PsychologistDetailBannerStoryblok> & {
  psychologist: PsychologistResponse;
};

export const PsychologistDetailBanner = ({
  blok,
  psychologist,
}: CustomProps) => {
  const breakpoint = useBreakpoint();

  const { width: windowWidth } = useWindowSize();

  const { helmControl } = psychologist || {};

  const handleClickViewProfile = () => {
    scrollSmoothById(ScrollAnchorIds.PSYCHOLOGIST_DETAIL_INFO);
  };

  const renderDesktopView = () => {
    return (
      <div className="flex bg-highlight rounded-30 w-full justify-between px-24 overflow-hidden">
        <div className="flex flex-1 flex-col justify-center text-primary gap-5 z-[2]">
          <div>
            <span className="font-medium">
              <span
                className="text-30 leading-10 md:text-40 md:leading-48 xl:text-58 xl:leading-61 table-caption -mb-3"
                title={psychologist.name}
              >
                {psychologist?.name}
              </span>
              <span>{renderPsychologistPronouns(psychologist)}</span>
            </span>
          </div>

          <div className="text-12 font-semibold tracking-wide uppercase">
            {renderPsychologistDisplayRole(psychologist?.medicare?.role)},
            SPECIALISING IN:
          </div>

          <div className="flex flex-wrap items-center justify-start gap-3">
            {helmControl?.primarySpecialisations?.map(
              (primarySpecialization, index) => {
                const badge = {
                  name: defaultTo(
                    specializationsSingleValue[primarySpecialization],
                    "Unknown"
                  ),
                  type: "plain",
                  size: "md",
                  isUpperCase: false,
                  component: "badge",
                } as BadgeStoryblok;

                return <StoryblokComponent key={index} blok={badge} />;
              }
            )}
          </div>
        </div>

        <div className="flex flex-1 relative justify-center">
          <div className="h-full scale-125 z-[1] absolute -mt-14 aspect-square clip-path__avatar-0 bg-neutral"></div>
          <img
            src={psychologist.avatar}
            alt="Avatar"
            className="z-[2] mt-7 h-[350px] bottom-0 self-end"
          />
        </div>
      </div>
    );
  };

  const renderMobileView = () => {
    return (
      <div className="flex bg-highlight flex-col px-5 pt-8 pb-5 md:px-8 md:pt-14 md:pb-8 gap-3 -mx-4 sm:-mx-6 md:-mx-20">
        {breakpoint.isMobile ? (
          <>
            <div className="flex flex-row gap-x-5">
              <div className="flex clip-path__avatar-0 max-h-[130px] max-w-[130px] sm:max-h-[200px] sm:max-w-[200px] bg-neutral">
                <img
                  src={psychologist.avatar}
                  alt="Avatar"
                  className="w-full"
                />
              </div>

              <div className="flex flex-col gap-y-2 justify-start">
                <span
                  className="text-30 text-primary font-medium leading-10 md:text-40 md:leading-48 xl:text-58 xl:leading-61 line-clamp-3"
                  title={psychologist.name}
                >
                  {psychologist.name}
                </span>

                <span className="text-12 text-primary font-medium">
                  {renderPsychologistPronouns(psychologist)}
                </span>

                {/* Role */}
                <div
                  className={cn(
                    "font-semibold text-primary text-12 md:text-lg text-start mb-1.5 uppercase tracking-wider leading-5"
                  )}
                >
                  {renderPsychologistDisplayRole(psychologist?.medicare?.role)}
                </div>
              </div>
            </div>

            <div className="flex flex-col justify-center items-center">
              {blok.back_buttons?.map((backButton) => (
                <StoryblokComponent key={backButton._uid} blok={backButton} />
              ))}

              <button
                className="cursor-pointer text-primary opacity-90 text-15 font-medium hover:underline"
                onClick={handleClickViewProfile}
              >
                View profile
              </button>
            </div>
          </>
        ) : (
          <>
            <div className="flex gap-x-3 text-primary">
              <div className="flex clip-path__avatar-0 max-h-[250px] max-w-[250px] bg-neutral">
                <img
                  src={psychologist.avatar}
                  alt="Avatar"
                  className="w-full"
                />
              </div>

              <div className="mt-3">
                <span
                  className="text-30 font-medium leading-10 md:text-40 md:leading-48 xl:text-58 xl:leading-61 line-clamp-3 pb-1"
                  title={psychologist.name}
                >
                  {psychologist.name}
                </span>

                <span className="text-12 font-medium">
                  {renderPsychologistPronouns(psychologist)}
                </span>
              </div>
            </div>

            <div className="flex">
              <div className="flex flex-1 flex-col justify-center text-primary gap-2">
                <div className="font-semibold tracking-wide text-12 mb-2">
                  {blok.title}
                </div>

                <div className="flex flex-wrap items-center justify-start gap-3">
                  {helmControl?.primarySpecialisations?.map(
                    (primarySpecialization, index) => {
                      const badge = {
                        name: defaultTo(
                          specializationsSingleValue[primarySpecialization],
                          "Unknown"
                        ),
                        type: "plain",
                        size: "md",
                        isUpperCase: false,
                        component: "badge",
                      } as BadgeStoryblok;

                      return <StoryblokComponent key={index} blok={badge} />;
                    }
                  )}
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    );
  };

  return (
    <div
      {...storyblokEditable(blok)}
      className="flex flex-col w-full tracking-wide"
    >
      {windowWidth >= ScreenBreakpoints.LG
        ? renderDesktopView()
        : renderMobileView()}
    </div>
  );
};
