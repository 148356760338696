import {
  defaultTo,
  first,
  get,
  isEmpty,
  isEqual,
  isNil,
  orderBy,
} from "lodash";
import { match } from "ts-pattern";

import {
  DEFAULT_APPOINTMENT_TYPE,
  DEFAULT_CLAIM_TYPE,
  DEFAULT_CLINICIAN_PSYCHOLOGIST_COST,
  DEFAULT_GENERAL_PSYCHOLOGIST_COST,
  UNKNOWN_COST,
} from "core/booking.constant";
import { SAFE_LINK_REGEX } from "core/regex.constant";
import {
  BookBehalfOfType,
  ScrollAnchorIds,
  GTM_DataLayer_Types,
  MedicareRole,
  PageAlias,
  SignUpFormType,
  TimeZoneType,
  UserRole,
} from "enums";
import {
  AvailabilityTimeSlot,
  EnvName,
  Funding,
  GtmPayload,
  NextAvailability,
} from "models";
import { Profile } from "models/client.model";
import { Routes } from "routes/main.routes";
import {
  KEY_TACKLIT_ATTACHED_CLINICIAN,
  KEY_TACKLIT_ROLES,
} from "services/auth.service";
import { AppointmentHistoryResponse } from "services/booking.service";
import {
  AppointmentAllocation,
  FundingCategory,
} from "services/psychologist.service";
import { getSessionStoreData } from "stores/session.store";
import { MultilinkStoryblok } from "types/component-types-sb";
import {
  fundingMethodDisplayOptions,
  isFutureDate,
  isLessThan2Hours,
  isLessThan48Hours,
} from "utils";
import {
  getStoredAppointmentRate,
  getStoredFundingMethod,
  getStoredReserveData,
} from "utils/storage.util";
import { User } from "@auth0/auth0-react";

export const toMinute = (time: number) => {
  if (isNaN(time) || time < 0) return 0;

  return Math.floor(time / 60);
};

export const toSecond = (time: number) => {
  if (isNaN(time) || time < 0) return 0;

  return time % 60;
};

export const toSafeLink = (rawLink: string | undefined): string => {
  const link = safeTrailingSlash(rawLink);

  if (!link) return Routes.PLACEHOLDER;

  if (SAFE_LINK_REGEX.test(link)) {
    return link;
  }

  return `/${link}`;
};

export const toNoCachedLink = (link?: MultilinkStoryblok): string => {
  if (!link) return "";

  if (isEqual(link.linktype, "story") || isEqual(link.linktype, "url")) {
    return defaultTo(link.story?.url, link.cached_url);
  }

  return link.cached_url;
};

export const redirectTo = (url?: string, target?: string) => {
  if (!url) {
    window.location.assign(Routes.PLACEHOLDER);

    return;
  }

  if (!Boolean(target)) {
    window.location.assign(url);

    return;
  }

  window.open(url, target);
};

export const openInNewTab = (url: string) => {
  window.open(url, "_blank");
};

export const reloadDocument = () => {
  window.location.reload();
};

export const redirectToPsychologistDetails = (id?: string | null) => {
  if (!id) {
    redirectTo(Routes.OUR_PSYCHOLOGISTS);

    return;
  }

  redirectTo(toPsychologistDetailsPath(id));
};

export const toPsychologistDetailsPath = (id: string | undefined) => {
  if (!id) return "";

  return `${Routes.PSYCHOLOGIST_DETAILS}?id=${id}`;
};

export const toFullPsychologistDetailsPath = (id: string | undefined) => {
  if (!id) return "";

  return `${window.location.origin}${toPsychologistDetailsPath(id)}`;
};

export const removeUrlSearchParams = () => {
  const location = window.location;

  window.history.replaceState(
    {},
    "",
    location.href.replaceAll(location.search, "")
  );
};

export const toRequiredMessage = (fieldName: string): string =>
  `${fieldName} is required`;

export const toInvalidMessage = (fieldName: string): string =>
  `${fieldName} is invalid`;

export const validateBehalfOfMySelf = (behalfOf: BookBehalfOfType): boolean =>
  isEqual(behalfOf, BookBehalfOfType.MY_SELF);

export const validateBehalfOfSomeOne = (behalfOf: BookBehalfOfType): boolean =>
  isEqual(behalfOf, BookBehalfOfType.SOMEONE_ELSE_ADULT);

export const validateBehalfOfChild = (behalfOf: BookBehalfOfType): boolean =>
  isEqual(behalfOf, BookBehalfOfType.MY_CHILD);

export const formatDollar = (
  amount: number,
  minDigits?: number,
  maxDigits?: number
) => {
  return `$${amount.toLocaleString("en-US", {
    minimumFractionDigits: defaultTo(minDigits, 2),
    maximumFractionDigits: defaultTo(maxDigits, 2),
  })}`;
};

export const isValidFunding = (fundingMethodName: string): boolean => {
  return Object.values(Funding)
    .concat("Self Funded" as Funding) // Exception from API
    .map((funding) => funding.toLowerCase())
    .includes(fundingMethodName.toLowerCase() as Funding);
};

export const toClaimType = (funding: Funding | null): FundingCategory => {
  return match(funding)
    .with(Funding.REBATE, () => "rebate")
    .with(Funding.BULK_BILL, Funding.DVA, () => "bulkBill")
    .with(Funding.NDIS, () => "ndis")
    .with(Funding.WORK_COVER, () => "workCover")
    .with(Funding.SELF_FUNDED, () => "outOfPocket")
    .otherwise(() => DEFAULT_CLAIM_TYPE) as FundingCategory;
};

export const toFundingType = (claimType: string | undefined): Funding => {
  return match(claimType)
    .with("rebate", () => Funding.REBATE)
    .with("bulkBill", () => Funding.BULK_BILL)
    .with("ndis", () => Funding.NDIS)
    .with("workCover", () => Funding.WORK_COVER)
    .with("outOfPocket", () => Funding.SELF_FUNDED)
    .otherwise(() => DEFAULT_APPOINTMENT_TYPE) as Funding;
};

export const extractDisableWeekRanges = (
  appointmentAllocations: AppointmentAllocation[],
  funding = getStoredFundingMethod(),
  shouldFilterByFunding = true
): string[] => {
  if (!appointmentAllocations) return [];

  const fundingCategory: FundingCategory = toClaimType(funding);

  const disabledWeeks =
    appointmentAllocations?.filter((week) =>
      week?.allocations?.some((allocation) =>
        shouldFilterByFunding
          ? !allocation?.isAvailable && allocation?.category === fundingCategory
          : !allocation?.isAvailable
      )
    ) ?? [];

  const disabledWeekRanges: string[] = disabledWeeks
    .map((week) => week?.weekStartDate)
    .filter((startDate): startDate is string => startDate !== undefined);

  return disabledWeekRanges;
};

export const isExternalUrl = (url: string): boolean => {
  try {
    new URL(url);

    return true;
  } catch {
    return false;
  }
};

export const getNextTimeSlot = (
  nextAvailabilityDetails: NextAvailability[],
  isNewClient = true
): { date: Date; timeSlot: AvailabilityTimeSlot } | undefined => {
  const timeSlots = nextAvailabilityDetails
    .flatMap((detail) => detail.availabilities)
    .filter((availability) => availability?.timeSlots);

  const futureTimeSlots = timeSlots
    .flatMap((availability) => {
      return (
        availability.timeSlots
          // .filter((slot) => filterActiveSlots(slot, isNewClient))
          .map((timeSlot) => ({
            date: availability.date,
            timeSlot,
          }))
      );
    })
    .filter(({ timeSlot }) =>
      isFutureDate(timeSlot.startDateTime, TimeZoneType.AUSTRALIA_SYDNEY)
    );

  const nearestTimeSlot = orderBy(futureTimeSlots, "date", "asc");

  return first(nearestTimeSlot);
};

export const filterActiveSlots = (
  slot: AvailabilityTimeSlot,
  isNewClient: boolean
) => {
  return isNewClient
    ? !isLessThan48Hours(slot?.startDateTime)
    : !isLessThan2Hours(slot?.startDateTime);
};

export const getFundingMethodDisplayLabel = (paymentOption: string): string => {
  return defaultTo(
    fundingMethodDisplayOptions.find((method) =>
      isEqual(method.value, paymentOption)
    )?.label,
    paymentOption
  );
};

export const getAttachedPsychologistAuth0 = (
  user?: User
): string | undefined => {
  const userData = user || getSessionStoreData()?.user;

  if (isEmpty(userData)) return;

  const attachedPsychologistIds: string[] = get(
    userData,
    KEY_TACKLIT_ATTACHED_CLINICIAN
  );

  return first(attachedPsychologistIds);
};

export const getAttachedRoleAuth0 = (): string | undefined => {
  const userData = getSessionStoreData()?.user;

  if (isEmpty(userData)) return;

  const roles: string[] = get(userData, KEY_TACKLIT_ROLES);

  return first(roles);
};

export const isGpUser = (): boolean => {
  const psychologistId = getAttachedPsychologistAuth0();
  const userRole = getAttachedRoleAuth0();

  const isPatientOrEmptyRole =
    isEqual(userRole, UserRole.PATIENT) || isEmpty(userRole);

  return isEmpty(psychologistId) && isPatientOrEmptyRole;
};

export const isFilledMedicareCardGP = (
  profile: Profile | undefined
): boolean => {
  if (!profile) return false;

  const { medicare } = profile;

  const isHaveMedicareCard = !isNil(medicare);

  if (!isHaveMedicareCard) return false;

  const { firstName, lastName, dateOfBirth, number, irn, expiryDate } =
    medicare || {};

  const isMedicareCardFilled =
    ![firstName, lastName, dateOfBirth, expiryDate].some(isEmpty) &&
    ![number, irn].some(isNil);

  return isMedicareCardFilled;
};

export const isFilledAddressGP = (profile: Profile | undefined): boolean => {
  if (!profile) return false;

  const { address } = profile;

  const isHaveAddress = !isNil(address);

  if (!isHaveAddress) return false;

  const { line1, suburb, state, postcode } = address || {};

  const isAddressFilled = ![line1, suburb, state, postcode].some(isEmpty);

  return isAddressFilled;
};

export const isFilledRequiredFieldsGP = (profile?: Profile | null): boolean => {
  if (isNil(profile)) return false;

  const isHaveSavedCard = get(profile, "hasSavedCard", false);

  const isCompletedGPBooking =
    isFilledMedicareCardGP(profile) &&
    isFilledAddressGP(profile) &&
    isHaveSavedCard;

  return isCompletedGPBooking;
};

export const isRoleClinician = (): boolean => {
  const userRole = getAttachedRoleAuth0();

  return isEqual(userRole, UserRole.CLINICIAN);
};

export const isMatchedUrl = (url?: string): boolean => {
  const comparedLink = toSafeLink(url).replace(/\/$/, "");

  return window.location.pathname.includes(comparedLink);
};

export const getNextBookingNavigation = (): string => {
  const fundingMethod = getStoredFundingMethod();

  const bookingUrl = match(fundingMethod)
    .with(Funding.BULK_BILL, Funding.REBATE, () => Routes.BOOKING_MEDICARE)
    .with(Funding.NDIS, () => Routes.BOOKING_NDIS)
    .with(Funding.DVA, () => Routes.BOOKING_DVA)
    .with(Funding.WORK_COVER, () => Routes.BOOKING_WORKCOVER)
    .with(Funding.SELF_FUNDED, () => Routes.BOOKING_CONFIRM)
    .otherwise(() => Routes.BOOKING_MEDICARE);

  return bookingUrl;
};

export const isDetailsPage = (): boolean => {
  return window.location.pathname.startsWith(Routes.PSYCHOLOGIST_DETAILS);
};

export const isSignupInvitePage = (): boolean => {
  return isEqual(window.location.pathname, Routes.SIGNUP_INVITE);
};

export const isPsyUnavailablePage = (): boolean => {
  return isEqual(window.location.pathname, Routes.CLINICIAN_NOT_AVAILABLE);
};

export const isHomePage = (): boolean => {
  return isEqual(window.location.pathname, Routes.ROOT);
};

export const sortFunding = (fundingMethods: Funding[]): Funding[] => {
  if (!fundingMethods) return [];

  const ORDER = [
    Funding.REBATE,
    Funding.BULK_BILL,
    Funding.SELF_FUNDED,
    Funding.NDIS,
    Funding.WORK_COVER,
    Funding.DVA,
  ].map((funding) => funding.toLowerCase());

  return fundingMethods.sort(
    (a, b) => ORDER.indexOf(a.toLowerCase()) - ORDER.indexOf(b.toLowerCase())
  );
};

export const getLastBookedAppointmentTypeId = (
  appointment: AppointmentHistoryResponse
): string => {
  return get(appointment, "sessionTypeId");
};

export const getLastBookedConsult = (
  appointment: AppointmentHistoryResponse
): string | null => {
  return get(appointment, "deliveryType", null);
};

export const toPageAlias = (): PageAlias => {
  return match(window.location.pathname as Routes)
    .with(Routes.SIGNUP, Routes.SIGNUP_GP, () => PageAlias.SIGNUP)
    .with(
      Routes.BOOKING_DVA,
      Routes.BOOKING_MEDICARE,
      Routes.BOOKING_NDIS,
      Routes.BOOKING_WORKCOVER,
      () => PageAlias.FUNDING_BOOKING
    )
    .with(Routes.BOOKING_CONFIRM, () => PageAlias.CONFIRM)
    .otherwise(() => PageAlias.OTHER);
};

export const Duration = {
  millis(millis: number) {
    return millis;
  },
  seconds(seconds: number) {
    return seconds * this.millis(1000);
  },
  minutes(minutes: number) {
    return minutes * this.seconds(60);
  },
  hours(hours: number) {
    return hours * this.minutes(60);
  },
  days(days: number) {
    return days * this.hours(24);
  },
  weeks(weeks: number) {
    return weeks * this.days(7);
  },
  months(months: number) {
    return months * this.days(30);
  },
  years(years: number) {
    return years * this.days(365);
  },
};

export const getAppointmentRate = () => {
  const reserveAppointment = getStoredReserveData();
  const medicareRole = reserveAppointment?.psychologist?.medicare
    ?.role as MedicareRole;

  return match(medicareRole)
    .with(
      MedicareRole.ClinicalPsychologists,
      () => DEFAULT_CLINICIAN_PSYCHOLOGIST_COST
    )
    .with(
      MedicareRole.RegisteredPsychologists,
      () => DEFAULT_GENERAL_PSYCHOLOGIST_COST
    )
    .otherwise(() => getStoredAppointmentRate());
};

export const getAppointmentRateByMedicareRole = (
  medicareRole: MedicareRole
): number | string => {
  return match(medicareRole)
    .with(
      MedicareRole.ClinicalPsychologists,
      () => DEFAULT_CLINICIAN_PSYCHOLOGIST_COST
    )
    .with(
      MedicareRole.RegisteredPsychologists,
      () => DEFAULT_GENERAL_PSYCHOLOGIST_COST
    )
    .otherwise(() => UNKNOWN_COST);
};

export const safeTrailingSlash = (
  url: string | undefined
): string | undefined => {
  if (!url) return undefined;

  if (isEqual(url, "/")) return url;

  return url.endsWith("/") ? url.slice(0, -1) : url;
};

export const rolesAndAttachedClinicianIdExisted = (
  roles: string | undefined,
  clinicians: string | undefined
): boolean => !isEmpty(roles) && !isEmpty(clinicians);

export const gtmDataLayerType = (
  payloadFunding?: Funding
): GTM_DataLayer_Types | null => {
  const funding = payloadFunding || getStoredFundingMethod();

  return match(funding)
    .with(Funding.BULK_BILL, () => GTM_DataLayer_Types.BULK_BILL)
    .with(Funding.REBATE, () => GTM_DataLayer_Types.REBATE)
    .with(Funding.SELF_FUNDED, () => GTM_DataLayer_Types.SELF_FUND)
    .with(Funding.NDIS, () => GTM_DataLayer_Types.NDIS)
    .with(Funding.WORK_COVER, () => GTM_DataLayer_Types.WORK_COVER)
    .with(Funding.DVA, () => GTM_DataLayer_Types.DVA)
    .otherwise(() => null);
};

export const defaultEnvPayload = (): Partial<GtmPayload> => {
  return {
    environment: match<string, EnvName>(window.location.hostname)
      .with("localhost", () => "LOCAL")
      .with("preview.someone.health", () => "PREVIEW")
      .with("release.someone.health", () => "RELEASE")
      .with("sandbox.someone.health", () => "SANDBOX")
      .with("someone.health", () => "PROD")
      .otherwise(() => "UNKNOWN"),
    path: window.location.href,
  };
};

export const getSignUpFormType = (formTypeName?: string): SignUpFormType => {
  const formType = formTypeName as SignUpFormType;

  const isValid = Object.values(SignUpFormType).includes(formType);

  return isValid ? formType : SignUpFormType.FUNDING;
};

export const scrollSmoothById = (
  elementId: ScrollAnchorIds,
  verticalPosition: ScrollLogicalPosition = "start"
) => {
  const element = document.getElementById(elementId);

  if (!element) return;

  element.scrollIntoView({
    behavior: "smooth",
    block: verticalPosition,
  });
};

export const allTruly = (values: boolean[]): boolean => values.every(Boolean);

export const allFalsy = (values: boolean[]): boolean => values.every((v) => !v);

export const oneTruly = (values: boolean[]): boolean => values.some(Boolean);

export const oneFalsy = (values: boolean[]): boolean => values.some((v) => !v);
