import { TimeSlotItem } from "components/psychologist-detail/TimeSlotItem";
import { AvailabilityTimeFilterType, TimeZoneType } from "enums";
import { isEmpty } from "lodash";
import { TimeSlot } from "models";
import { useMemo } from "react";
import { cn, getFilteredTimeSlotsByTimeOfDay } from "utils";
import { v4 } from "uuid";

type Props = {
  slots: TimeSlot[];
  timeSlotSelected: TimeSlot | null | undefined;
  timeZoneSelected: TimeZoneType;

  onSelectTimeSlot: (timeSlot: TimeSlot) => void;
};

export const MobileTimeSlotAccordion = ({
  slots,
  timeSlotSelected,
  timeZoneSelected,
  ...props
}: Props) => {
  const collapseId = v4();

  const getSlotBy = (time: AvailabilityTimeFilterType) =>
    getFilteredTimeSlotsByTimeOfDay(slots, time);

  const morningSlots = getSlotBy(AvailabilityTimeFilterType.MORNING);
  const afternoonSlots = getSlotBy(AvailabilityTimeFilterType.AFTERNOON);
  const eveningSlots = getSlotBy(AvailabilityTimeFilterType.EVENING);

  const optionsMap = useMemo(() => {
    const resultMap = [];

    if (!isEmpty(morningSlots)) {
      resultMap.push({
        label: "Morning",
        slots: morningSlots,
      });
    }

    if (!isEmpty(afternoonSlots)) {
      resultMap.push({
        label: "Afternoon",
        slots: afternoonSlots,
      });
    }

    if (!isEmpty(eveningSlots)) {
      resultMap.push({
        label: "Evening",
        slots: eveningSlots,
      });
    }

    return resultMap;
  }, [morningSlots, afternoonSlots, eveningSlots]);

  return (
    <div className={cn("flex-col", "daisy-join daisy-join-vertical w-full")}>
      {optionsMap.map((option, optionIndex) => (
        <div
          key={optionIndex}
          className="daisy-collapse daisy-collapse-arrow daisy-join-item"
        >
          <input
            type="radio"
            className="w-full min-h-[40px]"
            name={collapseId}
          />

          <div className="daisy-collapse-title px-0 py-2 min-h-[unset] after:!top-[50%] after:!right-[6px]">
            {option.label}
          </div>

          <div className="daisy-collapse-content !p-0 !pb-[1px]">
            <div className="grid grid-cols-2 sm:grid-cols-2 lg:grid-cols-2 xl:grid-cols-2 gap-x-4 gap-y-3">
              {option.slots.map((timeSlot, slotIndex) => (
                <TimeSlotItem
                  key={slotIndex}
                  timeSlot={timeSlot}
                  timeSlotSelected={timeSlotSelected}
                  onSelectTimeSlot={props.onSelectTimeSlot}
                  timeZoneSelected={timeZoneSelected}
                />
              ))}
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};
