import { Props } from "@/types/core";
import { StoryblokComponent, storyblokEditable } from "@storyblok/react";
import { TreatmentStoryblok } from "types/component-types-sb";
import { cn, isHomePage } from "utils";

type CustomProp = Props<TreatmentStoryblok> & { hasDarkerBackground: boolean };

export const Treatment = ({
  blok,
  hasDarkerBackground = false,
}: CustomProp) => {
  return (
    <div
      {...storyblokEditable(blok)}
      className={cn(
        "flex flex-col p-[30px] h-full md:h-80 lg:h-96 xl:h-[420px] rounded-[20px] bg-light hover:bg-secondary transition-all duration-300 cursor-pointer group",
        {
          "bg-light-grey": hasDarkerBackground,
          "!h-[unset]": isHomePage(),
        }
      )}
    >
      {blok.icon?.filename && (
        <img
          src={blok.icon.filename}
          alt={blok.icon.alt}
          className="w-[40px] h-[40px] mb-5"
        />
      )}

      {blok.title?.map((title) => (
        <div key={title._uid} className="pb-[10px]">
          <StoryblokComponent blok={title} />
        </div>
      ))}

      <div className="mb-5 md:mb-6">
        {blok.brief?.map((brief) => (
          <span
            key={brief._uid}
            className="md:line-clamp-3 lg:line-clamp-5 xl:line-clamp-6"
          >
            <StoryblokComponent blok={brief} />
          </span>
        ))}
      </div>

      <div className="flex items-end h-full opacity-0 group-hover:opacity-100 duration-300 group-hover:duration-300">
        {blok.navButton?.map((navButton) => (
          <StoryblokComponent key={navButton._uid} blok={navButton} />
        ))}
      </div>
    </div>
  );
};
