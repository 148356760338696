import { yupResolver } from "@hookform/resolvers/yup";
import { StoryblokComponent } from "@storyblok/react";
import { toNumber } from "lodash";
import {
  FormProvider,
  SubmitHandler,
  useForm,
  useWatch,
} from "react-hook-form";
import { useToggle } from "react-use";
import { BookGpFormStoryblok } from "types/component-types-sb";

import { SpinnerLoading } from "assets/icons/SpinnerLoading";
import { Profile } from "models/client.model";
import { savePaymentDetailAsync } from "services/booking.service";
import { validateMedicareAsync } from "services/psychologist.service";
import { TacklitService } from "services/tacklit.service";
import {
  getDefaultButtonStyles,
  redirectTo,
  toDateOfBirth,
  toExpiryDate,
} from "utils";
import {
  getGPBehalfOfSomeOneFormDefaultData,
  getUserMedicareBasicInformation,
} from "utils/booking.util";
import { customToast } from "utils/toast.util";
import {
  MedicareSomeOneFields,
  MedicareSomeOneFieldsType,
} from "../shared/MedicareSomeOneFields";
import { getBookGPSomeOneFormResolver } from "./book-gp.resolver";

export type BookMedicareGPSomeOneFormData = MedicareSomeOneFieldsType;

type Props = {
  bookGPFormBlok: BookGpFormStoryblok;
  profile: Profile;
  accessToken: string;
};

export const GPBehalfOfSomeOneForm = ({
  bookGPFormBlok,
  profile,
  accessToken,
}: Props) => {
  const [isValidating, toggleValidating] = useToggle(false);

  const formContext = useForm<BookMedicareGPSomeOneFormData>({
    mode: "onChange",
    criteriaMode: "all",
    defaultValues: getGPBehalfOfSomeOneFormDefaultData(profile),
    resolver: yupResolver(getBookGPSomeOneFormResolver()),
  });

  const {
    control,
    formState: { errors, isValid: isFormValid },
    handleSubmit,
  } = formContext;

  const { isRegisteredWithMedicareName } = useWatch({
    control,
  });

  const userMedicareInformation = getUserMedicareBasicInformation(profile);

  const isDisabledForm = isValidating || !isFormValid;

  if (!userMedicareInformation) return;

  const validateMedicareCardAsync = async (
    formData: BookMedicareGPSomeOneFormData
  ): Promise<boolean> => {
    const medicareCardInfoParams = isRegisteredWithMedicareName
      ? {
          firstName: userMedicareInformation.firstName,
          lastName: userMedicareInformation.lastName,
          dateOfBirth: toDateOfBirth(userMedicareInformation.dateOfBirth) ?? "",
        }
      : {
          firstName: formData.firstName ?? "",
          lastName: formData.lastName ?? "",
          dateOfBirth: toDateOfBirth(formData.dateOfBirth) ?? "",
        };

    try {
      await validateMedicareAsync(
        {
          ...medicareCardInfoParams,
          number: toNumber(formData.medicareNumber),
          expiryDate: toExpiryDate(formData.expiryDate),
          irn: toNumber(formData.irnNumber),
          shouldRejectInvalidDetails: true,
        },
        accessToken
      );

      customToast.success("Valid Medicare card");

      return true;
    } catch (err) {
      console.log("[error]: >>", err);

      customToast.error("Invalid Medicare card");

      return false;
    }
  };

  const onSubmit: SubmitHandler<BookMedicareGPSomeOneFormData> = async (
    formData
  ) => {
    toggleValidating(true);

    try {
      const isValidMedicareCheck = await validateMedicareCardAsync(formData);

      if (!isValidMedicareCheck) return;

      // Put contact address and adult contact
      await Promise.all([
        TacklitService.putContactAddressAsync(formData, accessToken),
        TacklitService.putAdultContactAsync(formData, accessToken),
      ]);

      // Update payment card details
      const { data: checkoutUrl } = await savePaymentDetailAsync(accessToken);

      if (!checkoutUrl) {
        customToast.error(
          "Failed when processing payment. Please try again later."
        );

        return;
      }

      redirectTo(checkoutUrl);
    } catch (err) {
      console.log("[error]: >>", err);
    } finally {
      toggleValidating(false);
    }
  };

  return (
    <FormProvider {...formContext}>
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="flex flex-col flex-1 gap-y-5"
      >
        <div className="flex flex-col w-full gap-y-4">
          {/* MEDICARE GP SOMEONE */}
          <MedicareSomeOneFields<BookMedicareGPSomeOneFormData>
            control={control}
            errors={errors}
            userInformation={userMedicareInformation}
            medicareNumberHints={bookGPFormBlok.medicareNumberHints}
            irnHints={bookGPFormBlok.irnHints}
            expiryDateHints={bookGPFormBlok.expiryDateHints}
            streetAddressHints={bookGPFormBlok.streetAddressHints}
            medicareImageAsset={bookGPFormBlok.medicareImage}
            medicareImageAlt={bookGPFormBlok.medicareImageAlt}
          />

          {/* GP Summary */}
          {bookGPFormBlok?.gpSummaries?.map((gpSummaryItem) => (
            <StoryblokComponent key={gpSummaryItem._uid} blok={gpSummaryItem} />
          ))}
        </div>

        <div className="flex items-center justify-start">
          <button
            type="submit"
            disabled={isDisabledForm}
            className={getDefaultButtonStyles()}
          >
            Proceed to payment details
          </button>
          {isValidating && <SpinnerLoading className="w-12 h-12 ml-3" />}
        </div>
      </form>
    </FormProvider>
  );
};
