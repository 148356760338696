import { StoryblokComponent } from "@storyblok/react";
import { Carousel } from "flowbite-react/components/Carousel";
import { CustomFlowbiteTheme } from "flowbite-react/components/Flowbite";
import { chunk } from "lodash";

import { AssetStoryblok, CheckpointStoryblok } from "types/component-types-sb";
import { cn, disabledCss } from "utils";
import { v4 } from "uuid";

type Props = {
  checkpointItems: string[];
  iconBlok: AssetStoryblok | undefined;
  iconAlt: string | undefined;
};

const NUMBER_OF_ITEMS_PER_SLIDE = 2;

export const CheckpointCarousel = ({
  checkpointItems,
  iconBlok,
  iconAlt,
  ...props
}: Props) => {
  const chunkedItems = chunk(checkpointItems, NUMBER_OF_ITEMS_PER_SLIDE);

  const isDisabledControls =
    checkpointItems?.length <= NUMBER_OF_ITEMS_PER_SLIDE;

  const rootStyles =
    "absolute top-0 flex h-full items-center justify-center focus:outline-none";

  const customTheme: CustomFlowbiteTheme["carousel"] = {
    root: {
      leftControl: cn(rootStyles, "left-0 pr-4"),
      rightControl: cn(rootStyles, "right-0 pl-4"),
    },
    control: {
      base: cn(
        "inline-flex h-7 w-7 sm:h-8 sm:w-8 items-center justify-center rounded-full",
        isDisabledControls
          ? disabledCss
          : "group-hover:bg-secondary/50 group-focus:outline-none"
      ),
      icon: "h-5 w-5 text-secondary-darker",
    },
  };

  return (
    <div className="h-28 w-full">
      <Carousel theme={customTheme} indicators={false} slide={false}>
        {chunkedItems.map((chunk) => (
          <div
            key={v4()}
            className="flex flex-col w-full px-10 sm:px-14 items-center justify-start gap-y-3"
          >
            {chunk.map((item) => {
              const checkpointBlok = {
                _uid: v4(),
                icon: iconBlok,
                text: item,
                icon_alt: iconAlt,
                component: "checkpoint",
              } as CheckpointStoryblok;

              return (
                <div className="w-full" key={checkpointBlok._uid}>
                  <StoryblokComponent blok={checkpointBlok} />
                </div>
              );
            })}
          </div>
        ))}
      </Carousel>
    </div>
  );
};
