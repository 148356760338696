/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback } from "react";

import { AsYouType, isValidPhoneNumber } from "libphonenumber-js";
import { debounce, isEmpty, isEqual } from "lodash";
import {
  ControllerRenderProps,
  FieldValues,
  useFormContext,
} from "react-hook-form";
import { useAsyncFn } from "react-use";

import { TextError } from "components/forms/TextError";
import { TextInput } from "components/forms/TextInput";
import { InputSuccessInfo } from "components/forms/shared/InputSuccessInfo";
import { InputValidationSpinner } from "components/forms/shared/InputValidationSpinner";
import {
  DEFAULT_INPUT_DEBOUNCED_MS,
  DEFAULT_PHONE_COUNTRY_CODE,
  DEFAULT_PLACEHOLDER_PHONE_NUMBER,
} from "core/booking.constant";
import { useFormContextLoadingState } from "hooks/useFormContextLoadingState";
import { validatePhoneNumberByTacklitAsync } from "services/signup.service";
import { cn } from "utils";

type Props<T extends FieldValues> = {
  field: ControllerRenderProps<T>;

  label?: string;
  skipAsyncValidation?: boolean;
};

export const PhoneValidatorInput = <T extends FieldValues>({
  label = "Phone number",

  field,
  skipAsyncValidation = false,
  ...props
}: Props<T>) => {
  const { setError, getFieldState } = useFormContext();

  const phoneState = getFieldState(field.name);

  const [validationState, validatePhoneHookSync] = useAsyncFn(
    async (phoneNumber: string) => {
      if (skipAsyncValidation) return true;

      const isValid = await validatePhoneNumberByTacklitAsync(phoneNumber);

      return isValid;
    },
    [skipAsyncValidation]
  );

  useFormContextLoadingState({
    name: field.name,
    loading: validationState.loading,
  });

  const handleValidateAsync = async (phoneNumber: string = "") => {
    if (isEmpty(phoneNumber)) return;

    if (!isValidPhoneNumber(phoneNumber, DEFAULT_PHONE_COUNTRY_CODE)) {
      setError(field.name, {
        type: "manual",
        message: "Phone number is not valid",
      });

      return;
    }

    const isValid = await validatePhoneHookSync(phoneNumber);

    return isValid;
  };

  const debouncedValidateFn = useCallback(
    debounce(handleValidateAsync, DEFAULT_INPUT_DEBOUNCED_MS),
    []
  );

  const renderFeedback = useCallback(() => {
    if (validationState.loading) {
      return <InputValidationSpinner />;
    }

    const phoneError = phoneState.error;

    if (phoneError && phoneState.invalid) {
      return <TextError fieldError={phoneError} />;
    }
  }, [phoneState, validationState.loading]);

  const shouldShowSuccess =
    !phoneState.error && phoneState.isDirty && Boolean(validationState.value);

  return (
    <>
      <span className="relative">
        <TextInput
          type="tel"
          name={field.name}
          title={label}
          placeholder={DEFAULT_PLACEHOLDER_PHONE_NUMBER}
          onChangeValue={(e) => {
            const value = e.target.value;

            const formattedValue = new AsYouType(
              DEFAULT_PHONE_COUNTRY_CODE
            ).input(value);

            e.target.value = formattedValue;

            if (isEqual(formattedValue, field.value)) return;

            validationState.value = false;

            field.onChange(e);

            debouncedValidateFn(formattedValue);
          }}
          className={cn(shouldShowSuccess && "pr-10")}
          {...props}
        />

        {shouldShowSuccess && (
          <span className="absolute top-[50%] mt-[2px] right-[12px]">
            <InputSuccessInfo />
          </span>
        )}
      </span>

      {renderFeedback()}
    </>
  );
};
