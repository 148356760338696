/* eslint-disable react-hooks/exhaustive-deps */
import { yupResolver } from "@hookform/resolvers/yup";
import { StoryblokComponent, storyblokEditable } from "@storyblok/react";

import { isAxiosError } from "axios";
import { cloneDeep, defaultTo, first, get, isEmpty, isEqual } from "lodash";
import { Suspense, useEffect, useMemo, useRef, useState } from "react";
import { Controller, FieldError, FormProvider, useForm } from "react-hook-form";
import { useToggle } from "react-use";
import { match } from "ts-pattern";
import {
  NoticeBoxStoryblok,
  SignupFormStoryblok,
} from "types/component-types-sb";
import { Props } from "types/core";
import { passwordStrength as validatePassScore } from "check-password-strength";

import { useAuth0 } from "@auth0/auth0-react";
import { CheckSvg } from "assets/icons/CheckSvg";
import { SpinnerLoading } from "assets/icons/SpinnerLoading";
import { FilterCheckbox } from "components/forms/FilterCheckbox";
import { EmailValidatorInput } from "components/forms/shared/EmailValidatorInput";
import { PhoneValidatorInput } from "components/forms/shared/PhoneValidatorInput";
import { DateInputMask } from "components/shared/DateInputMask";
import {
  AGE_ADULT,
  AGE_CHILDREN,
  CALENDAR_DATE_FORMAT,
  DEFAULT_MINIMUM_PASSWORD_LENGTH,
  MSG_INVITED_EMAIL_ERROR,
  MSG_INVITED_PHONE_ERROR,
} from "core/booking.constant";
import { SignUpFormType } from "enums";
import { useGtmDataLayer } from "hooks/useGtmDataLayer";
import { Funding } from "models";
import { MinifiedSignupUserData } from "models/client.model";
import { Routes } from "routes/main.routes";
import {
  checkEmailMobileNumberInviteAsync,
  CheckEmailMobileNumberInvitePayload,
} from "services/booking.service";
import {
  createClientProfileAsync,
  toTacklitPhoneNumberFormat,
} from "services/signup.service";
import {
  calculateAge,
  cn,
  getDefaultButtonStyles,
  getNextBookingNavigation,
  isValidDate,
  redirectTo,
  toCalendarFormat,
  toISODateFormat,
} from "utils";
import { reclaimReserveAppointment } from "utils/booking.util";
import {
  getStoredFundingMethod,
  getStoredReserveData,
  setStoredReserveData,
  setStoredSignupSessionUser,
} from "utils/storage.util";
import { customToast } from "utils/toast.util";
import { PasswordField } from "../PasswordField";
import { TextError } from "../TextError";
import { TextInput } from "../TextInput";
import {
  LoggedInWarningModal,
  LoggedInWarningModalRef,
} from "./LoggedInWarningModal";
import { OTPVerifyModal, OTPVerifyModalRef } from "./OTPVerifyModal";
import { PasswordStrengthIndicator } from "./PasswordStrengthIndicator";
import { GetSignupFormResolver } from "./signup.resolver";
import React from "react";

const DatePicker = React.lazy(() => import("react-datepicker"));

export type SignupFormData = {
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  dob: Date;
  password: string;
  isPoliciesConfirmed: boolean;
  isAtsi: boolean;
  isRegistered: boolean;
};

const passwordStrengthRules = {
  passwordLengthValid: (password: string) =>
    password.length >= DEFAULT_MINIMUM_PASSWORD_LENGTH,
  passwordUpperLowerNumberValid: (password: string) =>
    /(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{0,}/g.test(password),
  passwordSpecialCharValid: (password: string) =>
    /.*[!@#$%^&*?-]+.*/g.test(password),
};

const PASSWORD_STRONG_SCORE = 3;

type SignupFormCustomProps = Props<SignupFormStoryblok> & {
  formType: SignUpFormType;
};

export const SignupForm = ({ blok, formType }: SignupFormCustomProps) => {
  const { isAuthenticated } = useAuth0();

  const GtmDataLayer = useGtmDataLayer();

  const otpModalRef = useRef<OTPVerifyModalRef>(null);
  const loggedInWarningModalRef = useRef<LoggedInWarningModalRef>(null);

  const [passwordStrength, setPasswordStrength] = useState(0);
  const [clientAge, setClientAge] = useState<number>(0);
  const [password, setPassword] = useState("");

  const [isLoading, toggleIsLoading] = useToggle(false);
  const [isValidAge, toggleValidAge] = useToggle(true);

  const formContext = useForm<SignupFormData>({
    mode: "onChange",
    defaultValues: {
      isRegistered: true,
      isPoliciesConfirmed: false,
    },
    resolver: yupResolver(GetSignupFormResolver(formType)),
  });

  const {
    control,
    handleSubmit,
    setError,
    formState: { errors, isSubmitted, isValidating, isValid },
    getFieldState,
  } = formContext;

  const reserveAppointmentData = getStoredReserveData();
  const isDVAOrWorkCoverFunding = [Funding.DVA, Funding.WORK_COVER].includes(
    getStoredFundingMethod() as Funding
  );

  const {
    passwordLengthValid,
    passwordUpperLowerNumberValid,
    passwordSpecialCharValid,
  } = useMemo(
    () => ({
      passwordLengthValid: passwordStrengthRules.passwordLengthValid(password),
      passwordUpperLowerNumberValid:
        passwordStrengthRules.passwordUpperLowerNumberValid(password),
      passwordSpecialCharValid:
        passwordStrengthRules.passwordSpecialCharValid(password),
    }),
    [password]
  );

  const isStrongPassword = passwordStrength === PASSWORD_STRONG_SCORE;
  const isValidPassword =
    [
      passwordLengthValid,
      passwordUpperLowerNumberValid,
      passwordSpecialCharValid,
    ].every((isValid) => isValid === true) && isStrongPassword;

  const isAuthCreatingUserInvite =
    isAuthenticated && isEqual(formType, SignUpFormType.INVITE);

  const canSubmitForm =
    !isLoading && isValid && isValidPassword && isValidAge && isEmpty(errors);

  const isDisabledSubmitBtn = !canSubmitForm || isAuthCreatingUserInvite;

  useEffect(() => {
    if (!isAuthCreatingUserInvite) return;

    loggedInWarningModalRef.current?.show();
  }, [isAuthCreatingUserInvite]);

  useEffect(() => {
    const passScore = validatePassScore(password).id;

    setPasswordStrength(passScore);
  }, [password]);

  const handleFormSubmitAsync = async (data: SignupFormData) => {
    try {
      toggleIsLoading(true);

      await match(formType)
        .with(SignUpFormType.FUNDING, async () => {
          await handleSubmitFundingFlowAsync(data);
        })
        .with(SignUpFormType.GP, async () => {
          await handleSubmitGpFlowAsync(data);
        })
        .with(SignUpFormType.INVITE, async () => {
          await handleSubmitInviteFlowAsync(data);
        })
        .exhaustive();
    } catch (err) {
      if (isAxiosError(err)) {
        customToast.error("Failed to create profile. Please try again later.");
      }
    } finally {
      toggleIsLoading(false);
    }
  };

  const handleSubmitFundingFlowAsync = async (formData: SignupFormData) => {
    const psychologistId = reserveAppointmentData?.psychologist._id;
    const appointmentTypeId = first(
      reserveAppointmentData?.reserveAppointment.appointments
    )?.sessionTypeId;

    if (!psychologistId || !appointmentTypeId) {
      return;
    }

    const clientProfile = await createClientProfileAsync(
      formData,
      psychologistId,
      appointmentTypeId
    );

    if (!clientProfile.data) return;

    const newReserveAppointmentData = cloneDeep(reserveAppointmentData);
    newReserveAppointmentData.reserveAppointment.appointments[0].clientRecord =
      clientProfile.data.clientRecord;

    setStoredReserveData(newReserveAppointmentData);

    const authToken = clientProfile.data.authToken;

    setStoredSignupSessionUser({
      firstName: formData.firstName,
      lastName: formData.lastName,
      dateOfBirth: toISODateFormat(formData.dob),
      authToken: authToken,
      clientRecord: defaultTo(clientProfile?.data?.clientRecord, {}),
    } as MinifiedSignupUserData);

    // Reset holding timeout back to 15 minutes
    const reservedId = reserveAppointmentData?.reserveAppointment.reserveId;
    await reclaimReserveAppointment(reservedId, authToken);

    const bookingUrl = getNextBookingNavigation();

    if (!bookingUrl) {
      customToast.error(
        "Funding method is not matched. Please check and try again."
      );

      return;
    }

    GtmDataLayer.pushEventRegistrationCompleted();

    redirectTo(bookingUrl);
  };

  const handleSubmitGpFlowAsync = async (data: SignupFormData) => {
    const clientProfile = await createClientProfileAsync(data);

    setStoredSignupSessionUser({
      firstName: data.firstName,
      lastName: data.lastName,
      dateOfBirth: toISODateFormat(data.dob),
      authToken: clientProfile.data.authToken,
      clientRecord: defaultTo(clientProfile?.data?.clientRecord, {}),
    } as MinifiedSignupUserData);

    GtmDataLayer.pushEventGpRegistrationCompleted();

    redirectTo(Routes.BOOKING_GP);
  };

  const validateInvitedEmailAsync = async (
    data: SignupFormData
  ): Promise<boolean> => {
    const payload: CheckEmailMobileNumberInvitePayload = {
      email: data.email,
      mobileNumber: toTacklitPhoneNumberFormat(data.phone),
      shouldSendOtp: true,
    };

    try {
      const { data } = await checkEmailMobileNumberInviteAsync(payload);
      const { used, invited, emailInvited } = data || {};

      if (used) {
        setError("email", {
          type: "manual",
          message: "Email address is already used.",
        });

        return false;
      }

      if (!invited) {
        if (!emailInvited) {
          setError("email", {
            type: "manual",
            message: MSG_INVITED_EMAIL_ERROR,
          });
        } else {
          setError("phone", {
            type: "manual",
            message: MSG_INVITED_PHONE_ERROR,
          });
        }

        return false;
      }

      return true;
    } catch (err) {
      console.log("[error]: >>", err);

      return false;
    }
  };

  const handleSubmitInviteFlowAsync = async (data: SignupFormData) => {
    try {
      const isValidInviteEmail = await validateInvitedEmailAsync(data);

      if (!isValidInviteEmail) return;

      otpModalRef.current?.show({ formData: data });
    } catch (err) {
      console.log("[error]: >>", err);
    }
  };

  const validateClientAge = (dob: Date) => {
    const age = calculateAge(dob);

    setClientAge(age);

    const isUnderChildrenGp = blok.isGPBooking && age < AGE_CHILDREN;
    const isUnderChildrenNotGp =
      !blok.isGPBooking && age < AGE_CHILDREN && !isDVAOrWorkCoverFunding;
    const isUnderAdultDvaOrWorkCover =
      !blok.isGPBooking && age < AGE_ADULT && isDVAOrWorkCoverFunding;

    const invalidAge: Boolean =
      isUnderChildrenGp || // GP booking is restricted to over 13 years of age
      isUnderChildrenNotGp || // Funding booking is restricted to over 13 years of age if not DVA or WorkCover
      isUnderAdultDvaOrWorkCover; // Funding booking is restricted to over 18 years of age for DVA and WorkCover

    if (invalidAge) {
      toggleValidAge(false);
    } else {
      toggleValidAge(true);
    }
  };

  const renderDobNoticeBox = (notice: NoticeBoxStoryblok) => {
    if (!getFieldState("dob").isDirty) return null;

    let msg = "";

    const isUnderChildrenGp = blok.isGPBooking && clientAge < AGE_CHILDREN;
    const isUnderChildrenNotGp =
      !blok.isGPBooking && clientAge < AGE_CHILDREN && !isDVAOrWorkCoverFunding;

    const is13YearsOldRestricted = isUnderChildrenGp || isUnderChildrenNotGp;

    const is18YearsOldRestricted =
      !blok.isGPBooking && clientAge < AGE_ADULT && isDVAOrWorkCoverFunding;

    if (is13YearsOldRestricted) {
      msg = "Psychology services are restricted to over 13 years of age.";
    }

    if (is18YearsOldRestricted) {
      msg = "This psychology service is restricted to over 18 years of age.";
    }

    if (!msg) return null;

    const updatedNotice = {
      ...notice,
      content: notice.content ? [{ ...notice.content[0], text: msg }] : [],
    };

    return <StoryblokComponent key={updatedNotice._uid} blok={updatedNotice} />;
  };

  return (
    <div className="flex flex-1" {...storyblokEditable(blok)}>
      <div className="flex flex-col w-full gap-5">
        {/* Header */}
        <div>
          {blok.header?.map((item) => (
            <StoryblokComponent key={item._uid} blok={item} />
          ))}
        </div>

        {/* Form */}
        <FormProvider {...formContext}>
          <form
            onSubmit={handleSubmit(handleFormSubmitAsync)}
            className="flex flex-col gap-3 lg:gap-4"
          >
            <div className="flex items-start justify-between mb-10">
              <span className="text-primary text-15">
                {blok.formNoteContent}
              </span>
              <div className="mt-1">
                {blok.formNoteHint?.map((item) => (
                  <StoryblokComponent key={item._uid} blok={item} />
                ))}
              </div>
            </div>

            <div className="flex mb-2 gap-7">
              {/* First name */}
              <div className="flex-1">
                <Controller
                  name="firstName"
                  control={control}
                  render={({ field }) => (
                    <div className="flex flex-col gap-1.5">
                      <TextInput
                        name={field.name}
                        title={"First name"}
                        onChangeValue={field.onChange}
                      />

                      <TextError fieldError={errors.firstName} />
                    </div>
                  )}
                />
              </div>

              {/* Last name */}
              <div className="flex-1">
                <Controller
                  name="lastName"
                  control={control}
                  render={({ field }) => (
                    <div className="flex flex-col gap-1.5">
                      <TextInput
                        name={field.name}
                        title={"Last name"}
                        onChangeValue={field.onChange}
                      />

                      <TextError fieldError={errors.lastName} />
                    </div>
                  )}
                />
              </div>
            </div>

            {/* Email */}
            <Controller
              name="email"
              control={control}
              render={({ field }) => (
                <div className="flex flex-col gap-1.5">
                  <EmailValidatorInput
                    field={field}
                    skipAsyncValidation={formType === SignUpFormType.INVITE}
                  />
                </div>
              )}
            />

            <div className="flex flex-col gap-3 md:flex-row lg:gap-7">
              {/* Phone */}
              <div className="flex-1">
                <Controller
                  name="phone"
                  control={control}
                  render={({ field }) => (
                    <div className="flex flex-col gap-1.5">
                      <PhoneValidatorInput field={field} />
                    </div>
                  )}
                />
              </div>

              {/* Date of birth */}
              <div className="flex-1">
                <Controller
                  name="dob"
                  control={control}
                  render={({ field }) => (
                    <div className="flex flex-col justify-between w-full gap-y-1">
                      <label className="text-dark-grey">Date of birth</label>

                      <Suspense>
                        <DatePicker
                          portalId="root"
                          name={field.name}
                          selected={field.value}
                          customInput={<DateInputMask />}
                          onChange={(date, event) => {
                            let rawDate = get(event, "target.value", "");

                            if (event?.type === "click" && date) {
                              rawDate = toCalendarFormat(date as Date);
                            }

                            const validDate =
                              isValidDate(rawDate) &&
                              isEqual(rawDate?.length, 10);

                            if (validDate) {
                              field.onChange(date);

                              validateClientAge(date as Date);
                            } else {
                              field.onChange(null);
                            }
                          }}
                          placeholderText="DD/MM/YYYY"
                          dateFormat={CALENDAR_DATE_FORMAT}
                          maxDate={new Date()}
                          className="w-full py-3 pl-3 border rounded-md border-light-grey-3 focus:border-secondary-darker focus:ring-secondary-darker outline-secondary-darker"
                        />
                      </Suspense>

                      <TextError fieldError={errors.dob} />

                      {blok.ageRestrictNotice?.map(renderDobNoticeBox)}
                    </div>
                  )}
                />
              </div>
            </div>

            {/* Password */}
            <Controller
              name="password"
              control={control}
              render={({ field }) => (
                <div className="flex flex-col gap-1.5">
                  <PasswordField
                    title="Set a secure password"
                    placeHolder="ALL password rules must be met"
                    onChangeValues={(e) => {
                      field.onChange(e);
                      setPassword(e);
                    }}
                  />

                  {renderPasswordStrengthIndicatorErrorMessage(
                    getFieldState("password").isDirty,
                    passwordStrength,
                    errors.password
                  )}

                  <div className="flex flex-col gap-5 bg-light-grey rounded-10 mt-7 p-7 text-primary text-15">
                    <div className="flex items-start gap-4">
                      <div
                        className={cn(
                          "flex w-6 h-6 shrink-0 items-center justify-center ring-1 rounded-full",
                          passwordUpperLowerNumberValid
                            ? "ring-secondary-darker duration-500"
                            : "ring-light-grey-2 duration-500"
                        )}
                      >
                        <CheckSvg
                          className={cn(
                            "w-2/3",
                            passwordUpperLowerNumberValid
                              ? "opacity-100 duration-500"
                              : "opacity-0 duration-500"
                          )}
                        />
                      </div>
                      Contains uppercase, lowercase, and numbers
                    </div>

                    <div className="flex items-start gap-4">
                      <div
                        className={cn(
                          "flex w-6 h-6 shrink-0 items-center justify-center ring-1 rounded-full",
                          passwordSpecialCharValid
                            ? "ring-secondary-darker duration-500"
                            : "ring-light-grey-2 duration-500"
                        )}
                      >
                        <CheckSvg
                          className={cn(
                            "w-2/3",
                            passwordSpecialCharValid
                              ? "opacity-100 duration-500"
                              : "opacity-0 duration-500"
                          )}
                        />
                      </div>
                      Contains special characters (!@#$%^&*?-)
                    </div>

                    <div className="flex flex-col gap-4 sm:flex-row md:gap-5">
                      <div className="flex items-start gap-4">
                        <div
                          className={cn(
                            "flex w-6 h-6 shrink-0 items-center justify-center ring-1 rounded-full",
                            passwordLengthValid
                              ? "ring-secondary-darker duration-500"
                              : "ring-light-grey-2 duration-500"
                          )}
                        >
                          <CheckSvg
                            className={cn(
                              "w-2/3",
                              passwordLengthValid
                                ? "opacity-100 duration-500"
                                : "opacity-0 duration-500"
                            )}
                          />
                        </div>
                        <span>
                          Minimum {DEFAULT_MINIMUM_PASSWORD_LENGTH} characters
                        </span>
                      </div>
                    </div>

                    <div className="flex flex-col gap-4 sm:flex-row md:gap-5">
                      <div className="flex items-start">
                        <div
                          className={cn(
                            "flex w-6 h-6 shrink-0 items-center justify-center ring-1 rounded-full",
                            isEqual(passwordStrength, PASSWORD_STRONG_SCORE)
                              ? "ring-secondary-darker duration-500"
                              : "ring-light-grey-2 duration-500"
                          )}
                        >
                          <CheckSvg
                            className={cn(
                              "w-2/3",
                              isEqual(passwordStrength, PASSWORD_STRONG_SCORE)
                                ? "opacity-100 duration-500"
                                : "opacity-0 duration-500"
                            )}
                          />
                        </div>

                        <div className="flex flex-col md:flex-row">
                          <span className="mx-4">Password must be strong</span>

                          <div className="flex flex-row">
                            <PasswordStrengthIndicator
                              strengthLevel={passwordStrength}
                            />

                            <div className="flex self-center ml-7">
                              {blok.hintPasswordStrong?.map((hint) => (
                                <StoryblokComponent
                                  key={hint._uid}
                                  blok={hint}
                                />
                              ))}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            />

            {/* Policies confirmation */}
            <Controller
              name="isPoliciesConfirmed"
              control={control}
              render={({ field }) => (
                <div>
                  <div className="flex items-start">
                    <FilterCheckbox
                      isChecked={field.value ?? false}
                      onCheck={field.onChange}
                      title=""
                    />

                    {blok.policiesConfirmText?.map((item) => (
                      <StoryblokComponent key={item._uid} blok={item} />
                    ))}
                  </div>

                  <TextError fieldError={errors.isPoliciesConfirmed} />
                </div>
              )}
            />

            {/* Aboriginal or Torres Strait Islander */}
            <div className="flex flex-row gap-x-3">
              <Controller
                name="isAtsi"
                control={control}
                render={({ field }) => (
                  <div className="flex items-start">
                    <FilterCheckbox
                      isChecked={field.value ?? false}
                      onCheck={field.onChange}
                      title=""
                    />

                    {blok.aboriginalConfirmText?.map((item) => (
                      <StoryblokComponent key={item._uid} blok={item} />
                    ))}
                  </div>
                )}
              />

              {blok?.hintAboriginal?.map((hint) => (
                <StoryblokComponent key={hint._uid} blok={hint} />
              ))}
            </div>

            {/* Footer */}
            <div className="flex flex-col gap-1.5">
              {blok.footer?.map((item) => (
                <StoryblokComponent key={item._uid} blok={item} />
              ))}

              {/* Registered */}
              <Controller
                name="isRegistered"
                control={control}
                render={({ field }) => (
                  <div className="flex items-start">
                    <FilterCheckbox
                      isChecked={field.value ?? false}
                      onCheck={field.onChange}
                      title=""
                    />

                    {blok.registerConfirmText?.map((item) => (
                      <StoryblokComponent key={item._uid} blok={item} />
                    ))}
                  </div>
                )}
              />
            </div>

            {/* Submit */}
            <div className="flex flex-col items-center gap-5 md:flex-row">
              <button
                disabled={isDisabledSubmitBtn}
                type="submit"
                className={cn(
                  getDefaultButtonStyles(isLoading),
                  "max-md:w-full"
                )}
              >
                Create my profile
              </button>

              {(isLoading || isValidating) && (
                <SpinnerLoading className="w-12 h-12" />
              )}
            </div>

            {isSubmitted && !isValid && (
              <TextError message="There is an error in the form. Please check all the fields before submit again" />
            )}
          </form>
        </FormProvider>
      </div>

      <OTPVerifyModal ref={otpModalRef} />

      <LoggedInWarningModal ref={loggedInWarningModalRef} />
    </div>
  );
};

const renderPasswordStrengthIndicatorErrorMessage = (
  isFieldDirty: boolean,
  passwordStrength: number,
  passwordError: FieldError | undefined
): JSX.Element => {
  if (!isFieldDirty) return <></>;

  if (passwordError) {
    return <TextError fieldError={passwordError} />;
  }

  if (isEqual(passwordStrength, PASSWORD_STRONG_SCORE)) return <></>;

  return (
    <div className="text-alerts-error text-xs">
      Password does not meet all the security requirements
    </div>
  );
};
