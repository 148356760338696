import { InputSuccessInfo } from "components/forms/shared/InputSuccessInfo";
import { InputValidationSpinner } from "components/forms/shared/InputValidationSpinner";
import { TextError } from "components/forms/TextError";
import { TextInput } from "components/forms/TextInput";
import { useCallback } from "react";
import {
  ControllerRenderProps,
  FieldValues,
  useFormContext,
} from "react-hook-form";
import { useAsyncFn } from "react-use";
import { cn } from "utils";

type Props<T extends FieldValues> = {
  field: ControllerRenderProps<T>;
  hintElement: JSX.Element;
  validationState: ReturnType<typeof useAsyncFn>[0];

  label?: string;
};

export const InputMedicareNumberValidator = <T extends FieldValues>({
  field,
  hintElement,
  validationState,

  label = "Medicare card number",
}: Props<T>) => {
  const { getFieldState } = useFormContext();

  const medicareNumberState = getFieldState(field.name);

  const renderFeedback = useCallback(() => {
    if (validationState.loading) {
      return <InputValidationSpinner title="Autosaving..." />;
    }

    const medicareError = medicareNumberState?.error;

    if (medicareError && medicareNumberState.invalid) {
      return <TextError fieldError={medicareError} />;
    }
  }, [medicareNumberState, validationState.loading]);

  const shouldShowSuccess =
    validationState.value === true &&
    !validationState.loading &&
    !medicareNumberState.invalid;

  return (
    <>
      <span className="relative">
        <TextInput
          {...field}
          type="text"
          title={label}
          onChangeValue={field.onChange}
          hintElement={hintElement}
          className={cn(shouldShowSuccess && "pr-10")}
          numberOnly
        />

        {shouldShowSuccess && (
          <span className="absolute mt-[2px] top-[50%] right-[12px]">
            <InputSuccessInfo />
          </span>
        )}
      </span>

      {renderFeedback()}
    </>
  );
};
