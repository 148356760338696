import { Routes } from "routes/main.routes";
import { HeaderLogoStoryblok } from "@/types/component-types-sb";
import { Props } from "@/types/core";
import { storyblokEditable } from "@storyblok/react";
import { Link as RouterLink } from "react-router-dom";

export const Logo = ({ blok }: Props<HeaderLogoStoryblok>) => (
  <div {...storyblokEditable(blok)}>
    <RouterLink {...storyblokEditable(blok)} to={Routes.ROOT} reloadDocument>
      <img
        src={blok.img?.filename}
        alt={blok.img?.alt ?? "Someone.health logo"}
        loading="eager"
      />
    </RouterLink>
  </div>
);
