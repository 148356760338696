import * as Yup from "yup";

import { ConfirmRadioType, ProvideReferralType } from "enums";
import { toRequiredMessage } from "utils";
import { getDefaultConfirmBookingFormResolver } from "../book-finalise/booking.resolver";
import {
  getAddressFormValidation,
  getMedicareChildFieldsValidation,
  getMedicareMySelfFieldsValidation,
  getMedicareSomeOneFieldsValidation,
} from "../shared/shared.resolver";

export const getBookMedicareMySelfFormResolver = () =>
  Yup.object({
    isHaveGPReferralAndTreatmentPlan: Yup.string().required(
      toRequiredMessage("Have GP referral and treatment plan")
    ),
    provideReferral: Yup.string().required(
      toRequiredMessage("Provide referral")
    ),
    videoAppointmentWithBulkBillGP: Yup.string().required(
      toRequiredMessage("Video appointment with Bulk bill GP")
    ),
    gPReferralList: Yup.array().when(
      ["isHaveGPReferralAndTreatmentPlan", "provideReferral"],
      ([isHaveGPReferralAndTreatmentPlan, provideReferral], field) =>
        isHaveGPReferralAndTreatmentPlan === ConfirmRadioType.YES &&
        provideReferral === ProvideReferralType.UPLOAD
          ? field.max(4, "The maximum file is four")
          : field.optional()
    ),
    treatmentPlanList: Yup.array().when(
      ["isHaveGPReferralAndTreatmentPlan", "provideReferral"],
      ([isHaveGPReferralAndTreatmentPlan, provideReferral], field) =>
        isHaveGPReferralAndTreatmentPlan === ConfirmRadioType.YES &&
        provideReferral === ProvideReferralType.UPLOAD
          ? field.max(6, "The maximum file is six")
          : field.optional()
    ),
  })
    .concat(getAddressFormValidation())
    .concat(getMedicareMySelfFieldsValidation())
    .concat(getDefaultConfirmBookingFormResolver());

export const getBookMedicareSomeOneFormResolver = () =>
  Yup.object()
    .shape({
      isHaveGPReferralAndTreatmentPlan: Yup.string().required(
        toRequiredMessage("Have GP referral and treatment plan")
      ),
      provideReferral: Yup.string().required(
        toRequiredMessage("Provide referral")
      ),
      videoAppointmentWithBulkBillGP: Yup.string().required(
        toRequiredMessage("Video appointment with Bulk bill GP")
      ),

      gPReferralList: Yup.array().when(
        ["isHaveGPReferralAndTreatmentPlan", "provideReferral"],
        ([isHaveGPReferralAndTreatmentPlan, provideReferral], field) =>
          isHaveGPReferralAndTreatmentPlan === ConfirmRadioType.YES &&
          provideReferral === ProvideReferralType.UPLOAD
            ? field.max(4, "The maximum file is four")
            : field.optional()
      ),
      treatmentPlanList: Yup.array().when(
        ["isHaveGPReferralAndTreatmentPlan", "provideReferral"],
        ([isHaveGPReferralAndTreatmentPlan, provideReferral], field) =>
          isHaveGPReferralAndTreatmentPlan === ConfirmRadioType.YES &&
          provideReferral === ProvideReferralType.UPLOAD
            ? field.max(6, "The maximum file is six")
            : field.optional()
      ),
    })
    .concat(getAddressFormValidation())
    .concat(getMedicareSomeOneFieldsValidation())
    .concat(getDefaultConfirmBookingFormResolver());

export const getBookMedicareChildFormResolver = () =>
  Yup.object()
    .shape({
      isConsentForParent: Yup.boolean().required(),
      isHaveGPReferralAndTreatmentPlan: Yup.string().required(
        toRequiredMessage("Have GP referral and treatment plan")
      ),
      provideReferral: Yup.string().required(
        toRequiredMessage("Provide referral")
      ),
      videoAppointmentWithBulkBillGP: Yup.string().required(
        toRequiredMessage("Video appointment with Bulk bill GP")
      ),
      gPReferralList: Yup.array().when(
        ["isHaveGPReferralAndTreatmentPlan", "provideReferral"],
        ([isHaveGPReferralAndTreatmentPlan, provideReferral], field) =>
          isHaveGPReferralAndTreatmentPlan === ConfirmRadioType.YES &&
          provideReferral === ProvideReferralType.UPLOAD
            ? field.max(4, "The maximum file is four")
            : field.optional()
      ),
      treatmentPlanList: Yup.array().when(
        ["isHaveGPReferralAndTreatmentPlan", "provideReferral"],
        ([isHaveGPReferralAndTreatmentPlan, provideReferral], field) =>
          isHaveGPReferralAndTreatmentPlan === ConfirmRadioType.YES &&
          provideReferral === ProvideReferralType.UPLOAD
            ? field.max(6, "The maximum file is six")
            : field.optional()
      ),
    })
    .concat(getAddressFormValidation())
    .concat(getMedicareChildFieldsValidation())
    .concat(getDefaultConfirmBookingFormResolver());
