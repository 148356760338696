import { StoryblokComponent, storyblokEditable } from "@storyblok/react";

import { BannerStoryblok } from "@/types/component-types-sb";
import { Props } from "@/types/core";
import { ScreenBreakpoints } from "enums";
import { defaultTo, isEmpty, random } from "lodash";
import { useEffect, useMemo, useRef, useState } from "react";
import { useMeasure, useWindowSize } from "react-use";
import { cn } from "utils";
import {
  calculateArticleBannerContentMaxWidth,
  calculateArticleBannerImageMaxWidth,
  isBlogPage,
  isEngagePages,
} from "utils/blog.util";

export type CustomBannerProps = Props<BannerStoryblok> & {
  isArticleBanner?: boolean;
};

export const Banner = ({
  blok,
  isArticleBanner = false,
}: CustomBannerProps) => {
  const [contentWidth, setContentWidth] = useState(0);
  const [imageWidth, setImageWidth] = useState(0);
  const [imageHeight, setImageHeight] = useState(0);

  const { width: windowWidth } = useWindowSize();
  const imageRef = useRef<HTMLImageElement>(null);
  const [containerRef, containerEl] = useMeasure<HTMLDivElement>();

  const hasMobilePicture =
    blok.bannerMobilePicture && blok.bannerMobilePicture.filename;

  useEffect(() => {
    setContentWidth(defaultTo(containerEl.width, 0) - imageWidth);
  }, [containerEl, imageWidth]);

  const BannerDesktop = useMemo<JSX.Element>(() => {
    const hasBannerPicture = blok.bannerPicture && blok.bannerPicture.filename;
    const imagePaddingStyles = {
      paddingTop: `${blok.imageTopPadding}px`,
      paddingBottom: `${blok.imageBottomPadding}px`,
      paddingLeft: `${blok.imageLeftPadding}px`,
      paddingRight: `${blok.imageRightPadding}px`,
    };

    return (
      <div
        ref={containerRef}
        className={cn(
          "lg:flex lg:relative rounded-10 xl:rounded-30",
          !blok.isReverseContent ? "justify-between" : "flex-row-reverse",
          {
            [`${blok.contentJustify}`]: Boolean(blok?.contentJustify),
          }
        )}
        style={{
          marginTop: `${blok.bannerTopMargin}px`,
          marginBottom: `${blok.bannerBottomMargin}px`,
          paddingTop: `${blok.bannerTopPadding}px`,
          paddingBottom: `${blok.bannerBottomPadding}px`,
          paddingLeft: `${blok.bannerLeftPadding}px`,
          paddingRight: `${blok.bannerRightPadding}px`,
          backgroundImage: `url(${blok.background?.filename})`,
          backgroundSize: "cover",
          maxHeight: hasBannerPicture ? `${imageHeight}px` : undefined,
          minHeight: `${blok.bannerHeight}px`,
        }}
      >
        <div
          className={cn("flex items-center text-primary", blok.contentJustify, {
            "w-full text-center": !hasBannerPicture,
          })}
          style={{
            maxWidth: isArticleBanner
              ? calculateArticleBannerContentMaxWidth({
                  windowWidth: windowWidth,
                  contentWidth: contentWidth,
                })
              : `${contentWidth}px`,
          }}
          data-aos="fade-right"
          data-aos-delay="200"
        >
          <div
            className={cn("block z-[1]")}
            style={{
              paddingTop: `${blok.contentTopPadding}px`,
              paddingBottom: `${blok.contentBottomPadding}px`,
              paddingLeft: `${blok.contentLeftPadding}px`,
              paddingRight: `${blok.contentRightPadding}px`,
            }}
          >
            {/* Content */}
            {blok.content?.map((item) => (
              <div key={item._uid} className="mb-6">
                <StoryblokComponent blok={item} />
              </div>
            ))}

            {/* Buttons */}
            <div
              className={cn("block lg:flex lg:gap-3", {
                "justify-center": !hasBannerPicture,
              })}
            >
              {blok.buttons?.map((button) => (
                <div key={button._uid} className="pb-3 lg:pb-0">
                  <StoryblokComponent blok={button} />
                </div>
              ))}
            </div>

            {/* ACTIONS */}
            <div className={cn({ "pt-8": !isEmpty(blok?.actions) })}>
              {blok?.actions?.map((action) => (
                <StoryblokComponent key={action._uid} blok={action} />
              ))}
            </div>
          </div>
        </div>

        {/* Image */}
        <div
          className={cn("self-center h-full flex w-fit")}
          data-aos="fade-left"
          data-aos-delay="200"
        >
          <img
            ref={imageRef}
            src={blok.bannerPicture?.filename}
            alt={blok.bannerPicture?.alt}
            loading="eager"
            className={cn(
              "object-bottom",
              isArticleBanner ? "object-cover" : "object-contain",
              blok.isReverseContent
                ? "rounded-s-10 xl:rounded-s-30"
                : "rounded-e-10 xl:rounded-e-30"
            )}
            style={
              !blok.isReverseContent
                ? {
                    ...imagePaddingStyles,
                    maxWidth: isArticleBanner
                      ? calculateArticleBannerImageMaxWidth(windowWidth)
                      : `${imageWidth}px`,
                    maxHeight: isArticleBanner ? undefined : `${imageHeight}px`,
                    height: isArticleBanner
                      ? `${containerEl.height}px`
                      : undefined,
                  }
                : {
                    ...imagePaddingStyles,
                    maxHeight: isArticleBanner ? undefined : `${imageHeight}px`,
                    height: isArticleBanner
                      ? `${containerEl.height}px`
                      : undefined,
                  }
            }
            onLoad={() => {
              setImageWidth(defaultTo(imageRef.current?.naturalWidth, 0));
              setImageHeight(defaultTo(imageRef.current?.naturalHeight, 0));
            }}
          />
        </div>

        {blok.bannerPictureName && (
          <div
            className={cn(
              "h-7 uppercase bg-neutral absolute bottom-5 px-3 flex justify-center items-center text-primary rounded-full font-semibold",
              blok.isReverseContent ? "left-9" : "right-9"
            )}
          >
            {blok.bannerPictureName}
          </div>
        )}
      </div>
    );
  }, [
    blok,
    containerRef,
    containerEl?.height,
    imageHeight,
    isArticleBanner,
    windowWidth,
    contentWidth,
    imageWidth,
  ]);

  const BannerMobile = useMemo<JSX.Element>(() => {
    return (
      <div
        className="relative grid grid-cols-1 px-6 -mx-4 lg:hidden lg:px-0 bg-highlight-green"
        style={{
          backgroundImage: `url(${blok.background?.filename})`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
        }}
      >
        <div className="grid mx-5">
          <div
            className={cn(
              blok.isReverseContent
                ? "order-last mb-[50px] mt-[30px]"
                : "mt-[50px] max-md:my-[18px]"
            )}
          >
            {/* Content */}
            <div className="text-primary">
              {blok.content?.map((item) => (
                <div key={item._uid} className="mb-[15px]">
                  <StoryblokComponent blok={item} />
                </div>
              ))}
            </div>

            {/* Buttons */}
            <div className="block">
              {blok.buttons?.map((button) => {
                button.isFullWidth = true;

                return (
                  <div key={button._uid} className="pb-3">
                    <StoryblokComponent blok={button} />
                  </div>
                );
              })}
            </div>

            {/* ACTIONS */}
            <div className={cn({ "pt-2": !isEmpty(blok?.actions) })}>
              {blok?.actions?.map((action) => (
                <StoryblokComponent key={action._uid} blok={action} />
              ))}
            </div>
          </div>

          {/* Image */}
          <div
            className={cn("flex justify-center mt-3 lg:mt-[50px]", {
              "max-md:hidden": isEngagePages(),
              "mb-4": isBlogPage(),
            })}
          >
            <img
              src={
                hasMobilePicture
                  ? blok.bannerMobilePicture?.filename
                  : blok.bannerPicture?.filename
              }
              loading="eager"
              alt={blok.bannerPicture?.alt}
              className={cn("object-cover", {
                [`clip-path__avatar-${random(3)}`]: blok.hasClipPath,
              })}
            />
          </div>
        </div>
      </div>
    );
  }, [blok, hasMobilePicture]);

  return (
    <div
      {...storyblokEditable(blok)}
      className={cn("bg-center md:rounded-30", {
        "overflow-hidden": !Boolean(blok?.allowOverflow),
      })}
      // data-aos="fade-in"
    >
      {/* Tablet + Desktop - Greater than or Equal 768px */}
      {/* Mobile - Less than 768px */}
      {windowWidth >= ScreenBreakpoints.LG ? BannerDesktop : BannerMobile}
    </div>
  );
};
